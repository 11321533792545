import { request } from './client';
var model = 'eye-of-the-enemy/EdrTags';
var edrTags = {
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  create: function create(tag) {
    return request('post', "/".concat(model), tag);
  }
};
export default edrTags;