import React from 'react';
import {
    Button,
    CustomInput,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

const products = [
    {id: 1, name: 'Eye of the Enemy'},
    {id: 2, name: 'Attack Hunter'},
];

class CreateLicenseButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productId: null,
            startDate: null,
            endDate: null,
            licenseType: null,
            quota: null,
            chosenVectors: null
        };

        this.toggle = this.toggle.bind(this);
        this.validateLicense = this.validateLicense.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            productId: null,
            startDate: null,
            endDate: null,
            licenseType: null,
            quota: null,
            chosenVectors: null,
            errors: null
        });
    }

    validateLicense() {
        const errors = {};
        if(!this.state.startDate) {
            errors.startDate = true;
        }

        if(!this.state.endDate) {
            errors.endDate = true;
        }

        if (this.state.startDate && this.state.endDate) {
            const start = new Date(this.state.startDate);
            const end = new Date(this.state.endDate);

            if(start > end) {
                errors.startDate = true;
                errors.endDate = true;
            }
        }

        if(!this.state.productId) {
            errors.productId = true;
        }

        if(this.state.productId === 1) {
            if(!this.state.licenseType) {
                errors.licenseType = true;
            } else if (this.state.licenseType === 'quota' && !this.state.quota) {
                errors.quota = true;
            }
        }

        if(this.state.productId === 2 && !this.state.quota) {
            errors.quota = true;
        }

        if(Object.keys(errors).length !== 0) {
            this.setState({ errors });
            return false;
        } else {
            return true;
        }
    }

    handleClick() {
        this.setState({
            errors: null
        });
        if(!this.validateLicense()) { 
            return;
        }

        const license = {
            organizationId: this.props.org.id,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            productId: this.state.productId,
            licenseDetails: {}
        }

        if(this.state.productId === 1) {
            license.licenseDetails.licenseType = this.state.licenseType
        }

        if(this.state.productId === 1 && this.state.licenseType === 'attack vectors') {
            Object.assign(license.licenseDetails, this.state.chosenVectors);
        } else {
            license.licenseDetails.quota = this.state.quota;
        }

        this.props.createFunc(license).then(() => {
            this.toggle();
        });
    }

    render() {
        return(
            <div>
                <Button outline color='secondary' size="sm" onClick={this.toggle}>
                    <i className='mr-1 mdi mdi-account-card-details'/>
                    Create license
                </Button>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className='modal-dialog-centered'
                  data-theme='dark'
                >
                <ModalHeader toggle={this.toggle}>Create license</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Organization</InputGroupText>
                                </InputGroupAddon>
                                <Input type='text' value={this.props.org.name} disabled/>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Start Date</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type='date'
                                    placeholder='Start Date'
                                    invalid={this.state.errors && this.state.errors.startDate}
                                    onChange={e => this.setState({ startDate: e.target.value })}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>End Date</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type='date'
                                    placeholder='End Date'
                                    invalid={this.state.errors && this.state.errors.endDate}
                                    onChange={e => this.setState({ endDate: e.target.value })}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>Product</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type="select"
                                    name="Select Product"
                                    invalid={this.state.errors && this.state.errors.productId}
                                    onChange={(e) => this.setState({
                                        productId : parseInt(e.target.value),
                                        licenseType: null,
                                        quota: null,
                                        chosenVectors: null
                                    })}
                                >
                                    <option value='' selected disabled>Select Product</option>
                                    {products.map(product => (
                                        <option key={product.id} value={product.id}>{product.name}</option>
                                    ))}
                                </Input>
                            </InputGroup>
                        </FormGroup>
                        {this.state.productId === 1?
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>License Type</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type="select"
                                        name="Select License Type"
                                        invalid={this.state.errors && this.state.errors.productId}
                                        onChange={(e) => this.setState({
                                            licenseType : e.target.value,
                                            quota: null,
                                            chosenVectors: null
                                        })}
                                    >
                                        <option value='' selected disabled>Select License Type</option>
                                        <option>quota</option>
                                        <option>attack vectors</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        : null}
                        {this.state.productId?
                            this.state.productId === 1 && this.state.licenseType === 'attack vectors'?
                                <FormGroup>
                                    {this.props.vectors.filter(v => !v.hide).map(v => 
                                        <div className="form-check form-check-inline">
                                            <CustomInput
                                                type="checkbox"
                                                id={v.propName}
                                                label={v.title}
                                                onChange={e => {
                                                    const chosenVectors = {};
                                                    if (this.state.chosenVectors) {
                                                        Object.assign(chosenVectors, this.state.chosenVectors);
                                                    }

                                                    chosenVectors[v.propName] = e.target.checked;

                                                    this.setState({ chosenVectors });
                                                }}
                                            />
                                        </div>
                                    )}
                                </FormGroup>
                            :   
                                this.state.productId === 2 || this.state.licenseType === 'quota'?
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Quota</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type='number'
                                                placeholder='Quota'
                                                invalid={this.state.errors && this.state.errors.quota}
                                                onChange={e => this.setState({ quota: e.target.value })}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                : null
                        : null}
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button color="info" onClick={this.handleClick}>
                    Create
                </Button>{' '}
                <Button color="secondary" onClick={this.toggle}>
                    Cancel
                </Button>
                </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default CreateLicenseButton;