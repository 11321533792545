import React, { useState,useEffect  } from 'react';
import './SearchBar.css';

function SearchBar(props) {
  const [query, setQuery] = useState("");

  
  useEffect(() => {
    onSearch(query);
  }, [query]);
  
  const onSearch= (query) =>{
    props.searchFunction(query);
  }

  const handleInputChange = event => {
    setQuery(event.target.value);
  };


  return (
    <form className="search-form">
      <input
        type="text"
        placeholder="Search..."
        value={query}
        onChange={handleInputChange}
      />
    </form>
  );
}

export default SearchBar;