import React from 'react';
import {
	Row,
	Col,
	Button
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import user from '../../services/user';

import img2 from '../../assets/images/background/login.jpg';
import img3 from '../../assets/images/eye-of-the-enemy-logo-text.png';

const sidebarBackground = {
	backgroundImage: "url(" + img2 + ")",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "center center"
};

class NoLicense extends React.Component {
	render() {
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        if (user.isLicensed()) {
            return (<Redirect to={from} />);
        };
        
		return <div className="auth-wrapper align-items-center d-flex" style={sidebarBackground}>
			<div className="container">
				<div >
					<Row className="no-gutters justify-content-center">
                        <Col md="6" lg="4" className="text-white p-2" data-theme='dark'>
                            <div className="logo text-center">
                                <img src={img3} alt="Home"/>
                            </div>
							<div className="mt-3 text-center">
                                <h3>No valid license</h3>
							</div>
							<Button block size='lg' onClick={() => { user.logout().then(() => { window.location.href = '../'; }) }}>
								Logout
							</Button>
						</Col>
					</Row>
				</div>
			</div>
		</div>;
	}
}

export default NoLicense;
