import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  UncontrolledTooltip
} from 'reactstrap';
import sdk from 'api-sdk';
import vectors from '../../../assets/data/attackVectors.json';

import CreateLicenseButton from './createLicenseButton';
import EditLicenseButton from "./editLicenseButton";

const productNames = {
    1: 'Eye of the Enemy',
    2: 'Attack Hunter'
};

const toLocaleString = str => {
    const date = new Date(str);
    return date.toLocaleString('en-il');
};

const toLocaleDateString = str => {
    const date = new Date(str);
    return date.toLocaleDateString('en-il');
};


class LicenseCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeLicenses: [],
            expiredLicenses: [],
        };

        this.getLicenses = this.getLicenses.bind(this);
        this.createLicense = this.createLicense.bind(this);
        this.license2Row = this.license2Row.bind(this);
        this.updateLicense = this.updateLicense.bind(this);
    }

    license2Row (license)  {
        let licenseType;
        if(license.productId !== 1) {
            licenseType = 'quota';
        } else {
           licenseType = license.eyeOfTheEnemyLicense.licenseType;
        }
    
        let quota;
        let attackVectors;
        if(license.productId === 2 && license.attackHunterLicense) {
            quota = license.attackHunterLicense.quota;
        } else if (license.productId === 1 && license.eyeOfTheEnemyLicense) {
            quota = license.eyeOfTheEnemyLicense.quota;
            attackVectors = vectors.filter(v => license.eyeOfTheEnemyLicense[v.propName])
            .map(v => v.title);
        }
    
        return <tr key={license.id}>
            <td>{license.id}</td>
            <td>{productNames[license.productId]}</td>
            <td>{toLocaleDateString(license.startDate)}</td>
            <td>{toLocaleDateString(license.endDate)}</td>
            <td>{toLocaleString(license.createdAt)}</td>
            <td>{licenseType}</td>
            <td>{quota}</td>
            {attackVectors && attackVectors.length > 0?
                <td>
                    <span
                        href="#"
                        id={`attackVectors-${license.id}`}
                    >
                        <strong>{attackVectors.length}</strong>
                    </span>
                    <UncontrolledTooltip
                        placement="bottom"
                        target={`attackVectors-${license.id}`}
                    >
                        <ol>
                            {attackVectors.map(vector => <li>{vector}</li>)}
                        </ol>
                    </UncontrolledTooltip>
                </td>
            : <td></td>}
            {new Date(license.endDate) > new Date() &&  
            <td>
                <span className="float-right" >
                    <EditLicenseButton org={this.props.org} updateFunc={this.updateLicense} licenseId={license.id} vectors={vectors}/>
                </span>
            </td>}
            
        </tr>;
    }

    getLicenses() {
        sdk.licenses.get({
            where: {
                organizationId: this.props.org.id
            },
            include: ['attackHunterLicense','eyeOfTheEnemyLicense']
        }).then(licenses => {
            this.setState({
                activeLicenses: licenses.filter(license => new Date(license.endDate) > new Date()),
                expiredLicenses: licenses.filter(license => new Date(license.endDate) <= new Date()),
            });
        });
    }

    createLicense(license) {
        return sdk.licenses.create(license).then(this.getLicenses);
    }

    updateLicense(license) {
        return sdk.licenses.update(license).then(this.getLicenses);
    }

    componentDidMount() {
        this.getLicenses();
    }

    componentDidUpdate(prevProps) {
        if(this.props.org.id !== prevProps.org.id) {
            this.getLicenses();
        }
    }
    
    render() {
        return (
        <div>
            <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                    Active Licenses
                    <span className="float-right">
                        <CreateLicenseButton
                            createFunc={this.createLicense}
                            vectors={vectors}
                            org={this.props.org}
                        />
                    </span>
                </CardTitle>
                <CardBody className='p-0'>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Product</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Created At</th>
                                <th>License Type</th>
                                <th>Quota</th>
                                <th>Attack Vectors</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.activeLicenses.map(this.license2Row)}
                            {this.state.activeLicenses.length < 1?
                                <tr key='no-licenses'>
                                    <td colpan='5'>
                                        <h3>
                                            No active licenses
                                        </h3>
                                    </td>
                                </tr>
                            : null}
                        </tbody>
                        
                    </Table>
                </CardBody>
            </Card>
            <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                    License History
                </CardTitle>
                <CardBody className='p-0'>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Product</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Created At</th>
                                <th>License Type</th>
                                <th>Quota</th>
                                <th>Attack Vectors</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.expiredLicenses.map(this.license2Row)}
                            {this.state.expiredLicenses.length < 1?
                                <tr key='no-licenses'>
                                    <td colpan='5'>
                                        <h3>
                                            No expired licenses
                                        </h3>
                                    </td>
                                </tr>
                            : null}
                        </tbody>                       
                    </Table>
                </CardBody>
            </Card>
        </div>
        );
    }
}

export default LicenseCard;