import { request, requestDownload } from './client';
var model = 'eye-of-the-enemy/SilverBullets';
var silverBullets = {
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  getById: function getById(id, filter) {
    return request('get', "/".concat(model, "/").concat(id), null, {
      filter: filter
    });
  },
  create: function create(bullet) {
    return request('post', "/".concat(model), bullet);
  },
  update: function update(id, bullet) {
    return request('patch', "/".concat(model, "/").concat(id), bullet);
  },
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  },
  getDocument: function getDocument(silverBulletId, docName) {
    return request('get', "/".concat(model, "/").concat(silverBulletId, "/get-document/").concat(docName));
  },
  getChartLink: function getChartLink(silverBulletId) {
    return requestDownload("/".concat(model, "/").concat(silverBulletId, "/chart"));
  },
  uploadFile: function uploadFile(file, silverBulletId, fileType) {
    // eslint-disable-next-line no-undef
    var formData = new FormData();
    formData.append('file', file);
    var config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return request('post', "/".concat(model, "/").concat(silverBulletId, "/document?docType=").concat(fileType), formData, config);
  },
  getTechniques: function getTechniques(silverBulletId) {
    return request('get', "/".concat(model, "/").concat(silverBulletId, "/techniques"));
  },
  relateTechnique: function relateTechnique(silverBulletId, techId) {
    return request('put', "/".concat(model, "/").concat(silverBulletId, "/techniques/rel/").concat(techId));
  },
  deleteTechniqueRelation: function deleteTechniqueRelation(silverBulletId, techId) {
    return request('delete', "/".concat(model, "/").concat(silverBulletId, "/techniques/rel/").concat(techId));
  },
  relateAptTag: function relateAptTag(silverBulletId, tagId) {
    return request('put', "/".concat(model, "/").concat(silverBulletId, "/aptTags/rel/").concat(tagId));
  },
  relateNewAptTag: function relateNewAptTag(silverBulletId, aptName) {
    return request('post', "/".concat(model, "/").concat(silverBulletId, "/aptTags"), {
      aptName: aptName
    });
  },
  deleteAptTagRelation: function deleteAptTagRelation(silverBulletId, tagId) {
    return request('delete', "/".concat(model, "/").concat(silverBulletId, "/aptTags/rel/").concat(tagId));
  },
  getPayloads: function getPayloads(silverBulletId, filter) {
    return request('get', "/".concat(model, "/").concat(silverBulletId, "/payloads"), null, {
      filter: filter
    });
  },
  postPayload: function postPayload(silverBulletId, payload) {
    return request('post', "/".concat(model, "/").concat(silverBulletId, "/payloads"), payload);
  },
  downloadPayload: function downloadPayload(silverBulletId, payloadId, fileName) {
    return requestDownload("/".concat(model, "/").concat(silverBulletId, "/payloads/").concat(payloadId, "/download"), fileName);
  },
  getPayloadFile: function getPayloadFile(silverBulletId, payloadId) {
    return request('get', "/".concat(model, "/").concat(silverBulletId, "/payloads/").concat(payloadId, "/download"), undefined, undefined, {
      responseType: 'arraybuffer'
    });
  },
  //sendPayload: (silverBulletId, payloadId) => request('get',`/${model}/${silverBulletId}/payloads/${payloadId}/send`),
  getDefRules: function getDefRules(silverBulletId, filter) {
    return request('get', "/".concat(model, "/").concat(silverBulletId, "/defenceRules"), null, {
      filter: filter
    });
  },
  postDefRule: function postDefRule(silverBulletId, defRule) {
    return request('post', "/".concat(model, "/").concat(silverBulletId, "/defenceRules"), defRule);
  },
  downloadDefRule: function downloadDefRule(silverBulletId, defRuleId, fileName) {
    return requestDownload("/".concat(model, "/").concat(silverBulletId, "/defenceRules/").concat(defRuleId, "/download"), fileName);
  },
  sendDefRule: function sendDefRule(silverBulletId, defRuleId) {
    return request('get', "/".concat(model, "/").concat(silverBulletId, "/defenceRules/").concat(defRuleId, "/send"));
  },
  downloadReport: function downloadReport(format) {
    return requestDownload("/".concat(model, "/report?format=").concat(format), "silver-bullets-report.".concat(format));
  },
  getDefRuleFile: function getDefRuleFile(silverBulletId, defRuleId) {
    return request('get', "/".concat(model, "/").concat(silverBulletId, "/defenceRules/").concat(defRuleId, "/download"), undefined, undefined, {
      responseType: 'arraybuffer'
    });
  },
  paginate: function paginate(page, filter) {
    // Ensure that the page is a number
    var pageNumber = Number(page);

    if (isNaN(pageNumber)) {
      throw new Error('Page must be a valid number');
    } // Adjusted to use path parameter


    return request('get', "/".concat(model, "/paginateBullets/").concat(pageNumber), {
      filter: filter
    });
  },
  search: function search(searchQuery, platformFilter) {
    // Handle empty searchQuery or platformFilter
    searchQuery = searchQuery || 'none'; // Use 'none' or a similar placeholder for empty values

    platformFilter = platformFilter || 'none';
    return request('get', "/".concat(model, "/searchBullets/").concat(searchQuery, "/").concat(platformFilter));
  },
  sendBase64PayloadEmail: function sendBase64PayloadEmail(silverBulletId, payloadId, email, encoding) {
    // Encode the email and encoding values to ensure they are safe for URL inclusion
    var queryParams = new URLSearchParams({
      email: email,
      encoding: encoding
    }).toString();
    return request('get', "/".concat(model, "/").concat(silverBulletId, "/payloads/").concat(payloadId, "/sendbase64payloademail?").concat(queryParams));
  },
  sendBytesPayloadEmail: function sendBytesPayloadEmail(silverBulletId, payloadId, email, encoding) {
    // Encode the email and encoding values to ensure they are safe for URL inclusion
    var queryParams = new URLSearchParams({
      email: email,
      encoding: encoding
    }).toString();
    return request('get', "/".concat(model, "/").concat(silverBulletId, "/payloads/").concat(payloadId, "/sendbyteencodedpayloademail?").concat(queryParams));
  }
};
export default silverBullets;