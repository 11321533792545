import React, { useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    FormFeedback,
} from 'reactstrap';
import TagContainer from './tagContainer';
import DeleteButton from '../../components/buttons/deleteButton';
import SubmitButton from '../../components/buttons/submitButton';


export default props => {
    const [inputTag, setTag] = useState('');
    const errors = props.payload.errors;
    return (
        <Card key={`payload-upload-${props.index}`}>
            <CardTitle className='text-danger bg-light border-bottom p-3 mb-0'>
                Payload file #{props.index + 1}
                {!props.payload.id?
                    <span className="float-right">
                        <Button className='btn-outline-danger' size='sm' disabled={props.disabled} onClick={() => props.onRemove(props.index)}>
                            <i className='fas fa-times'/>
                        </Button>
                    </span>
                : null}
                {props.editor && props.payload.id?
                    <span className="float-right">
                        <DeleteButton item='payload' deleteFunc={() => props.onDelete(props.payload.id, props.index)}/>
                    </span>
                : null}
            </CardTitle>
            <CardBody>
                <Row>
                    <Col lg='6' md='12'>
                        <Row>
                            <Col xl='7'>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Name</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="text"
                                            value={props.payload.name || ''}
                                            disabled={props.disabled}
                                            invalid={errors && errors.name}
                                            onChange={e => props.onChange(props.index, 'name', e.target.value)}
                                        />
                                        <FormFeedback>
                                            {errors && errors.name}
                                        </FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xl='5'>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>File format</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="text"
                                            value={props.payload.fileFormat || ''}
                                            disabled={props.disabled}
                                            invalid={errors && errors.fileFormat}
                                            onChange={e => props.onChange(props.index, 'fileFormat', e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>File</InputGroupText>
                                        </InputGroupAddon>
                                        <div className="custom-file">
                                        <Input
                                            type="file"
                                            className="custom-file-input"
                                            id={`defRule-file-${props.index}`}
                                            disabled={props.disabled}
                                            onChange={e => props.onChange(props.index, 'file', e.target.files[0])}
                                        />
                                        <label
                                            className="custom-file-label"
                                            htmlFor="fileInput"
                                        >
                                            {props.payload && props.payload.file? props.payload.file.name : "Choose file"}
                                        </label>
                                        </div>
                                    </InputGroup>
                                    <h6 className='text-danger font-light'>
                                        {errors && errors.file}
                                    </h6>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg='6' md='12'>
                        <FormGroup>
                            <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Software tags</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                value={inputTag}
                                list="softwareTags"
                                disabled={props.creating}
                                onChange={e => setTag(e.target.value)}
                            />
                            <InputGroupAddon addonType="append">
                                <Button onClick={e => props.onChange(props.index, 'addTag', inputTag)}>Add</Button>
                            </InputGroupAddon>
                            <datalist id="softwareTags">
                                {props.softwareTags.map(tag => (
                                    <option key={tag.softwareName + '-option'}>{tag.softwareName}</option>
                                ))}
                            </datalist>
                            </InputGroup>
                        </FormGroup>
                        <TagContainer
                            title='Software tags'
                            tags={props.payload.softwareTags}
                            nameProp='softwareName'
                            onRemove={index => props.onChange(props.index, 'removeTag', index)}
                        />
                    </Col>
                </Row>
                {props.editor?
                    <Row className='mt-4'>
                        <Col xl={{ size: 2, offset: 5 }} lg={{ size: 4, offset: 4 }} md='10'>
                            <FormGroup className='mb-0'>
                                <SubmitButton item='payload' submitFunc={() => props.onSubmit(props.index)} disabled={props.disabled}/>
                            </FormGroup>
                        </Col>
                        {props.payload.id? 
                            <Col xl={{ size: 1, offset: 4 }} lg={{ size: 2, offset: 2 }} md='2'>
                                <Button color="danger" size="lg" block disabled={props.disabled} onClick={() => props.onRevert(props.index)}>
                                    <i className="fas fa-redo"/>
                                </Button>
                            </Col>
                        : null}
                    </Row>
                : null}
                <Row>
                    <Col>{props.editor && props.payload.errors && props.payload.errors.upload}</Col>
                </Row>
            </CardBody>
        </Card>
    );
};