import React from 'react';
import user from '../../services/user';
import LoginVerification from './loginVerification';

export default props => {
    return (
        <LoginVerification
            updateFunc={props.updateFunc}
            verifyFunc={user.verifyToken}
        />
    );
};
