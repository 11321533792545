import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label, Input, ModalFooter } from 'reactstrap';

const SendModal = ({ isOpen, toggle, onSend }) => {
    const [email, setEmail] = useState('');
    const [encoding, setEncoding] = useState('Base64');
    const [showInstructions, setShowInstructions] = useState(false);

    const toggleInstructions = () => setShowInstructions(!showInstructions);

    const downloadInstructions = () => {
        const instructions = getInstructions();
        const blob = new Blob([instructions], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `DecodingInstructions-${encoding}.txt`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getInstructions = () => {
        switch (encoding) {
            case 'Base64':
                return `Base64 -\nFile Encoded successfully with Base64.\nPowershell Command for Decoding:\n$Base64Exe = @"PASTE_YOUR_BASE64_STRING_HERE"@\n$OutputExePath = "C:\\Path\\To\\Output\\ExeFile.exe"\n[Byte[]]$ExeBytes = [System.Convert]::FromBase64String($Base64Exe)\nSet-Content -Path $OutputExePath -Value $ExeBytes -Encoding Byte\nEnd Powershell Command`;
            // case 'certutil':
            //     return `Certutil -\ncertutil -decode file1 file2`;
            case 'Bytes':
                return `Bytes Decoded -\nfunction bytes2file {\n$file = Read-Host 'File Path'\n$out = Read-Host 'Output Path'\n$bytes = Get-Content -Path $file\n[IO.File]::WriteAllBytes($out, $bytes)\n}`;
            default:
                return '';
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className='modal-dialog-centered' data-theme='dark'>
            <ModalHeader toggle={toggle}>Send Payload</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="email">Email</Label>
                    <Input type="email" name="email" id="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for="encoding">Encoding</Label>
                    <Input type="select" name="encoding" id="encoding" value={encoding} onChange={e => setEncoding(e.target.value)}>
                        <option>Base64</option>
                        <option>Bytes</option>
                        {/* <option>certutil</option> */}
                    </Input>
                </FormGroup>
                {showInstructions && (
                    <div>
                        <h5>Decode Instructions</h5>
                        <p>{getInstructions()}</p>
                    </div>
                )}
            </ModalBody>
            <ModalFooter className="justify-content-between">
                <Button color="primary" onClick={() => onSend(email, encoding)}>Send</Button>
                <div>
                    <Button color="secondary" onClick={toggleInstructions} className="mr-2">Show Instructions</Button>
                    {showInstructions && <Button color="info" onClick={downloadInstructions}>Download Instructions</Button>}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default SendModal;
