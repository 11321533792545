import { request } from './client';
var model = 'eye-of-the-enemy/Techniques';
var techniques = {
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  getById: function getById(id, filter) {
    return request('get', "/".concat(model, "/").concat(id), null, {
      filter: filter
    });
  },
  create: function create(bullet) {
    return request('post', "/".concat(model), bullet);
  },
  update: function update(id, bullet) {
    return request('patch', "/".concat(model, "/").concat(id), bullet);
  },
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  }
};
export default techniques;