import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

class setRoleButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            role: null,
        }

        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleClick(e) {
        e.stopPropagation();
        if(this.state.role) {
            this.props.setRoleFunc(this.state.role);
            this.toggle();
            this.setState({
                userId: null,
            });
        }
    }

    render() {
        return(
            <div>
                <Button color='secondary' size='sm' onClick={this.toggle}>
                    {this.props.delete ? "Remove Role" : "Set Role"}
                </Button>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className='modal-dialog-centered'
                  data-theme='dark'
                >
                <ModalHeader toggle={this.toggle}>Set Role </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Input type="select" required onChange={e => this.setState({role: e.target.value})}>
                                <option key='Role-placeholder' default hidden>Select Role</option>
                                <option key='Support-user'>support</option>
                                <option key='Uploader'>uploader</option>
                                <option key='Viewer'>viewer</option>
                            </Input>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button color="info" onClick={this.handleClick} disabled={!(this.state.role)}>
                    {this.props.delete ? "Remove Role" : "Set Role"}
                </Button>{' '}
                <Button color="secondary" onClick={this.toggle}>
                    Cancel
                </Button>
                </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default setRoleButton;