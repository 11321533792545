import React from "react";
import {
    Col,
    Row
} from 'reactstrap';
import ChangePassword from './changePassword';
import ResetSecret from './resetSecret';


export default props => (
    <Row>
        <Col md='6'>
            <ChangePassword/>
        </Col>
        <Col md='6'>
            <ResetSecret/>
        </Col>
    </Row>
);