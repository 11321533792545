import { request } from './client';
var model = 'AppUsers';
var users = {
  login: function login(email, password, captchaValue) {
    return request('post', "/".concat(model, "/login"), {
      email: email,
      password: password,
      captchaValue: captchaValue
    }, {
      include: 'User'
    });
  },
  logout: function logout() {
    return request('post', "/".concat(model, "/logout"));
  },
  get: function get() {
    return request('get', "/".concat(model));
  },
  add: function add(user) {
    return request('post', "/".concat(model), user);
  },
  updateLoginState: function updateLoginState(user) {
    return request('put', "/".concat(model, "/update-login-state"), user);
  },
  delete: function _delete(userId) {
    return request('delete', "/".concat(model, "/").concat(userId));
  },
  freeze: function freeze(user) {
    return request('put', "/".concat(model, "/freeze"), user);
  },
  getUserRoles: function getUserRoles(userId) {
    return request('get', "/".concat(model, "/getUserRoles/").concat(userId));
  },
  setRole: function setRole(user, role) {
    return request('put', "/".concat(model, "/setRole/").concat(role), user);
  },
  deleteRole: function deleteRole(user, role) {
    return request('put', "/".concat(model, "/deleteRole/").concat(role), user);
  },
  getUser: function getUser(id) {
    return request('get', "/".concat(model, "/").concat(id));
  },
  getUsers: function getUsers() {
    return request('get', "/".concat(model));
  },
  changePassword: function changePassword(oldPassword, newPassword) {
    return request('post', "/".concat(model, "/change-password"), {
      oldPassword: oldPassword,
      newPassword: newPassword
    });
  },
  setPasswordById: function setPasswordById(id, newPassword) {
    return request('post', "/".concat(model, "/").concat(id, "/set-password"), {
      newPassword: newPassword
    });
  },
  createSecret: function createSecret() {
    return request('post', "/".concat(model, "/create-secret"));
  },
  hasVerifiedSecret: function hasVerifiedSecret() {
    return request('get', "/".concat(model, "/has-verified-secret"));
  },
  verifySecret: function verifySecret(token) {
    return request('post', "/".concat(model, "/verify-secret"), {
      token: token
    });
  },
  verifyToken: function verifyToken(token) {
    return request('post', "/".concat(model, "/verify-token"), {
      token: token
    });
  },
  resetSecret: function resetSecret(password, token) {
    return request('post', "/".concat(model, "/reset-secret"), {
      password: password,
      token: token
    });
  },
  resetSecretById: function resetSecretById(id) {
    return request('post', "/".concat(model, "/").concat(id, "/reset-secret"));
  },
  getNotifications: function getNotifications(userId, filter) {
    return request('get', "/".concat(model, "/").concat(userId, "/notifications"), null, {
      filter: filter
    });
  },
  deleteNotification: function deleteNotification(userId, notificationId) {
    return request('delete', "/".concat(model, "/").concat(userId, "/notifications/").concat(notificationId));
  },
  setPayloadDownload: function setPayloadDownload(userId, userEmail, payloadId, payloadName, payloadFileName) {
    return request('post', "/".concat(model, "/setPayloadDownload"), {
      userId: userId,
      userEmail: userEmail,
      payloadId: payloadId,
      payloadName: payloadName,
      payloadFileName: payloadFileName
    });
  }
};
export default users;