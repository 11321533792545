import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import user from '../../services/user';
import sdk from 'api-sdk';
import generatePass from 'generate-password'

class CreateUserButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            email: '',
            password: '',
            emailFB: '',
            passwordFB: '',
            organizationId: null,
            organizationFB: '',
            organizations: ''

        }

        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleClick() {
        if(this.state.email && this.state.password) {
            this.props.createFunc(this.state.email, this.state.password, this.state.organizationId)
            this.toggle();
            this.setState({
                isOrgAdmin: false,
                email: '',
                password: '',
                emailFB: '',
                passwordFB: '',
                organizationId: '1',
                organizationFB: '',
            
            });
        }
        else {
            if(!this.state.email) {
                this.setState({emailFB: 'Email is required'});
            }
            if(!this.state.password) {
                this.setState({passwordFB: 'Password is required'});
            }
            if(!this.state.organizationId) {
                this.setState({organizationFB: 'Organization is required'});
            }
        }
    }

    componentDidMount() {

        const randomPass = generatePass.generate({
            length: 10,
            numbers: true
        })

        this.setState({password: randomPass})
        const userRoles = user.getRoles();
        const isOrgAdmin = userRoles.some(s => s === 'org-admin') && (!userRoles.find(s => s === 'root'));
        const isSupport = userRoles.some( s => s === 'support');

        if(isSupport){
            sdk.organizations.get().then(organizations => {
                this.setState({organizations});
            });
        }
        else if(isOrgAdmin) {
            const orgId = user.getUserData().organizationId;
            sdk.organizations.getById(orgId).then(org => {
                // console.log(org);
                this.setState({
                    organizations: [ org ],
                    isOrgAdmin: true,
                    organizationId: orgId
                });
            });
        }else {
            sdk.organizations.get().then(organizations => {
                this.setState({organizations});
            });
        }

        // console.log('org', this.state.organizations)
    }

    render() {
        return(
            <div>
                <Button outline color='secondary' size="sm" onClick={this.toggle}>
                    <i className='mr-1 mdi mdi-account-plus'/>
                    Create user
                </Button>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className='modal-dialog-centered'
                  data-theme='dark'
                >
                <ModalHeader toggle={this.toggle}>Create user</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <InputGroup>
                                <Input autoComplete="none" placeholder="Email" required onChange={(e) => this.setState({email : e.target.value})} invalid={!!this.state.emailFB}/>
                                <FormFeedback>
                                    {this.state.emailFB}
                                </FormFeedback>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <InputGroup>
                                <Input readOnly="readonly" autoComplete="none" value={this.state.password} placeholder="Password"  type="password" required onChange={(e) => this.setState({password : e.target.value})} invalid={!!this.state.passwordFB}/>
                                <FormFeedback>
                                    {this.state.passwordFB}
                                </FormFeedback>
                            </InputGroup>
                        </FormGroup>
                        {this.state.organizations?
                            <FormGroup>
                                <InputGroup>
                                    <Input
                                        type="select"
                                        name="Select Organization"
                                        disabled={this.state.isOrgAdmin}
                                        onChange={(e) => this.setState({organizationId : this.state.organizations[e.target.options.selectedIndex].id})}
                                    >
                                        {this.state.organizations.map(org => (
                                            <option key={org.id} value={org.id}>{org.name}</option>
                                        ))}
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        :
                            <h2 className='text-center'><i className='fas fa-spin fa-spinner'/></h2>
                        }
                        <input type="text" autoComplete="on" value="" style={{display: 'none', opacity: 0, position: 'absolute', left: '-100000px'}} readOnly={true}/>
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button color="info" onClick={this.handleClick} disabled={!this.state.organizations || !this.state.organizationId}>
                    Create
                </Button>{' '}
                <Button color="secondary" onClick={this.toggle}>
                    Cancel
                </Button>
                </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default CreateUserButton;