import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './notePopup.css';

const NotePopup = ({ isOpen, toggle, note, runDate }) => {
  // Function to split the note into lines without cutting words
  const splitNoteIntoLines = (note, lineLength) => {
    const lines = [];
    let start = 0;
    while (start < note.length) {
      let end = Math.min(start + lineLength, note.length);
      // Adjust end position to avoid cutting words
      while (end < note.length && !/\s/.test(note[end])) {
        end++;
      }
      lines.push(note.substring(start, end));
      start = end + 1;
    }
    return lines;
  };

  // Split the note into lines with a maximum length of 40 characters
  const lines = splitNoteIntoLines(note, 35); // Adjust line length as needed

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="note-modal">
      <ModalHeader toggle={toggle} className="note-modal-header">{runDate}</ModalHeader>
      <ModalBody className="note-modal-body">
        <div className="note-paper">
          {lines.map((line, index) => (
            <div key={index} className="note-line">{line}</div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NotePopup;
