import React from 'react';
import sdk from 'api-sdk';
import userService from '../../services/user';
import {
    ButtonDropdown,
    Card,
    CardTitle,
    CardBody,
    Col,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
} from 'reactstrap';
import attackVectors from '../../assets/data/attackVectors.json';
import { Doughnut } from 'react-chartjs-2';
import './licenseWidget.css';

const trueIcon = <i className='fas fa-check'/>;
const falseIcon = <i className='fas fa-times'/>;
const spinnerIcon = <i className='fas fa-spin fa-spinner'/>;

class LicenseWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            total: null,
            isOpen: false
        };
    }

    componentDidMount() {
        if (userService.isRoot()) {
            return;
        }
        sdk.actions.countDownloadedActions().then(res => this.setState({ total: res.count }));
        sdk.licenses.getStatus(1).then(license => {
            this.setState({
                license: license
            });
        });
    }

    render() {
        if (userService.isRoot()) {
            return null;
        }

        const eyeLicense = this.state.license && this.state.license.eyeOfTheEnemyLicense;
        const progressValue = this.state.license && this.state.license.usedQuota/eyeLicense.quota * 100;
        return (
            <ButtonDropdown
                className='mega-dropdown'
                isOpen={this.state.isOpen && !!this.state.license}
                toggle={() => this.setState(state => ({ isOpen: !state.isOpen }))}
            >
                <DropdownToggle color='info'>
                    <h6>License expires at</h6>
                    <h3>{this.state.license?
                        new Date(this.state.license.endDate).toLocaleDateString('en-il')
                    :
                        <i className='fas fa-spin fa-spinner'/>
                    }</h3>
                </DropdownToggle>
                {eyeLicense && eyeLicense.licenseType === 'quota'? 
                    <DropdownToggle color='primary'>
                        <h6 className='ml-4 mr-4'>Used Quota</h6>
                        <Progress id='quota-bar' value={progressValue} color='white'>
                            <div className={progressValue? 'text-primary' : 'text-white'}>{`${this.state.license.usedQuota}/${eyeLicense.quota}`}</div>
                        </Progress>
                    </DropdownToggle>
                : null}
                <DropdownMenu>
                {this.state.license?
                    <Row>
                        <Col md='4'>
                            <Card body inverse color="info" className="border mb-0">
                                <CardTitle tag='h2'>Product License</CardTitle>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h3>Product: Eye of the Enemy</h3>
                                            <h3>From: {(new Date(this.state.license.startDate)).toLocaleDateString('en-il')}</h3>
                                        </Col>
                                        <Col>
                                            <h3>Type: {this.state.license.eyeOfTheEnemyLicense? this.state.license.eyeOfTheEnemyLicense.licenseType : '???'}</h3>
                                            <h3>To: {(new Date(this.state.license.endDate)).toLocaleDateString('en-il')}</h3>
                                        </Col>
                                    </Row>   
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md='4'>
                            {this.state.license.eyeOfTheEnemyLicense.licenseType === 'attack vectors'?
                                <Card body inverse color="success" className="border pb-0 mb-0">
                                    <CardTitle tag='h2'>Licensed Attack Vectors</CardTitle>
                                    <CardBody className='pb-0'>
                                        <Row>
                                            <Col>
                                                {attackVectors.slice(0,3).map(vector => (
                                                    <h4 key={vector.title+'-indicator'}>{vector.title}: {this.state.license.eyeOfTheEnemyLicense[vector.propName]? trueIcon : falseIcon}</h4>
                                                ))}
                                            </Col>
                                            <Col>
                                                {attackVectors.slice(3,6).map(vector => (
                                                    <h4 key={vector.title+'-indicator'}>{vector.title}: {this.state.license.eyeOfTheEnemyLicense[vector.propName]? trueIcon : falseIcon}</h4>
                                                ))}
                                            </Col>
                                            <Col>
                                                {attackVectors.slice(6,9).map(vector => (
                                                    <h4 key={vector.title+'-indicator'}>{vector.title}: {this.state.license.eyeOfTheEnemyLicense[vector.propName]? trueIcon : falseIcon}</h4>
                                                ))}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            :
                                <Card body inverse color="primary" className="border mb-0">
                                    <CardTitle tag='h2'>Monthly Quota Status</CardTitle>
                                    <CardBody>
                                        <Row>
                                            <Col md='4'>
                                                <h3>Monthly Quota: {this.state.license.eyeOfTheEnemyLicense? this.state.license.eyeOfTheEnemyLicense.quota : '???'}</h3>
                                                <h3>Used this month: {this.state.license.usedQuota}</h3>
                                            </Col>
                                            <Col md='4'>
                                                <h3>Overall downloads: {this.state.total !== null? this.state.total : spinnerIcon}</h3>
                                                <h3>Ratio: {progressValue.toLocaleString()}%</h3>
                                            </Col>
                                            <Col md='4'>
                                                <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 100 }}>
                                                    <Doughnut
                                                        data={{
                                                            labels: ['Used quota','Free quota'],
                                                            datasets: [{
                                                                borderWidth: 0,
                                                                backgroundColor: ['rgba(255, 255, 255, 0.25)','#7460ee'],
                                                                hoverBackgroundColor: ['rgba(255, 255, 255, 0.4)','#7460ee'],
                                                                data: [this.state.license.usedQuota, this.state.license.eyeOfTheEnemyLicense? this.state.license.eyeOfTheEnemyLicense.quota - this.state.license.usedQuota: 0]
                                                            }]
                                                        }}
                                                        options={{
                                                            cutoutPercentage: 75,
                                                            maintainAspectRatio: false,
                                                            legend: { display: false },
                                                            title: { display: false }
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>   
                            }
                        </Col>
                    </Row>
                : <Row>
                    <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
                </Row>}
                </DropdownMenu>
            </ButtonDropdown>
        );
    }
}

export default LicenseWidget;