import React  from 'react';
import { Button } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import sdk from 'api-sdk';

import './bulletDocument.css';

class HtmlViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            htmlString: ''
        };

        this.printDoc = this.printDoc.bind(this);
        this.loadDocument = this.loadDocument.bind(this);
    }

    printDoc() {
        const content  = document.getElementById(`${this.props.docName}-doc`).innerHTML;
        const printedHtml = '<html><head><title></title></head><body>' + content + '</body></html>';
        const printWindow = window.open('', 'mywindow', 'status=1');
        printWindow.document.body.innerHTML = printedHtml;
        printWindow.print();
    }

    loadDocument() {
        sdk.silverBullets.getDocument(this.props.silverBulletId, this.props.docName).then(str => {
            if (str.match('"image-source"')) {
                return sdk.silverBullets.getChartLink(this.props.silverBulletId).then(url => {
                    return str.replace('"image-source"',`"${url}"`);
                });
            } else {
                return Promise.resolve(str);
            }
        }).then(htmlString => this.setState({ htmlString }));
    }

    componentDidMount() {
        this.loadDocument();
    }

    componentDidUpdate(prevProps) {
        if(this.props.silverBulletId !== prevProps.silverBulletId) {
            this.setState({
                htmlString: ''
            });
            this.loadDocument();
        }
    }

    render() {
        if (!this.state.htmlString) {
            return (
                <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
            );
        }

        let iconName;

        switch (this.props.docName) {
            case 'description':
                iconName = 'mdi mdi-note-outline';
                break;
            case 'intel':
                iconName = 'mdi mdi-eye-outline';
                break;
            case 'instructions':
                iconName = 'mdi mdi-clipboard-text';
                break;
            default:
                break;
        }

        return (
            <div className={`bullet-document ${this.props.docName}-document`}>
                <PerfectScrollbar>
                <span className='float-left mr-1 mb-0'>
                    <h1>
                        <i className={iconName}/>
                    </h1>
                </span>
                <span className='float-right'>
                    <Button size='sm' className='btn-rounded btn-outline-secondary' onClick={this.printDoc}>
                        <i className='mdi mdi-printer'/>
                        {' '}Print {this.props.docName}
                    </Button>
                </span>
                <div id={`${this.props.docName}-doc`} dangerouslySetInnerHTML={{__html: this.state.htmlString}}/>
                </PerfectScrollbar>
            </div>
        );
    }
}

export default HtmlViewer;