import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from 'reactstrap';
import sdk from 'api-sdk';
import attackVectors from '../../assets/data/attackVectors.json';


class DownloadPayloads extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bullets: null,
    };

    this.getBullets = this.getBullets.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.downloadAllPayloads = this.downloadAllPayloads.bind(this)
    this.getPayloadsAndDwonload = this.getPayloadsAndDwonload.bind(this)
    this.downloadAllDefenceRules = this.downloadAllDefenceRules.bind(this)
    this.getDefenceRulesAndDwonload = this.getDefenceRulesAndDwonload.bind(this)
    this.downloadPayloadByVector = this.downloadPayloadByVector.bind(this)
    this.downloadDefenceRulesByVector = this.downloadDefenceRulesByVector.bind(this)
  }

  getBullets() {
    sdk.silverBullets.get().then(bullets => {
      this.setState({ bullets });
    });
  }

  getPayloadsAndDwonload(id) {
    sdk.silverBullets.getPayloads(id).then(newpayloads => newpayloads.forEach(payload => sdk.silverBullets.downloadPayload(id, payload.id, payload.fileName)));
  }

  getDefenceRulesAndDwonload(id) {
    sdk.silverBullets.getDefRules(id).then(newDefenceRules => newDefenceRules.forEach(rule => sdk.silverBullets.downloadDefRule(id, rule.id, rule.fileName)));
  }

  downloadAllPayloads() {
    this.getBullets();
    this.state.bullets.forEach(bullet => {
      this.getPayloadsAndDwonload(bullet.id);
    })
  }

  downloadPayloadByVector(vector) {
    this.getBullets();
    // console.log(this.state.bullets)
    var silverBullets = this.state.bullets.filter(bullet => bullet.attackVector === vector);
    silverBullets.forEach(bullet => {
      this.getPayloadsAndDwonload(bullet.id);
    })
  }

  downloadDefenceRulesByVector(vector) {
    this.getBullets();
    // console.log(this.state.bullets)
    var silverBullets = this.state.bullets.filter(bullet => bullet.attackVector === vector);
    silverBullets.forEach(bullet => {
      this.getDefenceRulesAndDwonload(bullet.id);
    })
  }

  downloadAllDefenceRules() {
    this.getBullets();
    this.state.bullets.forEach(bullet => {
      this.getDefenceRulesAndDwonload(bullet.id);
    })
  }

  handleDelete(id) {
    sdk.silverBullets.delete(id).then(this.getBullets);
  }

  componentDidMount() {
    this.getBullets();
    
  }

  render() {
    return (
      <Card>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          Download Payloads And Defence Rules By Vector
        </CardTitle>
        <CardBody className='p-0'>
          {this.state.bullets?
            <Table hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Mitre Id</th>
                  <th>Download All Payloads</th>
                  <th>Download All DefenceRules</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.bullets.length === 0?
                  <tr key='no-bullet'>
                    <td colpan='5'>
                      <h3>
                        No Silver Bullets
                      </h3>
                    </td>
                  </tr>
              : null}
              {
                attackVectors.map((vector) => (
                  <tr key={vector.title}>
                    <td>{vector.title}</td>
                    <td>{vector.mitreId}</td>
                    <td>
                        <Button className='btn-outline-secondary' onClick={() => {this.downloadPayloadByVector(vector.title)}}>
                            <i className='mdi mdi-download'/>
                        </Button>
                    </td>
                    <td>
                        <Button className='btn-outline-secondary' onClick={() => {this.downloadDefenceRulesByVector(vector.title)}}>
                            <i className='mdi mdi-download'/>
                        </Button>
                    </td>
                  </tr>
                )
              )}
              </tbody>
            </Table>
          : 
            <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
          }
        </CardBody>
      </Card>
    );
  }
}

export default DownloadPayloads;