import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import sdk from 'api-sdk';

class CreateUserButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            resetting: false
        }

        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleClick() {
        this.setState({resetting: true});
        sdk.users.resetSecretById(this.props.user.id)
        .then(() => {
            this.setState({resetting: true, modal: false});
        }).catch(e => this.setState({error: e.toString(), resetting: false}));
    }

    render() {
        return(
            <div>
                <Button outline color='secondary' onClick={this.toggle}>
                    Reset Secret
                </Button>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className='modal-dialog-centered'
                  data-theme='dark'
                >
                <ModalHeader toggle={this.toggle}>Reset Secret</ModalHeader>
                <ModalBody>
                    Reset the secret of user {this.props.user.email}.
                </ModalBody>
                <ModalFooter>
                <Button color="info" onClick={this.handleClick} disabled={this.state.resetting}>
                    {!this.state.resetting? 'Reset' : <i className='fas fa-spin fa-spinner'/>}
                </Button>{' '}
                <Button color="danger" onClick={this.toggle}>
                    Cancel
                </Button>
                </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default CreateUserButton;