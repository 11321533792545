import React from 'react';

const ErrorPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Account Frozen</h1>
      <p>Your account has been frozen due to policy violations or pending verifications.</p>
      <p>Please contact support for further assistance.</p>
    </div>
  );
};

export default ErrorPage;
