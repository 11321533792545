import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

class FreezeButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
        }

        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggle(e) {
        if (e) {
            e.stopPropagation();
        }
        this.setState({
            modal: !this.state.modal
        });
    }

    handleClick() {
        this.toggle();
        this.props.freezeFunc();
    }


    render() {
        return(
            <div>
                <Button className='btn-outline-info' onClick={this.toggle} disabled={this.props.disabled}>
                    <i className='mdi mdi-snowflake'/> {this.props.user.freeze ? 'unfreeze' : 'freeze'}
                </Button>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className='modal-dialog-centered'
                  data-theme='dark'
                >
                <ModalHeader toggle={this.toggle}>{this.props.item}</ModalHeader>
                <ModalBody>
                    Are you sure you want to freeze this {this.props.item}?
                </ModalBody>
                <ModalFooter>
                <Button color="info" onClick={this.handleClick}>
                    {this.props.user.freeze ? 'unfreeze' : 'freeze'}
                </Button>{' '}
                <Button color="secondary" onClick={this.toggle}>
                    Cancel
                </Button>
                </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default FreezeButton;