import React, { useState, useEffect } from 'react';
import { Button, Table, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form } from 'reactstrap';
import { FaSortDown } from 'react-icons/fa'; // Import sort down icon from react-icons
import sdk from 'api-sdk'; // Ensure correct import path
import userService from '../../services/user'; // Ensure correct import path
import RunHistoryModal from './runHistoryForCompaniesTable'; // Ensure correct import path
import Papa from 'papaparse'; // Import PapaParse to handle CSV files

const statusColorMap = {
    'Pass': 'green',
    'Fail': 'red',
    'No defense': 'orange',
    'Detected': 'blue',
    'No POC': 'purple',
    'No Working': 'brown',
    'Declined' : 'darksalmon'
};

const statusOrder = {
    'Pass': 1,
    'Fail': 2,
    'No defense': 3,
    'Detected': 4,
    'No POC': 5,
    'No Working': 6,
    'Declined' : 7,
};

const CompaniesTable = () => {
    const [companies, setCompanies] = useState({});
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [modal, setModal] = useState(false);
    const [runModal, setRunModal] = useState(false);
    const [csvModal, setCsvModal] = useState(false); // New state for CSV modal
    const [newCompanyName, setNewCompanyName] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [runHistory] = useState(null);
    const [bullets, setBullets] = useState([]); // State for bullets

    const userRoles = userService.getRoles();
    const isRootOrUploader = userRoles.includes('root') || userRoles.includes('uploader');
    const isRoot = userRoles.includes('root');

    const getStatusColor = (status) => statusColorMap[status] || 'black';

    useEffect(() => {
        fetchCompaniesAndHistories();
        fetchBullets(); // Fetch bullets on component mount
    }, []);

    const fetchCompaniesAndHistories = async () => {
        try {
            const companiesData = await sdk.companies.getAll();
            const historiesData = await sdk.runHistory.getAll();
            // Create a set of company names from the companiesData
            const companyNamesSet = new Set(companiesData.map(company => company.companyName));

            // Filter run histories to only include those with company names present in companiesData
            const filteredHistoriesData = historiesData.filter(history => companyNamesSet.has(history.companyName));

            // Group filtered run histories by company name
            const groupedByCompany = filteredHistoriesData.reduce((acc, curr) => {
                (acc[curr.companyName] = acc[curr.companyName] || []).push(curr);
                return acc;
            }, {});

            // Ensure all companies from companiesData are included in groupedByCompany
            companiesData.forEach(company => {
                groupedByCompany[company.companyName] = {
                    id: company.companyID,  // Include company ID
                    histories: groupedByCompany[company.companyName] || [],
                };
            });

            setCompanies(groupedByCompany);
        } catch (error) {
            console.error("Error fetching companies and run histories:", error);
        }
    };

    const fetchBullets = async () => {
        try {
            const bulletsData = await sdk.silverBullets.get();
            setBullets(bulletsData);
        } catch (error) {
            console.error("Error fetching bullets:", error);
        }
    };

    const toggleCompany = (companyName) => {
        setSelectedCompany(selectedCompany === companyName ? null : companyName);
    };

    const toggleModal = () => {
        setModal(!modal);
    };

    const toggleRunModal = () => {
        setRunModal(!runModal);
    };

    const toggleCsvModal = () => {
        setCsvModal(!csvModal);
    };

    const handleNewCompanyChange = (e) => {
        setNewCompanyName(e.target.value);
    };

    const handleCreateCompany = async () => {
        try {
            await sdk.companies.create({ companyName: newCompanyName });
            setNewCompanyName('');
            toggleModal();
            await fetchCompaniesAndHistories();  // Refresh list to include the new company
        } catch (error) {
            console.error("Error creating company:", error);
        }
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedHistories = (histories) => {
        if (!Array.isArray(histories)) return [];

        if (!sortConfig.key) return histories;

        const sorted = [...histories].sort((a, b) => {
            if (sortConfig.key === 'status') {
                const compareA = statusOrder[a.status] || 7; // Default order if not found
                const compareB = statusOrder[b.status] || 7;
                return sortConfig.direction === 'asc' ? compareA - compareB : compareB - compareA;
            }

            if (sortConfig.key === 'runDate') {
                return sortConfig.direction === 'asc'
                    ? new Date(a.runDate) - new Date(b.runDate)
                    : new Date(b.runDate) - new Date(a.runDate);
            }

            if (sortConfig.key === 'runNumber') {
                return sortConfig.direction === 'asc'
                    ? parseInt(a.runNumber, 10) - parseInt(b.runNumber, 10)
                    : parseInt(b.runNumber, 10) - parseInt(a.runNumber, 10);
            }

            const compareA = a[sortConfig.key].toString().toLowerCase();
            const compareB = b[sortConfig.key].toString().toLowerCase();
            if (compareA < compareB) return sortConfig.direction === 'asc' ? -1 : 1;
            if (compareA > compareB) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });

        return sorted;
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-GB'); // 'en-GB' locale for dd/MM/yyyy format
    };

    const handleDeleteRunHistory = async (historyId) => {
        if (window.confirm("Are you sure you want to delete this run history?")) {
            try {
                await sdk.runHistory.delete(historyId);
                fetchCompaniesAndHistories();
            } catch (error) {
                console.error("Error deleting run history:", error);
            }
        }
    };

    const handleSaveRunHistory = async (runHistoryData, runHistoryId) => {
        try {
            // Fetch existing run histories
            const existingHistories = await sdk.runHistory.getAll();
    
            // Normalize function to compare strings case-insensitively
            const normalizeString = (str) => str.trim().toLowerCase();
    
            // Check if the run history with the same company name and name exists
            const existingEntry = existingHistories.find(
                (history) =>
                    normalizeString(history.companyName) === normalizeString(runHistoryData.companyName) &&
                    normalizeString(history.bulletName) === normalizeString(runHistoryData.bulletName)
            );
    
            if (existingEntry) {
                // Update the existing run history
                await sdk.runHistory.update(existingEntry.id, runHistoryData);
            } else {
                // Create a new run history
                await sdk.runHistory.create(runHistoryData);
            }
    
            toggleRunModal();
            await fetchCompaniesAndHistories();
        } catch (error) {
            console.error("Error saving run history:", error);
        }
    };

    function normalizeString(str) {
        return str.trim().replace(/\s+/g, ' ').toLowerCase();
    }

    const handleCsvUpload = async (event) => {
        const file = event.target.files[0];
        Papa.parse(file, {
            header: true,
            complete: async (results) => {
                const jsonData = results.data;
    
                // Fetch existing run histories
                const existingHistories = await sdk.runHistory.getAll();
    
                jsonData.forEach(async (run) => {
                    const bullet = bullets.find(b => normalizeString(b.name) === normalizeString(run.bulletName));
                    const runHistoryData = {
                        companyName: run.companyName,
                        categoryName: run.categoryName,
                        runDate: run.runDate,
                        notes: run.notes,
                        status: run.status,
                        runNumber: run.runNumber,
                        defenceRules: run.defenceRules,
                        bulletName: run.bulletName,
                        bulletId: bullet ? bullet.id : null,
                    };
    
                    // Check if the run history already exists
                    const existingEntry = existingHistories.find(
                        history => 
                            history.companyName === runHistoryData.companyName && 
                            normalizeString(history.bulletName) === normalizeString(runHistoryData.bulletName)
                    );
                    console.log(existingEntry);
    
                    if (existingEntry) {
                        // Update the existing run history
                        await sdk.runHistory.update(existingEntry.id, runHistoryData);
                    } else {
                        // Create a new run history
                        await sdk.runHistory.create(runHistoryData);
                    }
                });
    
                toggleCsvModal();
                fetchCompaniesAndHistories();
            }
        });
    };
    
    

    const handleDeleteCompany = async (companyId, companyName) => {
        if (window.confirm(`Are you sure you want to delete the company ${companyName}?`)) {
            try {
                await sdk.companies.delete(companyId); // Ensure correct delete method and parameter
                fetchCompaniesAndHistories();  // Refresh list to reflect the deleted company
            } catch (error) {
                console.error("Error deleting company:", error);
            }
        }
    };

    return (
        <div className="container-fluid larger-table-container">
            <Button color="primary" onClick={toggleModal} style={{ marginBottom: '1rem' }}>Add New Company</Button>
            <Button color="secondary" onClick={toggleRunModal} style={{ marginBottom: '1rem', marginLeft: '1rem' }}>Add Run</Button>
            <Button onClick={toggleCsvModal} style={{ marginBottom: '1rem', marginLeft: '1rem', backgroundColor: '#217346' }}>Add Run From CSV</Button>
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Create New Company</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="companyName">Company Name</Label>
                        <Input type="text" id="companyName" value={newCompanyName} onChange={handleNewCompanyChange} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleCreateCompany}>Create Company</Button>{' '}
                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <RunHistoryModal
                isOpen={runModal}
                toggle={toggleRunModal}
                onSaveRunHistory={handleSaveRunHistory}
                runHistory={runHistory}
            />
            <Modal isOpen={csvModal} toggle={toggleCsvModal}>
                <ModalHeader toggle={toggleCsvModal}>Upload CSV File</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="csvFile">CSV File</Label>
                            <Input type="file" id="csvFile" accept=".csv" onChange={handleCsvUpload} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleCsvModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Table>
                <thead>
                    <tr>
                        <th style={{ fontSize: '24px' }}>Company Name</th>
                        <th className="text-right"></th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(companies).map((companyName) => (
                        <React.Fragment key={companyName}>
                            <tr>
                                <td style={{ fontSize: '20px', color: '#FFFFFF' }}>{companyName}</td>
                                <td className="text-right">
                                    <Button size="sm" onClick={() => toggleCompany(companyName)}>
                                        {selectedCompany === companyName ? 'Hide' : 'View'} Run Histories
                                    </Button>
                                    {isRoot && <Button size="sm" style={{ marginLeft: '10px', backgroundColor: 'red' }} onClick={() => handleDeleteCompany(companies[companyName].id, companyName)}>
                                        Delete Company
                                    </Button>}
                                </td>
                            </tr>
                            <Collapse isOpen={selectedCompany === companyName}>
                                <div className="container-fluid">
                                    <Table size="sm" style={{ borderRadius: '5px' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ fontSize: '18px', color: "#272C33" }}>
                                                    Bullet
                                                    <FaSortDown onClick={() => handleSort('bulletName')} style={{ cursor: 'pointer' }} />
                                                </th>
                                                <th style={{ fontSize: '18px', color: "#272C33" }}>
                                                    Category
                                                    <FaSortDown onClick={() => handleSort('categoryName')} style={{ cursor: 'pointer' }} />
                                                </th>
                                                <th style={{ fontSize: '18px', color: "#272C33" }}>
                                                    Status
                                                    <FaSortDown onClick={() => handleSort('status')} style={{ cursor: 'pointer' }} />
                                                </th>
                                                <th style={{ fontSize: '18px', color: "#272C33" }}>
                                                    Run Date
                                                    <FaSortDown onClick={() => handleSort('runDate')} style={{ cursor: 'pointer' }} />
                                                </th>
                                                <th style={{ fontSize: '18px', color: "#272C33" }}>
                                                    Run Number
                                                    <FaSortDown onClick={() => handleSort('runNumber')} style={{ cursor: 'pointer' }} />
                                                </th>
                                                <th style={{ fontSize: '18px', color: "#272C33" }}>Defense Rules</th>
                                                <th style={{ fontSize: '18px', color: "#272C33" }}>Notes</th>
                                                {isRootOrUploader && <th style={{ fontSize: '18px', color: "#272C33" }}>Actions</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedHistories(companies[companyName].histories).map(history => (
                                                <tr key={history.id}>
                                                    <td style={{ fontSize: '16px', color: "#000000" }}>{history.bulletName}</td>
                                                    <td style={{ fontSize: '16px', color: "#000000" }}>{history.categoryName}</td>
                                                    <td style={{ fontSize: '16px', color: getStatusColor(history.status) }}>
                                                        {history.status}
                                                    </td>
                                                    <td style={{ fontSize: '16px', color: "#000000" }}>{formatDate(history.runDate)}</td>
                                                    <td style={{ fontSize: '16px', color: "#000000" }}>{history.runNumber}</td>
                                                    <td style={{ fontSize: '16px', color: "#000000" }}>{history.defenceRules}</td>
                                                    <td style={{ fontSize: '16px', color: "#000000" }}>{history.notes}</td>
                                                    {isRootOrUploader && (
                                                        <td>
                                                            <Button size="sm" color="danger" onClick={() => handleDeleteRunHistory(history.id)}>Delete</Button>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default CompaniesTable;
