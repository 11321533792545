import React from 'react';
import {
	Row,
    Col,
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup
} from 'reactstrap';
import user from '../../../services/user';

import img3 from '../../../assets/images/eye-of-the-enemy-logo-text.png';


class LoginVerification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            verifying: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        if(!this.state.code.match(/[0-9]{6}/)) {
            this.setState({ error: 'Invalid code' });
            return;
        }
        this.setState({ verifying: true });
        this.props.verifyFunc(this.state.code).then(res => {
            let error = null;
            if (res) {
                this.props.updateFunc();
            } else {
                error = 'Wrong verification code';
                this.setState({
                    verifying: false,
                    error
                });
            }
        }).catch(e => {
            this.setState({
                verifying: false,
                error: "Couldn't verify the code (error)"
            });
        });
    }

	render() {
		return <div className="container">
            <Row className="no-gutters justify-content-center">
                <Col md="8" lg="6" className="text-white p-2 auth-box login-box" data-theme="dark">
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col>
                                <div className='logo'>
                                    <img src={img3} alt="Home" />
                                </div>
                            </Col>
                        </Row>
                        {this.props.children}
                        <Row className='mt-3'>
                            <Col>
                                <h3 className='text-center'>
                                    Enter the verification code generated by your authenticator app or 
                                    <Button
                                        size='lg' 
                                        className='btn-link'
                                        onClick={() => user.logout().then(() => { window.location.href = '../'; }) }
                                    >
                                        Log-out
                                    </Button>
                                </h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <FormGroup>
                                    <InputGroup>
                                        <Input
                                            bsSize='lg'
                                            placeholder='Verification Code'
                                            className='text-center'
                                            maxLength='6'
                                            value={this.state.token}
                                            onChange={e => {this.setState({code: e.target.value, error: null})}}
                                            invalid={!!this.state.error}
                                            disabled={this.state.verifying}
                                            autoFocus
                                            required
                                        />
                                        <FormFeedback>{this.state.error}</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ size: 4, offset: 4 }}>
                                <FormGroup>
                                    <Button
                                        block
                                        disabled={this.state.verifying}
                                    >
                                        {!this.state.verifying?
                                            'Verify'
                                        :
                                            <i className='fas fa-spin fa-spinner'/>
                                        }
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>                    
                </Col>
            </Row>
        </div>
	}
}

export default LoginVerification;