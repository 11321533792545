import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import DeleteButton from '../../components/buttons/deleteButton';
import sdk from 'api-sdk';
import SearchBar from './searchBar';
import './SearchBar.css';
import { toast } from 'react-toastify';

class BulletTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bullets: null,
      search: null,
    };

    this.getBullets = this.getBullets.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.searchFunction = this.searchFunction.bind(this);
    this.setSearch = this.setSearch.bind(this);
  }

  getBullets() {
    sdk.silverBullets.get().then(bullets => {
      this.setState({ bullets });
    });
  }

  handleDelete(id, name) {
    sdk.silverBullets.delete(id).then(() => {
      this.getBullets(); // Refresh the list of bullets
      toast.success(`Bullet "${name}" deleted successfully`); // Show success toast
    }).catch(error => {
      console.error("Error deleting bullet:", error);
      toast.error("Error deleting bullet"); // Show error toast in case of failure
    });
  }

  componentDidMount() {
    this.getBullets();
  }

  searchFunction(bullet) {
    if (!this.state.search) return true;
    if(this.state.search){
      if(bullet.name.toLowerCase().includes(this.state.search.toLowerCase())){
        return true;
      }
    }
  }

  setSearch(searchTerm) {
    this.setState({ search : searchTerm });
    return searchTerm;
  }
  

  render() {
    const currentBullets = this.state.bullets ? this.state.bullets.filter(bullet => this.searchFunction(bullet)) : null;

    return (
      <Card>
        <div className="search-bar-container">
          <SearchBar searchFunction={this.setSearch} />
        </div>
        <CardTitle className="bg-light border-bottom p-3 mb-0">
          Silver Bullet Management
          <span className="float-right">
            <Button className='btn-outline-secondary mr-4' size='sm' onClick={() => sdk.silverBullets.downloadReport('pdf')}>
              Download report
            </Button>
            <Link to={`silver-bullet-management/create`} onClick={e => e.stopPropagation()}>
              <Button className='btn-outline-secondary' size='sm'>
                Create Silver Bullet
              </Button>
            </Link>
          </span>
        </CardTitle>
        <CardBody className='p-0'>
          {this.state.bullets?
            <Table hover>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Attack Vector</th>
                  <th>Created</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.bullets.length === 0?
                  <tr key='no-bullet'>
                    <td colpan='5'>
                      <h3>
                        No Silver Bullets
                      </h3>
                    </td>
                  </tr>
              : null}
              {
                currentBullets.map((bullet, i) => (
                  <tr key={bullet.id}>
                    <td>{bullet.id}</td>
                    <td>{bullet.name}</td>
                    <td>{bullet.attackVector}</td>
                    <td>{(new Date(Date.parse(bullet.createdAt))).toLocaleString('en-il')}</td>
                    <td>
                        <Link to={`silver-bullet-management/edit/${bullet.id}`} onClick={e => e.stopPropagation()}>
                          <Button className='btn-outline-secondary'>
                            <i className='mdi mdi-pencil'/>
                          </Button>
                        </Link>
                    </td>
                    <td><DeleteButton item='silver bullet' deleteFunc={() => {this.handleDelete(bullet.id, bullet.name)}}/></td>
                  </tr>
                )
              )}
              </tbody>
            </Table>
          : 
            <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
          }
        </CardBody>
      </Card>
    );
  }
}

export default BulletTable;