import React from "react";
import { Link } from 'react-router-dom';
import sdk from 'api-sdk';
import { Button } from 'reactstrap';
import attackVectors from "../../assets/data/attackVectors.json";

import './techniqueTable.css'

class TechniqueTable extends React.Component {
    constructor(props) {
        super(props);

        const isOpenMap = {};
        attackVectors.forEach(v => {
            isOpenMap[v.title] = true;
        });

        this.state = {
            techniqueMap: null,
            isOpenMap,
            popup: {
                show: false,
                content: ''
            }
        };

        this.getTechniques = this.getTechniques.bind(this);
        this.toggleColumn = this.toggleColumn.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.hidePopup = this.hidePopup.bind(this);
    
    }

    showPopup(e, content) {
        const x = e.pageX-270;;
        const y = e.pageY;
    
        this.setState({
            popup: {
                show: true,
                content: content,
                x: x,
                y: y
            }
        });
    }
    
    
    hidePopup() {
        this.setState({
            popup: {
                show: false,
                content: ''
            }
        });
    }
    

    getTechniques() {
        Promise.all([
            sdk.techniques.get(),
            sdk.silverBullets.get({include: 'techniques'})
        ]).then(data => {
            const technqiues = data[0].filter(t => !t.isSubTechnique);
            const bullets = data[1];
            const techniqueMap = {};

            attackVectors.forEach(v => {
                techniqueMap[v.title] = {...v, techniques: {}};
            });

            technqiues.forEach(tech => {
                // const bulletCounter = bullets.filter(b => b.techniqueId === t.id).length;
                const bulletCounter = bullets.filter(b => b.techniques.find(t => t.id === tech.id || t.subTechniqueOfId === tech.id)).length;
                techniqueMap[tech.attackVector].techniques[tech.id] ={...tech, bulletCounter};
            });

            this.setState({techniqueMap})
        });
    }

    toggleColumn(attackVector) {
        this.setState(state => {
            const newMap = { ...state.isOpenMap };
            newMap[attackVector] = !state.isOpenMap[attackVector];

            console.log(newMap);
            return { isOpenMap: newMap };
        });
    }

    componentDidMount() {
        this.getTechniques();
    }

    render() {
        if (!this.state.techniqueMap) {
            return <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>;
        }

        return (
            <div className='technqiue-table'>
                {attackVectors.filter(v => !v.hide).map(v => {
                    const techniques = this.state.techniqueMap[v.title].techniques;
                    return <div key={`${v.propName}-column`} className='vector-col'>
                        <Link className='vector-title' title={v.mitreId} to={`/silver-bullets/${v.path}`}>{v.title}</Link>
                        <div className='vector-subtitle'>{Object.keys(techniques).length} techniques</div>
                        <Button
                            className='btn-sm btn-rounded btn-outline-secondary mb-3'
                            title={this.state.isOpenMap[v.title]? 'Hide column' : 'Show column'}
                            onClick={() => this.toggleColumn(v.title)}
                        >
                            {this.state.isOpenMap[v.title]? <i className="fas fa-minus"/> : <i className="fas fa-plus"/>}
                        </Button>
                        {this.state.isOpenMap[v.title]?
                            <table id="spacing" cellSpacing="0">
                                <tbody>
                                    {Object.values(techniques).map(t => (
                                        <tr key={t.id}>
                                            <td title={t.name +"-"+ v.mitreId} className={t.bulletCounter > 0 ? "glow" : ""} >
                                                <Link className='technique-box' to={`/silver-bullets/${v.path}/technique/${t.id}`} id={t.bulletCounter > 0 ? "" : "unclickable"}>
                                                    {t.name} { t.bulletCounter > 0 ? "("+t.bulletCounter+")" : ""}
                                                </Link>
                                            </td>
                                        </tr> 
                                    ))}
                                </tbody>
                            </table>
                        : null}
                    </div>
                })}
            </div>
        );
    }
}

export default TechniqueTable;