import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import sdk from 'api-sdk';
import { toast } from 'react-toastify';

class UserPayloadDownload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            downloads: [],
            filterEmail: '',
            uniqueEmails: []
        };
    }

    componentDidMount() {
        this.fetchPayloadDownloads();
    }

    fetchPayloadDownloads() {
        sdk.payloadDownloads.getDownloads()
            .then(downloads => {
                const uniqueEmails = [...new Set(downloads.map(download => download.userEmail))];
                this.setState({ downloads, uniqueEmails });
            })
            .catch(error => {
                console.error("Error fetching payload downloads:", error);
                toast.error("Failed to fetch payload downloads.");
            });
    }

    handleEmailFilterChange = (event) => {
        this.setState({ filterEmail: event.target.value });
    };

    render() {
        const { downloads, filterEmail, uniqueEmails } = this.state;
        const filteredDownloads = downloads.filter(download => download.userEmail.includes(filterEmail));

        return (
            <div>
                <Card>
                    <CardTitle className="bg-light border-bottom p-3 mb-0">
                        Payload Download Management
                        <FormGroup className="float-right">
                            <Label for="emailFilter">Filter by Email</Label>
                            <Input type="select" id="emailFilter" value={filterEmail} onChange={this.handleEmailFilterChange}>
                                <option value="">All</option>
                                {uniqueEmails.map(email => (
                                    <option key={email} value={email}>{email}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </CardTitle>
                    <CardBody className='p-0'>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>User Email</th>
                                    <th>Payload Name</th>
                                    <th>Payload File Name</th>
                                    <th>Downloaded At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredDownloads.map((download, index) => (
                                    <tr key={index}>
                                        <td>{download.userEmail}</td>
                                        <td>{download.payloadName}</td>
                                        <td>{download.payloadFileName}</td>
                                        <td>{new Date(download.downloadedAt).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default UserPayloadDownload;
