import React from 'react';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    Badge,
    Button,
    CardTitle,
    Jumbotron,
    Row,
    Col,
    Collapse,
    CardBody,
    CardHeader
} from 'reactstrap';
import PayloadModal from './payloadModal';
import DefRuleModal from './defRuleModal';
import classnames from 'classnames';
import userService from '../../../services/user';
import HtmlViewer from '../../../components/htmlViewer';
import sdk from 'api-sdk';
import vectors from '../../../assets/data/attackVectors.json';
import RunHistoryList from './runHistory/runHistoryList';
import Switch from '@mui/material/Switch';
import { withRouter } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

const falseIcon = <i className='fas fa-times' />;
const lockIcon = <i className='fas fa-lock' />;

const vectorNameMap = {};
vectors.forEach(vector => {
    vectorNameMap[vector.title] = vector.propName;
});

// const customNames = {
//     silverBulletId: "Silver Bullet ID",
//     crowdStrikeFalcon: "CrowdStrike Falcon",
//     symantec: "Symantec",
//     carbonBlack: "Carbon Black",
//     tanium: "Tanium",
//     bitdefenderGravityZone: "Bitdefender GravityZone",
//     microsoftDefender: "Microsoft Defender",
//     sentinelOne: "SentinelOne",
//     fireEye: "FireEye",
//     cynet: "Cynet",
//     mcAfee: "McAfee",
//     sysmon: "Sysmon",
//     paloAltoCortexXDR: "Palo Alto Cortex XDR",
//     azureSentinel: "Azure Sentinel",
//     groupPolicyObjects: "GPO",
//     awsAthena: "AWS Athena",
//     id: "ID"
// };

class Bullet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
            payloadModalOpen: false,
            defRuleModalOpen: false,
            techniquesCollapseOpen: false,
            edrsCollapseOpen: false,
            feedbackCollapseOpen: false,
        };

        this.toggleTab = this.toggleTab.bind(this);
        this.getTechniques = this.getTechniques.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    getTechniques() {
        if (!this.props.bullet) return;

        sdk.silverBullets.getTechniques(this.props.bullet.id).then(techniques => {
            this.setState({ techniques });
        });
    }

    componentDidMount() {
        this.getTechniques();
    }

    componentDidUpdate(prevProps) {
        if (this.props.bullet.id !== prevProps.bullet.id) {
            this.setState({ activeTab: '1', techniques: null });
            this.getTechniques();
        }
    }

    navigateToEdit = () => {
        const { bullet } = this.props;
        this.props.history.push(`/silver-bullet-management/edit/${bullet.id}`);
    };


    render() {
        const userRoles = userService.getRoles();
        const isRootOrUploader = userRoles.includes('root') || userRoles.includes('uploader');
        let blockDownload = '';
        let isUnlicensed = false;
        if (!userService.isRoot()) {
            if (this.props.license.licenseType === 'attack vectors') {
                if (!this.props.license[vectorNameMap[this.props.bullet.attackVector]]) {
                    blockDownload = 'Unlicensed attack vector';
                    isUnlicensed = true;
                }
            } else {
                const isDownloaded = this.props.action && this.props.action.payloadStatus === 'selected' && this.props.action.defRuleStatus === 'selected';
                if (this.props.downloadedCount >= this.props.license.quota && isDownloaded) {
                    blockDownload = 'Exceeded silver bullet monthly quota';
                }
            }
        }

        const isSelected = this.props.action && this.props.action.payloadStatus !== 'discarded' && this.props.action.defRuleStatus !== 'discarded';

        let payloadComponent;
        if (this.props.action) {
            const payloadCount = this.props.bullet.payloads.length;
            switch (this.props.action.payloadStatus) {
                case 'selected':
                    payloadComponent = (
                        <Button
                            size='lg'
                            disabled={!!blockDownload || payloadCount === 0}
                            onClick={() => this.setState({ payloadModalOpen: true })}
                        >
                            {payloadCount > 0 ? 'Download Payload' : 'No Payloads Available'}
                        </Button>
                    );
                    break;
                case 'downloaded':
                    payloadComponent = (
                        <div className='status-container'>
                            <Button className='m-2' onClick={() => { this.props.registerFunc(this.props.bullet.id, 'payload-executed') }}>
                                Set status to executed
                            </Button>
                            <Button
                                className='m-2'
                                disabled={!!blockDownload || payloadCount === 0}
                                onClick={() => this.setState({ payloadModalOpen: true })}

                            >
                                {payloadCount > 0 ? 'Download Payload' : 'No Payloads Available'}
                            </Button>
                        </div>
                    );
                    break;
                case 'executed':
                    payloadComponent = (
                        <div className='status-container'>
                            <Button className='m-2' size='sm' color='danger' onClick={() => { this.props.registerFunc(this.props.bullet.id, 'payload-passed') }}>
                                Set status to passed
                            </Button>
                            <Button className='m-2' size='sm' color='info' onClick={() => { this.props.registerFunc(this.props.bullet.id, 'payload-blocked') }}>
                                Set status to blocked
                            </Button>
                            <Button className='m-2' size='sm' onClick={() => { this.props.registerFunc(this.props.bullet.id, 'payload-unmark-executed') }}>
                                Unmark as executed
                            </Button>
                        </div>
                    );
                    break;
                case 'passed':
                    payloadComponent = (
                        <Button size='lg' onClick={() => { this.props.registerFunc(this.props.bullet.id, 'payload-unmark-passed') }}>
                            Unmark as passed
                        </Button>
                    );
                    break;
                case 'blocked':
                    payloadComponent = (
                        <Button size='lg' onClick={() => { this.props.registerFunc(this.props.bullet.id, 'payload-unmark-blocked') }}>
                            Unmark as blocked
                        </Button>
                    );
                    break;
                default:
                    break;
            }
        }

        return <div className='mt-4'>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggleTab('1'); }}
                    >
                        Summary
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggleTab('2'); }}
                        disabled={!isSelected}
                    >
                        {!isSelected ? <>{lockIcon} </> : null}
                        Description
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggleTab('3'); }}
                        disabled={!isSelected}
                    >
                        {!isSelected ? <>{lockIcon} </> : null}
                        Intelligence
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => { this.toggleTab('4'); }}
                        disabled={!isSelected}
                    >
                        {!isSelected ? <>{lockIcon} </> : null}
                        Instructions
                    </NavLink>
                </NavItem>
                {isRootOrUploader ? <NavItem>
                    <NavLink
                        className={classnames({ active: this.state.activeTab === '5' })}
                        onClick={() => { this.toggleTab('5'); }}
                        disabled={!isSelected}
                    >
                        {!isSelected ? <>{lockIcon} </> : null}
                        Payload Run History
                    </NavLink>
                </NavItem> : null}
                <NavItem>
                    <NavLink
                        onClick={this.props.closeFunc}
                    >
                        {falseIcon}
                    </NavLink>
                </NavItem>
            </Nav>
            <Card body id='bullet-container' className='pt-4 pb-4'>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId='1'>
                        <PayloadModal bullet={this.props.bullet} isOpen={this.state.payloadModalOpen} toggle={() => this.setState(state => ({ payloadModalOpen: !state.payloadModalOpen }))} updateFunc={this.props.updateFunc} />
                        <DefRuleModal bullet={this.props.bullet} isOpen={this.state.defRuleModalOpen} toggle={() => this.setState(state => ({ defRuleModalOpen: !state.defRuleModalOpen }))} updateFunc={this.props.updateFunc} />
                        <Row>
                            <Col md='10'> {/* Adjust the size as needed for the title */}
                                <CardTitle tag='h1'>
                                    {this.props.bullet.name}
                                </CardTitle>
                            </Col>
                            {isRootOrUploader ? <Col md='2' className='text-right'>
                                <EditIcon onClick={this.navigateToEdit} style={{cursor: 'pointer'}}></EditIcon>
                            </Col> : null}
                        </Row>
                        <h2 className='font-light mb-3'>Attack vector: {this.props.bullet.attackVector}</h2>

                        <h4 className='font-light mb-2'>Creator: {this.props.bullet.creator ? this.props.bullet.creator : "Red Team"}</h4>

                        <h4>
                            {this.props.bullet.needForInternet ?
                                <Badge className='pl-2 pr-2 ml-1 mr-1 badge-tag' color="secondary" pill>
                                    Requires internet connection
                                </Badge>
                                : null}
                            {this.props.bullet.requireLocalAdmin ?
                                <Badge className='pl-2 pr-2 ml-1 mr-1 badge-tag' color="secondary" pill>
                                    Requires local admin
                                </Badge>
                                : null}
                        </h4>
                        <br />
                        <Jumbotron className='pt-4 pb-4'>
                            <h4>
                                {this.props.bullet.description}
                            </h4>
                        </Jumbotron>
                        <Card className='inner-card collapse-card'>
                            <CardHeader onClick={() => this.setState(state => ({ techniquesCollapseOpen: !state.techniquesCollapseOpen }))}>
                                Techniques{" "}
                                <span className={this.state.techniquesCollapseOpen ? 'rotated-icon active' : 'rotated-icon'}><i className={'fas fa-caret-right'} /></span>
                            </CardHeader>
                            <Collapse isOpen={this.state.techniquesCollapseOpen}>
                                <CardBody>
                                    <i className='fas fa-spin spinner' />
                                    {!this.state.techniques ? <h2 className='text-center'><i className='fas fa-spin fa-spinner' /></h2> : null}
                                    {this.state.techniques && this.state.techniques.length > 0 ? <ul>
                                        {this.state.techniques.map(tech => <li className='mb-1' key={tech.name}>
                                            <a target="_blank" rel="noreferrer noopener" href={`https://attack.mitre.org/techniques/${tech.mitreId.replace(/\./g, "/")}`}>{tech.mitreId}</a> : {tech.name}
                                        </li>)}
                                    </ul> :
                                        <h4>No related techniques</h4>}
                                </CardBody>
                            </Collapse>
                        </Card>
                        {/* <Card className='inner-card collapse-card'>
                            <CardHeader onClick={() => this.setState(state => ({ edrsCollapseOpen: !state.edrsCollapseOpen }))}>
                                Relevant Defense Systems{" "}
                                <span className={this.state.edrsCollapseOpen ? 'rotated-icon active' : 'rotated-icon'}><i className={'fas fa-caret-right'} /></span>
                            </CardHeader>
                            <Collapse isOpen={this.state.edrsCollapseOpen}>
                                <CardBody>
                                    <i className='fas fa-spin spinner' />
                                    {this.props.bullet.edrs[0] ?
                                        <ul>
                                            {Object.keys(this.props.bullet.edrs[0]).map((key) => {
                                                if (this.props.bullet.edrs[0][key] === true) {
                                                    return <li key={key}>{customNames[key]}</li>;
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </ul> :
                                        <h4>No related relevant defense systems</h4>}
                                </CardBody>
                            </Collapse>
                        </Card> */}

                        {isRootOrUploader ? <Row className="align-items-center">
                            <Col xs="auto"> {/* Use 'auto' to size the column based on its content */}
                                <h4 className='font-light mb-0'>Irrelevant</h4> {/* Use 'mb-0' to remove the bottom margin from the <h4> */}
                            </Col>
                            <Col xs="auto">
                                <Switch
                                    checked={this.props.bullet.irrelevant}
                                    onChange={(e) => this.props.onToggleRelevance(this.props.bullet.id, e.target.checked)}
                                />
                            </Col>
                        </Row> : null}

                        <div className='tags-row' style={{ marginTop: '16px' }}>
                            {this.props.bullet.aptTags && this.props.bullet.aptTags.length > 0 ?
                                <h4>Tags: {this.props.bullet.aptTags.map(tag => (
                                    <Badge key={`${tag.aptName} tag`} className='pl-2 pr-2 ml-1 mr-1' color="danger">
                                        {tag.aptName}
                                    </Badge>
                                ))}</h4>
                                : null}
                        </div>

                        {blockDownload ?
                            <h2 className='text-danger'>{blockDownload}, cannot download</h2>
                            : <br />}
                        <div className='status-row'>
                            {isSelected ?
                                <>
                                    <Jumbotron className='pt-4 pb-4 status-container'>
                                        <h4>Payload Status: {this.props.action.payloadStatus}</h4>
                                        <div className='status-container'>
                                            {payloadComponent}
                                        </div>
                                    </Jumbotron>
                                    <Jumbotron className='pt-4 pb-4 status-container'>
                                        <h4 className='status-title mb-2'>Defence Rule Status: {this.props.action.defRuleStatus}</h4>
                                        <div className='status-container'>
                                            {this.props.action.defRuleStatus === 'selected' ?
                                                <Button
                                                    size='lg'
                                                    disabled={!!blockDownload}
                                                    onClick={() => this.setState({ defRuleModalOpen: true })}
                                                >
                                                    Download Defence Rule
                                                </Button>
                                                :
                                                this.props.action.defRuleStatus === 'downloaded' ?
                                                    <div className='status-container'>
                                                        <Button className='m-2' onClick={() => { this.props.registerFunc(this.props.bullet.id, 'defRule-deployed') }}>
                                                            Set status to deployed
                                                        </Button>
                                                        <Button
                                                            className='m-2'
                                                            disabled={!!blockDownload}
                                                            onClick={() => this.setState({ defRuleModalOpen: true })}
                                                        >
                                                            Download Defence Rule
                                                        </Button>
                                                    </div>
                                                    :
                                                    <Button size='lg' onClick={() => { this.props.registerFunc(this.props.bullet.id, 'defRule-unmark-deployed') }}>
                                                        Unmark as deployed
                                                    </Button>
                                            }
                                        </div>
                                    </Jumbotron>
                                </>
                                :
                                <Col md={{ size: 6, offset: 3 }}>
                                    <Button
                                        block
                                        size='lg'
                                        disabled={this.props.exceededQuota || isUnlicensed}
                                        onClick={() => { this.props.registerFunc(this.props.bullet.id, 'select') }}
                                    >
                                        {this.props.exceededQuota || isUnlicensed ? <><i className='mdi mdi-lock' />{" "}</> : null}
                                        Select Silver Bullet
                                    </Button>
                                </Col>
                            }
                        </div>
                        {this.props.action && this.props.action.payloadStatus === 'selected' && this.props.action.defRuleStatus === 'selected' ?
                            <Row>
                                <Col md={{ size: 6, offset: 3 }}>
                                    <Button block size='lg' onClick={() => { this.props.registerFunc(this.props.bullet.id, 'discard') }}>Discard Silver Bullet</Button>
                                </Col>
                            </Row>
                            : null}
                    </TabPane>
                    {/* Decsription Tab */}
                    <TabPane tabId='2'>
                        {isSelected ?
                            <HtmlViewer silverBulletId={this.props.bullet.id} docName='description' />
                            : null}
                    </TabPane>
                    {/* Intelligence Tab */}
                    <TabPane tabId='3'>
                        {isSelected ?
                            <HtmlViewer silverBulletId={this.props.bullet.id} docName='intel' />
                            : null}
                    </TabPane>
                    {/* Instructions Tab */}
                    <TabPane tabId='4'>
                        {isSelected ?
                            <HtmlViewer silverBulletId={this.props.bullet.id} docName='instructions' />
                            : null}
                    </TabPane>
                    {isRootOrUploader ? <TabPane tabId='5'>
                        {isSelected ?
                            <RunHistoryList bullet={this.props.bullet} />
                            : null}
                    </TabPane> : null}
                </TabContent>
            </Card>
        </div>;
    }
}

export default withRouter(Bullet);
