import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

class SetAdminButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: null,
        }

        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleClick(e) {
        e.stopPropagation();
        if(this.state.email) {
            this.props.setAdminFunc(this.state.email);
            this.toggle();
            this.setState({
                userId: null,
            });
        }
    }

    render() {
        return(
            <div>
                <Button color='secondary' size='sm' onClick={this.toggle}>
                    Set Admin
                </Button>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className='modal-dialog-centered'
                  data-theme='dark'
                >
                <ModalHeader toggle={this.toggle}>Set admin user</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <InputGroup>
                                <Input placeholder="Email" required onChange={(e) => this.setState({email : e.target.value})}/>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button color="info" onClick={this.handleClick} disabled={!this.state.email}>
                    Set as Admin
                </Button>{' '}
                <Button color="secondary" onClick={this.toggle}>
                    Cancel
                </Button>
                </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default SetAdminButton;