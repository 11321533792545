import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import sdk from 'api-sdk';

class SetPasswordButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            newPassword: '',
            passwordFB: '',
            setting: false
        }

        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleClick() {
        if(this.state.newPassword) {
            this.setState({
                setting: true
            });
            sdk.users.setPasswordById(this.props.user.id, this.state.newPassword)
            .then(() => this.setState(state => ({
                newPassword: '',
                setting: false,
                modal: !state.modal
            })))
            .catch(e => this.setState({
                passwordFB: e.response.data.error.message,
                setting: false
            }));
        }
        else {
            this.setState({passwordFB: 'Password is required'});
        }
    }

    render() {
        return(
            <div>
                <Button outline color='secondary' onClick={this.toggle}>
                    Set Password
                </Button>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className='modal-dialog-centered'
                  data-theme='dark'
                >
                <ModalHeader toggle={this.toggle}>Set Password</ModalHeader>
                <ModalBody>
                    Set a new password for {this.props.user.email}:
                    <Form>
                        <FormGroup>
                            <InputGroup>
                                <Input placeholder="New Password"
                                    required
                                    onChange={(e) => this.setState({newPassword : e.target.value})}
                                    invalid={!!this.state.passwordFB}/>
                                <FormFeedback>
                                    {this.state.passwordFB}
                                </FormFeedback>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button color="info"
                    onClick={this.handleClick}
                    disabled={!this.state.newPassword || this.state.setting}
                >
                    {!this.state.setting? 'Set' : <i className='fas fa-spin fa-spinner'/>}
                </Button>{' '}
                <Button color="danger" onClick={this.toggle} disabled={this.state.setting}>
                    Cancel
                </Button>
                </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default SetPasswordButton;