import React from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    Table,
} from 'reactstrap';
import sdk from 'api-sdk';

class BulletModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bullets : null
        }
    }

    componentDidMount() {
        sdk.silverBullets.get().then(bullets => this.setState({ bullets }));
    }

    render() {
        let bullets;
        if (this.state.bullets && this.props.actions && this.props.filterFunc) {
            bullets = this.props.actions.filter(this.props.filterFunc);
        }
        return(
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggleFunc}
                centered={true}
                data-theme='dark'
            >
                <ModalHeader toggle={this.props.toggleFunc}>{this.props.title}</ModalHeader>
                <ModalBody className='p-0'>
                    {bullets?
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Silver Bullet name</th>
                                    <th>Payload Status</th>
                                    <th>Defence Rule Status</th>
                                </tr>
                        </thead>
                        <tbody>
                            {bullets.length > 0 ?
                                bullets.map(action => {
                                    const bullet = this.state.bullets.find(b => b.id === action.silverBulletId);
                                    return <tr key={`payload`}>
                                        <td>{bullet ? bullet.name : "can't load name"}</td>
                                        <td>{action.payloadStatus}</td>
                                        <td>{action.defRuleStatus}</td>
                                    </tr>;
                                })
                            : <tr key='no-payloads'><td colSpan='3'>No relevant payloads</td></tr>}
                        </tbody>
                        </Table>
                    : <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>}
                </ModalBody>
            </Modal>
        );
    }
}

export default BulletModal;