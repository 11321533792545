import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    FormFeedback,
} from 'reactstrap';
import DeleteButton from '../../components/buttons/deleteButton';
import SubmitButton from '../../components/buttons/submitButton';

export default props => (
    <Card key={`deRule-upload-${props.index}`}>
        <CardTitle className='text-info bg-light border-bottom p-3 mb-0'>
            Defence rule file #{props.index + 1}
            {props.index && !props.defRule.id > 0 ?
                <span className="float-right">
                    <Button className='btn-outline-danger' size='sm' disabled={props.disabled} onClick={() => props.onRemove(props.index)}>
                        <i className='fas fa-times' />
                    </Button>
                </span>
                : null}
            {props.editor && props.defRule.id ?
                <span className="float-right">
                    <DeleteButton item='defence rule' deleteFunc={() => props.onDelete(props.defRule.id, props.index)} />
                </span>
                : null}
        </CardTitle>
        <CardBody>
            <Row>
                <Col lg='3'>
                    <FormGroup className='mb-0'>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>Name</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                value={props.defRule.name || ''}
                                disabled={props.disabled}
                                invalid={props.defRule.errors && props.defRule.errors.name}
                                onChange={e => props.onChange(props.index, 'name', e.target.value)}
                            />
                            <FormFeedback>
                                {props.defRule.errors && props.defRule.errors.name}
                            </FormFeedback>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col lg='3'>
                    <FormGroup className='mb-0'>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>EDR</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="select"
                                value={props.defRule.edrTag ? props.defRule.edrTag.edrName : ''}
                                disabled={props.disabled}
                                invalid={props.defRule.errors && props.defRule.errors.edrTag}
                                onChange={e => props.onChange(props.index, 'edrName', e.target.value)}
                            >
                                <option value="">Select EDR</option>
                                {props.edrs.map((edr) => (
                                    <option key={edr.propName} value={edr.propName}>{edr.name}</option>
                                ))}
                            </Input>
                            <FormFeedback>
                                {props.defRule.errors && props.defRule.errors.edrTag}
                            </FormFeedback>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col lg='6'>
                    <FormGroup className='mb-0'>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>File</InputGroupText>
                            </InputGroupAddon>
                            <div className="custom-file">
                                <Input
                                    type="file"
                                    className="custom-file-input"
                                    id={`defRule-file-${props.index}`}
                                    disabled={props.disabled}
                                    onChange={e => props.onChange(props.index, 'file', e.target.files[0])}
                                />
                                <label
                                    className="custom-file-label"
                                    htmlFor="fileInput"
                                >
                                    {props.defRule && props.defRule.file ? props.defRule.file.name : "Choose file"}
                                </label>
                            </div>
                        </InputGroup>
                        <h6 className='text-danger font-light'>
                            {props.defRule.errors && props.defRule.errors.file}
                        </h6>
                    </FormGroup>
                </Col>
            </Row>
            {props.editor ?
                <Row className='mt-4'>
                    <Col xl={{ size: 2, offset: 5 }} lg={{ size: 4, offset: 4 }} md='10'>
                        <FormGroup className='mb-0'>
                            <SubmitButton item='defence rule' submitFunc={() => props.onSubmit(props.index)} disabled={props.disabled} />
                        </FormGroup>
                    </Col>
                    {props.defRule.id ?
                        <Col xl={{ size: 1, offset: 4 }} lg={{ size: 2, offset: 2 }} md='2'>
                            <Button color="danger" size="lg" block disabled={props.disabled} onClick={() => props.onRevert(props.index)}>
                                <i className="fas fa-redo" />
                            </Button>
                        </Col>
                        : null}
                </Row>
                : null}
            <Row>
                <Col>{props.editor && props.defRule.errors && props.defRule.errors.upload}</Col>
            </Row>
        </CardBody>
    </Card>
);