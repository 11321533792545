import React from 'react';
import {
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    CustomInput,
    Row,
    Col,
    Button,
    Form,
    FormFeedback,
    FormGroup,
} from 'reactstrap';
import ReCAPTCHA from "react-google-recaptcha";

import img1 from '../../assets/images/white-hat-logo.png';
import img3 from '../../assets/images/eye-of-the-enemy-logo-text.png';

import user from '../../services/user';

class InitialLogin extends React.Component {
    constructor(props) {
        super(props);
        this.reCaptchaRef = React.createRef();
        this.state = {
            email: '',
            password: '',
            rememberLogin: true,
            redirect: false,
            loginFB: '',
        };

        this.handleLogin = this.handleLogin.bind(this);
    }

    handleLogin(e) {
        e.preventDefault();
        this.reCaptchaRef.current.reset();
        if(this.state.email && this.state.password) {
            this.setState({ loginFB: null });
            this.reCaptchaRef.current.executeAsync().then(token => {
                return user.login(this.state.email, this.state.password, token, this.state.rememberLogin);
            }).then(() => {
                this.props.updateFunc();
            }).catch(err => {
                this.reCaptchaRef.current.reset()
                if(err.response && err.response.status === 401) {
                    this.setState({ loginFB: 'Email or password is invalid' })
                }
                else if(err.response) {
                    this.setState({ loginFB: err.response.data.error.message });
                }
                else {
                    console.error(JSON.stringify(err));
                }
            });
        }
    }

    render() {
        return <div className="auth-box on-sidebar" data-theme="dark">
            <ReCAPTCHA ref={this.reCaptchaRef} size="invisible" sitekey="6Lchg6cZAAAAAC46u15YQukqTobXFfuDino3iqVY"/>
            <div id="loginform">
                <div className="logo">
                    <span className="db">
                        <img src={img1} alt="logo" />
                        <br/>
                        <img src={img3} alt="Home" />
                    </span>
                </div>
                <Row>
                <Col xs="12">
                <Form className="mt-3" name="loginform" id="loginform" onSubmit={this.handleLogin.bind(this)}>
                    <FormGroup>
                    <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ti-user"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="Email" required
                            onChange={e => this.setState( {email: e.target.value} )}
                            invalid={!!this.state.loginFB}
                            id = 'emailInput'
                        />
                        <FormFeedback>{this.state.loginFB}</FormFeedback>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ti-pencil"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Password" required 
                            onChange={e => this.setState( {password: e.target.value} )}
                            invalid={!!this.state.loginFB}
                            id = 'passwordInput'
                        />
                    </InputGroup>
                    </FormGroup>
                    <div className="d-flex no-block align-items-center mb-3 pl-1">
                    <CustomInput type="checkbox" id="rememberMeCheckbox" label="Remember me" checked={this.state.rememberLogin}
                        onChange={event => this.setState({ rememberLogin: event.target.checked })}/>
                    </div>
                    <Row className="mb-3">
                    <Col xs="12">
                        <Button color="info" size="lg" type="submit" block>
                            Log In
                        </Button>
                    </Col>
                    </Row>
                </Form>
                </Col>
                </Row>
            </div>
            <footer className="footer text-center">
                All Rights Reserved to White-Hat.
            </footer>
        </div>
    }
}

export default InitialLogin;