import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import sdk from 'api-sdk';
import defenseRuleOptions from '../../assets/data/edrs.json';
import './CompaniesTable.css';


const RunHistoryForCompaniesTable = ({ isOpen, toggle, onSaveRunHistory, runHistory }) => {
    const [selectedCompany, setSelectedCompany] = useState('');
    const [runDate, setRunDate] = useState('');
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('');
    const [companies, setCompanies] = useState([]);
    const [bullets, setBullets] = useState([]);
    const [selectedBullet, setSelectedBullet] = useState('');
    const [selectedBulletId, setSelectedBulletId] = useState('');
    const [runNumber, setRunNumber] = useState('');
    const [selectedDefenseRule, setSelectedDefenseRule] = useState('');
    const [filteredBullets, setFilteredBullets] = useState([]);


    useEffect(() => {
        if (runHistory) {
            setSelectedCompany(runHistory.companyName);
            setRunDate(runHistory.runDate.split('T')[0]);
            setNotes(runHistory.notes);
            setStatus(runHistory.status);
            setSelectedDefenseRule(runHistory.defenceRules);
            setRunNumber(runHistory.runNumber);
            setSelectedBullet(runHistory.bulletName);
            setSelectedBulletId(runHistory.bulletId);
        }
    }, [runHistory]);

    useEffect(() => {
        const fetchCompanies = async () => {
            const response = await sdk.companies.getAll();
            setCompanies(response);
        };

        const fetchBullets = async () => {
            const response = await sdk.silverBullets.get();
            setBullets(response);
            setFilteredBullets(response.slice(0, 10)); // Show initial 10 bullets
        };

        fetchCompanies();
        fetchBullets();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedRunHistory = {
            companyName: selectedCompany,
            runDate,
            notes,
            status,
            runNumber,
            defenceRules: selectedDefenseRule,
            bulletName: selectedBullet,
            bulletId: selectedBulletId,
        };

        onSaveRunHistory(updatedRunHistory, runHistory ? runHistory.id : null);
    };

    const handleBulletSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();
        const newFilteredBullets = bullets.filter(bullet => bullet.name.toLowerCase().includes(searchValue)).slice(0, 10);
        setFilteredBullets(newFilteredBullets);
        setSelectedBullet(e.target.value);
    };

    const handleBulletSelect = (e) => {
        const bulletName = e.target.value;
        const bullet = bullets.find(b => b.name === bulletName);
        setSelectedBullet(bulletName);
        if (bullet) {
            setSelectedDefenseRule(bullet.attackVector);
            setSelectedBulletId(bullet.id);
        }
    };

    const handleBulletBlur = () => {
        const bullet = bullets.find(b => b.name === selectedBullet);
        if (bullet) {
            setSelectedBullet(bullet.name);
            setSelectedDefenseRule(bullet.attackVector);
            setSelectedBulletId(bullet.id);
        }
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'Pass':
                return 'status-pass';
            case 'Fail':
                return 'status-fail';
            case 'No defense':
                return 'status-no-defense';
            case 'Detected':
                return 'status-detected';
            case 'No POC':
                return 'status-no-poc';
            case 'No Working':
                return 'status-no-working';
            case 'Declined':
                return 'darksalmon';
            default:
                return 'status-select';
        }
    };

    const getColorByStatus = (status) => {
        switch (status) {
            case 'Pass':
                return 'green';
            case 'Fail':
                return 'red';
            case 'No defense':
                return 'orange';
            case 'Detected':
                return 'blue';
            case 'No POC':
                return 'purple';
            case 'No Working':
                return 'brown';
            case 'Declined':
                return 'darksalmon';
            default:
            return 'inherit';
        }
      };
    

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{runHistory ? 'Edit Run History' : 'Create New Run'}</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="companySelect">Company</Label>
                        <Input type="select" name="company" id="companySelect" value={selectedCompany} onChange={(e) => setSelectedCompany(e.target.value)}>
                            <option value="">Select a company</option>
                            {companies.map(company => (
                                <option key={company.companyID} value={company.companyName}>{company.companyName}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="bulletSearch">Bullet Name</Label>
                        <Input type="text" name="bulletSearch" id="bulletSearch" value={selectedBullet} onChange={handleBulletSearch} onBlur={handleBulletBlur} placeholder="Search Bullets..." />
                        <Input type="select" name="bulletSelect" id="bulletSelect" value={selectedBullet} onChange={handleBulletSelect} size="10" style={{ marginTop: '10px' }}>
                            <option value="">Select a bullet</option>
                            {filteredBullets.map(bullet => (
                                <option style={{ color: 'black' }} key={bullet.id} value={bullet.name}>{bullet.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="category">Category</Label>
                        <Input type="text" name="category" id="category" value={selectedDefenseRule} readOnly />
                    </FormGroup>
                    <FormGroup>
                        <Label for="statusSelect">Status</Label>
                        <Input
                            type="select"
                            name="status"
                            id="statusSelect"
                            style={{ color: getColorByStatus(status) }}
                            className={getStatusClass(status)}
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option value="" className="status-select">Select Status</option>
                            <option value="Pass" className="status-pass">Pass</option>
                            <option value="Fail" className="status-fail">Fail</option>
                            <option value="No defense" className="status-no-defense">No defense</option>
                            <option value="Detected" className="status-detected">Detected</option>
                            <option value="No POC" className="status-no-poc">No POC</option>
                            <option value="No Working" className="status-no-working">No Working</option>
                            <option value="Declined" className="status-declined">Declined</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="runDate">Run Date</Label>
                        <Input type="date" name="runDate" id="runDate" value={runDate} onChange={(e) => setRunDate(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="runNumber">Run Number</Label>
                        <Input type="number" name="runNumber" id="runNumber" value={runNumber} onChange={(e) => setRunNumber(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="defenseRuleSelect">Defense Rule</Label>
                        <Input type="select" name="defenseRule" id="defenseRuleSelect" value={selectedDefenseRule} onChange={(e) => setSelectedDefenseRule(e.target.value)}>
                            <option value="">Select Defense Rule</option>
                            {defenseRuleOptions.map(rule => (
                                <option key={rule.propName} value={rule.name}>{rule.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="notes">Notes</Label>
                        <Input type="textarea" name="notes" id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSubmit}>{runHistory ? 'Update Run' : 'Create Run'}</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default RunHistoryForCompaniesTable;
