import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    InputGroup,
} from 'reactstrap';
import user from '../../services/user';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ResetSecret extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            token: '',
            isValid: false,
            feedBack: '',
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
          this.handleSubmit(event)
          event.preventDefault();
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        user.resetSecret(this.state.password,this.state.token).then(() => {
            toast.success("Secret reset successfully!", {
                position: toast.POSITION.TOP_CENTER
            });
        })
        .catch(err => {
            this.setState({
                isValid: false,
                feedBack: err.response.data.error.message
            });
        });
    }

    render() {
        return (
            <div>
                <ToastContainer />
                <Card>
                    <CardTitle className="bg-light border-bottom p-3 mb-0">
                        Reset Secret
                    </CardTitle>
                    <CardBody>
                        <Form onSubmit={this.handleSubmit}>
                            <Col md={{ size: 10, offset: 1 }}>
                                <FormGroup>
                                    <InputGroup>
                                        <Input
                                            placeholder="Current Password"
                                            type="password"
                                            value={this.state.password}
                                            onChange={(e) => this.setState({password : e.target.value, error: null})}
                                            invalid={(!!this.state.feedBack  && !this.state.isValid)}
                                            required
                                        />
                                        <FormFeedback>
                                            {this.state.feedBack}
                                        </FormFeedback>
                                    </InputGroup>
                                <FormGroup>
                                </FormGroup>
                                    <InputGroup>
                                        <Input
                                            placeholder='Verification Code'
                                            maxLength='6'
                                            value={this.state.token}
                                            onChange={e => this.setState({token: e.target.value, error: null})}
                                            invalid={!!this.state.error}
                                            disabled={this.state.verifying}
                                            required
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Button type="submit" disabled={!this.state.password || !this.state.token}>
                                        Reset
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default ResetSecret;