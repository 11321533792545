import React from 'react';
import { Row, Col } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import BulletTable from './bulletTable';
import BulletCreation from './bulletCreation';
import BulletEditing from './bulletEditing';

class Suites extends React.Component {
  render() {
    const match = this.props.match
    return (
      <Switch>
        <Route path={`${match.path}/create`} exact render={props => (
          <Row>
          <Col sm="12">
            <BulletCreation {...props}/>
          </Col>
        </Row>
        )} />
        <Route path={`${match.path}/edit/:bulletId`} exact render={props => (
          <Row>
          <Col sm="12">
              <BulletEditing {...props}/>
          </Col>
        </Row>
        )} />
        <Route path="" exact render={props => (
          <Row>
            <Col sm="12">
              <BulletTable {...props}/>
            </Col>
          </Row>
        )} />
      </Switch>
    );
  }
}

export default Suites;