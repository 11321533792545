import React from 'react';
import {
    DropdownMenu,
    DropdownToggle,
    ButtonDropdown,
    Button
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import userService from '../../services/user';
import sdk from 'api-sdk';
import './notificationDropdown.css';

const timestamp2String = time => {
  const date = new Date(Date.parse(time));
  return date.toLocaleString('en-il');
}

class NotificationMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          notifications: null,
          alert: false,
          isOpen: false
        };

        this.toggle = this.toggle.bind(this);
        this.getNotifications = this.getNotifications.bind(this);
        this.deleteNotification = this.deleteNotification.bind(this);
    }

    toggle() {
      this.setState(state => {
        const isOpen = !state.isOpen;
        return { isOpen, alert: false };
      });
    }

    getNotifications() {
      const filter = { order: 'createdAt DESC'}
      const userData = userService.getUserData();
      if(!userData) {
        return;
      }
      sdk.users.getNotifications(userData.id, filter).then(notifications => {
        this.setState({
          notifications
        }, () => setTimeout(this.getNotifications, 60 * 1000));
      });
    };

    deleteNotification(id) {
      sdk.users.deleteNotification(userService.getUserData().id, id);
      const index = this.state.notifications.findIndex(notification => notification.id === id);
      this.setState(state => {
        const newNotifications = state.notifications.splice(index,1);
        return {newNotifications};
      });
    }

    componentDidMount() {
      this.getNotifications();
    }

    componentDidUpdate(prevProps, prevState) {
      const notifications = this.state.notifications;
      const prevNotifications = prevState.notifications;
      if (notifications && !prevNotifications) {
        this.setState({ alert: true });
      } else if (notifications &&
        notifications[0] &&
        prevNotifications &&
        prevNotifications[0] &&
        notifications[0].id !== prevNotifications[0].id
      ) {
        this.setState({ alert: true });
      }
    }

    render() {
      const notifications = this.state.notifications || [];
      return (
        <ButtonDropdown
          nav inNavbar
          className="ml-2"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <DropdownToggle nav caret>
            <i className="mdi mdi-bell big-profile-icon" />
            {this.state.alert && this.state.notifications && this.state.notifications.length?
              <div className="notify">
                <span className="point" />
              </div>
            : null}
          </DropdownToggle>
          <DropdownMenu right className="mailbox notification-dropdown">
            <div className="p-4 text-dark">
              <h6 className="mb-0 font-medium">Notifications</h6>
            </div>
            <PerfectScrollbar className="message-center notifications">
            {/* <div className="message-center notifications"> */}
              {notifications.map((notification) =>(
                  <span className="message-item" key={notification.id}>
                    <span className='btn btn-circle btn-info'>
                      <i className='mdi mdi-bell'/>
                    </span>
                    <div className="mail-contnet">
                      <h5 className="message-title">
                        {notification.title}
                        <Button
                          className='btn-link float-right pt-0 pr-0'
                          onClick={() => this.deleteNotification(notification.id)}
                        >
                          <i className='fas fa-times'/>
                        </Button>
                      </h5>
                      <span className="time">{timestamp2String(notification.createdAt)}</span>

                      <span className="mail-desc">
                        {notification.message}
                      </span>
                    </div>
                  </span>
                )
              )}
            {/* </div> */}
            </PerfectScrollbar>
            {this.state.notifications && this.state.notifications.length < 1?
              <span className="nav-link text-center mb-1 text-muted">
                <strong>No notifications</strong>
              </span>
            : null}
            {!this.state.notifications?
              <span className="nav-link text-center mb-1 text-muted">
                <strong><i className="fas fa-spin fa-spinner"/></strong>
              </span>
            : null}
          </DropdownMenu>
        {/* </UncontrolledDropdown> */}
        </ButtonDropdown>
      );
    }
}

export default NotificationMenu;