import sdk from 'api-sdk';
const authDataPropertyName = 'WhiteHatSuiteUser';

let currentUserAuthData = getStoredAuthData();
if (currentUserAuthData) {
  sdk.setAuthToken(currentUserAuthData.id);
  sdk.setOrganization(currentUserAuthData.user.organizationId);
}

function recheckLogin() {
  if (!isLoggedIn()) return Promise.reject();
  return sdk.users.getUser(currentUserAuthData.userId);
}

function getStoredAuthData() {
  const authData = localStorage.getItem(authDataPropertyName);
  return authData && JSON.parse(authData);
}

function getUserData() {
  return currentUserAuthData && currentUserAuthData.user;
}

function isLoggedIn() {
  return !!currentUserAuthData && currentUserAuthData.tokenVerified;
}

function hasSecret() {
  return sdk.users.hasVerifiedSecret();
}

function hasToken() {
  return !!currentUserAuthData;
}

function isLicensed() {
  const isRoot = getRoles().some( s => s === 'root');
  const isValidLicense = getLicenses().some(license => license.productId === 1);
  return isRoot || isValidLicense;
}

function isRoot() {
  const userRoles = getRoles() || [];
  return userRoles.some( s => s === 'root' || s === 'wh-admin');
}

function getRoles(){
  return currentUserAuthData ? currentUserAuthData.roles.map(r => r.name): [];
}

function getLicenses() {
  return currentUserAuthData ? currentUserAuthData.licenses : [];
}

function login(email, password, captchaValue, rememberLogin){
  return sdk.users.login(email, password, captchaValue).then(res => {
    currentUserAuthData = res;
    sdk.setAuthToken(res.id);
    sdk.setOrganization(res.user.organizationId);
    if (rememberLogin) {
      localStorage.setItem(authDataPropertyName, JSON.stringify(res));
    };
    return currentUserAuthData;
  });
}

function logout() {
  currentUserAuthData = null;
  localStorage.removeItem(authDataPropertyName);
  return sdk.users.logout().finally(()=>{
    sdk.clearAuthData();
  });
}

function verifyLogin(verifyFunc, token) {
  return verifyFunc(token).then(accessToken => {
    if(accessToken) {
      currentUserAuthData.tokenVerified = true;
      let currStoredData = localStorage.getItem(authDataPropertyName);
      if (currStoredData) {
        currStoredData = JSON.parse(currStoredData);
        currStoredData.tokenVerified = true;
        localStorage.setItem(authDataPropertyName, JSON.stringify(currStoredData));
      }
      if(getStoredAuthData()) {
      }
      return accessToken;
    } else {
      return false;
    }
  });
}

function createSecret() {
  return sdk.users.createSecret();
}

function verifySecret(token) {
  return verifyLogin(sdk.users.verifySecret, token);
}

function verifyToken(token) {
  return verifyLogin(sdk.users.verifyToken, token);
}

function resetSecret(password, token) {
  return sdk.users.resetSecret(password, token).then(() => {
    currentUserAuthData = null;
    localStorage.removeItem(authDataPropertyName);
    sdk.clearAuthData();
    window.location.reload();
  });
}

export default {
  getUserData,
  recheckLogin,
  isLoggedIn,
  getRoles,
  getLicenses,
  isLicensed,
  isRoot,
  login,
  logout,
  createSecret,
  verifySecret,
  verifyToken,
  resetSecret,
  hasSecret,
  hasToken
};