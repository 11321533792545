import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from 'reactstrap';
import sdk from 'api-sdk';

class SystemConfiguration extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            updating: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.undoChanges = this.undoChanges.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleChange(propName, newValue) {
        this.setState(state => {
            const newConfig = { ...state.config }
            newConfig[propName] = newValue;
            return {config: newConfig};
        });
    }

    undoChanges() {
        this.setState(state => {
            const newObj = {...state.oldConfig}
            return {config: newObj};
        });
    }

    handleUpdate() {
        this.setState({ updating: true });
        sdk.systemConfiguration.update(this.state.config).then(config => {
            this.setState({
                config,
                oldConfig: {...config},
                updating: false
            });
        });
    }

    componentDidMount() {
      sdk.systemConfiguration.get().then(config => this.setState({
          config,
          oldConfig: {...config}
        }));
    }

    render() {
        return (
            <Row>
                <Col md='6'>
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            System configuration
                        </CardTitle>
                        <CardBody>
                            {this.state.config?
                                <>
                                    <Form onSubmit={e => e.preventDefault()}>
                                        <FormGroup>
                                            Access token TTL (time to live) in seconds
                                            <InputGroup>  
                                                <Input
                                                    type="number"
                                                    min="60"
                                                    max="7200"
                                                    value={this.state.config.accessTokenTtl}
                                                    onChange={e => this.handleChange('accessTokenTtl', e.target.value)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                    <Button className='mr-2' onClick={this.handleUpdate} disabled={this.state.updating}>
                                        {!this.state.updating? 'Set Configuration' : <i className='fas fa-spin fa-spinner'/>}
                                    </Button>
                                    <Button color='danger' onClick={this.undoChanges}>
                                        Reset
                                    </Button>
                                </>
                            : <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default SystemConfiguration;