import React from 'react';
import { Row, Col } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import TechniqueTable from './techniqueTable';
import TechniqueEditor from './techniqueEditor';

class Suites extends React.Component {
  render() {
    const match = this.props.match
    return (
      <Switch>
        <Route path={`${match.path}/create`} exact render={props => (
          <Row>
          <Col sm="12">
            <TechniqueEditor {...props}/>
          </Col>
        </Row>
        )} />
        <Route path={`${match.path}/edit/:techniqueId`} exact render={props => (
          <Row>
          <Col sm="12">
              <TechniqueEditor {...props}/>
          </Col>
        </Row>
        )} />
        <Route path="" exact render={props => (
          <Row>
            <Col sm="12">
              <TechniqueTable {...props}/>
            </Col>
          </Row>
        )} />
      </Switch>
    );
  }
}

export default Suites;