import { request } from './client';
var model = 'eye-of-the-enemy/Actions';
var actions = {
  getDownloads: function getDownloads() {
    return request('get', "/".concat(model, "/downloads"));
  },
  countDownloaded: function countDownloaded(timeFrame) {
    return request('get', "/".concat(model, "/downloaded"), null, {
      timeFrame: timeFrame
    });
  },
  getDownloadedGraph: function getDownloadedGraph(timeFrame) {
    return request('get', "/".concat(model, "/downloaded-graph"), null, {
      timeFrame: timeFrame
    });
  },
  register: function register(silverBulletId, action) {
    return request('post', "/".concat(model, "/register?silverBulletId=").concat(silverBulletId, "&action=").concat(action));
  },
  getLatestActions: function getLatestActions() {
    return request('get', "/".concat(model, "/latest"));
  },
  getLatestAction: function getLatestAction(silverBulletId) {
    return request('get', "/".concat(model, "/latest/").concat(silverBulletId));
  },
  getLatestActionsBySilverBullet: function getLatestActionsBySilverBullet() {
    return request('get', "/".concat(model, "/latestBySilverBullet"));
  },
  countDownloadedActions: function countDownloadedActions(timeFrame) {
    return request('get', "/".concat(model, "/countDownloadedActions"), null, {
      timeFrame: timeFrame
    });
  },
  getFirstDownloadAction: function getFirstDownloadAction() {
    return request('get', "/".concat(model, "/firstDownloadAction"));
  }
};
export default actions;