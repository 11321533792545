import { request } from './client';
var model = 'eye-of-the-enemy/BulletPlatforms';
var bulletPlatforms = {
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  create: function create(data) {
    return request('post', "/".concat(model), data);
  },
  update: function update(id, data) {
    return request('put', "/".concat(model, "/").concat(id), data);
  },
  getByBulletId: function getByBulletId(silverBulletId) {
    var filter = {
      where: {
        silverBulletId: silverBulletId
      }
    };
    return bulletPlatforms.get(filter);
  }
};
export default bulletPlatforms;