import { request } from './client';
var model = 'Licenses';
var licenses = {
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  getById: function getById(id) {
    return request('get', "/".concat(model, "/").concat(id), null);
  },
  getStatus: function getStatus(productId, organizationId) {
    return request('get', "/".concat(model, "/status"), null, {
      productId: productId,
      organizationId: organizationId
    });
  },
  create: function create(license) {
    return request('post', "/".concat(model, "/create-complete"), license);
  },
  update: function update(license) {
    return request('put', "/".concat(model, "/").concat(license.licenseId), license);
  },
  setAdmin: function setAdmin(orgId) {
    var userId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var email = arguments.length > 2 ? arguments[2] : undefined;
    return request('post', "/".concat(model, "/").concat(orgId, "/setAdmin"), null, {
      userId: userId,
      email: email
    });
  }
};
export default licenses;