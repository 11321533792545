import { request, requestDownload } from './client';
var model = 'eye-of-the-enemy/RunHistory';
var runHistory = {
  create: function create(runHistory) {
    return request('post', "/".concat(model, "/createRunHistory"), runHistory);
  },
  getAll: function getAll() {
    return request('get', "/".concat(model));
  },
  getFiltered: function getFiltered(filter) {
    var filterString = encodeURIComponent(JSON.stringify(filter));
    return request('get', "/".concat(model, "?filter=").concat(filterString));
  },
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  },
  update: function update(id, runHistoryData) {
    // Ensure the `id` parameter is properly incorporated into the URL
    // `runHistoryData` is the new data for the RunHistory entry
    return request('put', "/".concat(model, "/updateRunHistory/").concat(id), runHistoryData);
  }
};
export default runHistory;