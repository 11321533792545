import React from 'react';
import {
    Button,
    Card,
    Col,
    Row,
    UncontrolledTooltip
} from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import BulletModal from './bulletModal';

const notExecutedFilter = action => ['selected','downloaded'].includes(action.payloadStatus) && action.defRuleStatus !== 'deployed';
const executedFilter = action => action.payloadStatus === 'executed' && action.defRuleStatus !== 'deployed';
const passedFilter = action => action.payloadStatus === 'passed' && action.defRuleStatus !== 'deployed';
const blockedFilter = action => action.payloadStatus === 'blocked' && action.defRuleStatus !== 'deployed';
const protectedFilter = action => action.payloadStatus === 'blocked' && action.defRuleStatus === 'deployed';

class StatusPie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            modalFilter: null
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState(state => ({ isModalOpen: !state.isModalOpen }));
    }

    componentDidUpdate(prevProps) {
        
        

        if (!prevProps.actions && this.props.actions) {
            const payloadCounters = {
                notExecuted: 0,
                executed: 0,
                passed: 0,
                blocked: 0,
            };

            const defRuleCounters = {
                notExecuted: 0,
                executed: 0,
                exposed: 0,
                protected: 0,
            };

            this.props.actions.forEach(action => {
                switch (action.payloadStatus) {
                    case 'selected':
                        payloadCounters.notExecuted++;
                        break;
                    case 'downloaded':
                        payloadCounters.notExecuted++;
                        break;
                    case 'executed':
                        payloadCounters.executed++;
                        break;
                    case 'passed':
                        payloadCounters.passed++;
                        break;
                    case 'blocked':
                        payloadCounters.blocked++;
                        break;
                    default:
                        break;
                }

                if (action.defRuleStatus === 'deployed') {
                    defRuleCounters.protected++;
                } else {
                    switch (action.payloadStatus) {
                        case 'selected':
                            defRuleCounters.notExecuted++;
                            break;
                        case 'downloaded':
                            defRuleCounters.notExecuted++;
                            break;
                        case 'executed':
                            defRuleCounters.executed++;
                            break;
                        case 'passed':
                            defRuleCounters.exposed++;
                            break;
                        case 'blocked':
                            defRuleCounters.protected++;
                            break;
                        default:
                            break;
                    }
                }
            });

            this.setState({
                payloadCounters,
                defRuleCounters
            });
        }
    }

    render() {
        if (!this.state.payloadCounters || !this.state.defRuleCounters) {
            return (
                <Row>
                    <Col md='6'>
                        <Card body>
                            <h2 className='mb-3 text-center'>
                                Payloads by Status
                            </h2>
                            <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
                        </Card>
                    </Col>
                    <Col md='6'>
                        <Card body>
                            <h2 className='mb-3 text-center'>
                                Defence Rules by Status
                            </h2>
                            <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
                        </Card>
                    </Col>
                </Row>
                
            );
        }

        return (
            <>
            <Row>
                <Col md='12' lg='6'>
                    <Card body>
                        <Row>
                            <Col>
                                <h2 className='mb-3 text-center'>
                                    Payloads by Status
                                </h2>
                                <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 350 }}>
                                    {Object.values(this.state.payloadCounters).reduce((accumulator, currentValue) => accumulator + currentValue)?
                                        <Doughnut
                                            data={{
                                                labels: [
                                                    'Not executed',
                                                    'Executed',
                                                    'Passed',
                                                    'Blocked'
                                                ],
                                                datasets: [{
                                                    borderWidth: 0,
                                                    data: Object.values(this.state.payloadCounters),
                                                    backgroundColor: [
                                                        '#b2b9bf',
                                                        '#ffc107',
                                                        '#dc3545',
                                                        '#1e88e5',
                                                    ],
                                                    hoverBackgroundColor: [
                                                        '#b2b9bf',
                                                        '#ffc107',
                                                        '#dc3545',
                                                        '#1e88e5',
                                                    ]
                                                }]
                                            }}
                                            options={{ maintainAspectRatio: false, legend: { display: false } }}
                                        />
                                    : <h2 className='text-center pt-5'>No Silver Bullets were selected</h2>}
                                </div>
                            </Col>
                        </Row>
                        <div className='graph-btn-container mt-4 mb-2'>
                            <Button size='lg' className='btn-outline-secondary' id='notExecuted-payload'
                                onClick={() => this.setState({
                                    modalTitle: 'Not executed',
                                    modalFilter: notExecutedFilter
                                },() => this.toggleModal())}
                            >
                                <h1>{this.state.payloadCounters.notExecuted}</h1>
                                <h6 className='font-light'>Not executed</h6>
                            </Button>
                            <UncontrolledTooltip
                                placement="bottom"
                                target="notExecuted-payload"
                            >
                                Payloads which were selected or downloaded, but were not executed.
                            </UncontrolledTooltip>
                            <Button size='lg' className='btn-outline-warning' id='executed-payload'
                                onClick={() => this.setState({
                                    modalTitle: 'Executed payloads',
                                    modalFilter: executedFilter
                                },() => this.toggleModal())}
                            >
                                <h1>{this.state.payloadCounters.executed}</h1>
                                <h6 className='font-light'>Executed</h6>
                            </Button>
                            <UncontrolledTooltip
                                placement="bottom"
                                target="executed-payload"
                            >
                                Payloads which were executed, but their result was not classified.
                            </UncontrolledTooltip>
                            <Button size='lg' className='btn-outline-danger' id='passed-payload'
                                onClick={() => this.setState({
                                    modalTitle: 'Passed payloads',
                                    modalFilter: passedFilter
                                },() => this.toggleModal())}
                            >
                                <h1>{this.state.payloadCounters.passed}</h1>
                                <h6 className='font-light'>Passed</h6>
                            </Button>
                            <UncontrolledTooltip
                                placement="bottom"
                                target="passed-payload"
                            >
                                Payloads which were executed, and marked as passed.
                            </UncontrolledTooltip>
                            <Button size='lg' className='btn-outline-info' id="blocked-payload"
                                onClick={() => this.setState({
                                    modalTitle: 'Blocked payloads',
                                    modalFilter: blockedFilter
                                },() => this.toggleModal())}
                            >
                                <h1>{this.state.payloadCounters.blocked}</h1>
                                <h6 className='font-light'>Blocked</h6>
                            </Button>
                            <UncontrolledTooltip
                                placement="bottom"
                                target="blocked-payload"
                            >
                                Payloads which were executed, and marked as blocked.
                            </UncontrolledTooltip>
                        </div>
                    </Card>
                </Col>
                <Col md='12' lg='6'>
                    <Card body>
                        <Row>
                            <Col>
                                <h2 className='mb-3 text-center'>
                                    Defence Status by Payloads
                                </h2>
                                <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 350 }}>
                                    {Object.values(this.state.defRuleCounters).reduce((accumulator, currentValue) => accumulator + currentValue)?
                                        <Doughnut
                                            data={{
                                                labels: [
                                                    'Not executed',
                                                    'Executed',
                                                    'Exposed',
                                                    'Protected',
                                                ],
                                                datasets: [{
                                                    borderWidth: 0,
                                                    data: Object.values(this.state.defRuleCounters),
                                                    backgroundColor: [
                                                        '#b2b9bf',
                                                        '#ffc107',
                                                        '#dc3545',
                                                        '#20c997',
                                                    ],
                                                    hoverBackgroundColor: [
                                                        '#b2b9bf',
                                                        '#ffc107',
                                                        '#dc3545',
                                                        '#20c997',
                                                    ]
                                                }]
                                            }}
                                            options={{ maintainAspectRatio: false, legend: { display: false } }}
                                        />
                                    : <h2 className='text-center pt-5'>No Silver Bullets were selected</h2>}
                                </div>
                            </Col>
                        </Row>
                        <div className='graph-btn-container mt-4 mb-2'>
                                <Button size='lg' className='btn-outline-secondary' id="notExecuted-defence"
                                    onClick={() => this.setState({
                                        modalTitle: 'Not executed',
                                        modalFilter: notExecutedFilter
                                    },() => this.toggleModal())}
                                >
                                    <h1>{this.state.defRuleCounters.notExecuted}</h1>
                                    <h6 className='font-light'>Not executed</h6>
                                </Button>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target="notExecuted-defence"
                                >
                                    Payloads which were selected or downloaded, but were not executed.
                                </UncontrolledTooltip>
                                <Button size='lg' className='btn-outline-warning' id="executed-defence"
                                    onClick={() => this.setState({
                                        modalTitle: 'Executed payloads',
                                        modalFilter: executedFilter
                                    },() => this.toggleModal())}
                                >
                                    <h1>{this.state.defRuleCounters.executed}</h1>
                                    <h6 className='font-light'>Executed</h6>
                                </Button>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target="executed-defence"
                                >
                                    Payloads which were executed, but their result was not classified.
                                </UncontrolledTooltip>
                                <Button size='lg' className='btn-outline-danger' id="exposed-defence"
                                    onClick={() => this.setState({
                                        modalTitle: 'Exposed to the following payloads',
                                        modalFilter: passedFilter
                                    },() => this.toggleModal())}
                                >
                                    <h1>{this.state.defRuleCounters.exposed}</h1>
                                    <h6 className='font-light'>Exposed</h6>
                                </Button>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target="exposed-defence"
                                >
                                    Payloads which were executed, and were marked as passed.
                                </UncontrolledTooltip>
                                <Button size='lg' className='btn-outline-success' id="protected-defence"
                                    onClick={() => this.setState({
                                        modalTitle: 'Protected from the following payloads',
                                        modalFilter: protectedFilter
                                    },() => this.toggleModal())}
                                >
                                    <h1>{this.state.defRuleCounters.protected}</h1>
                                    <h6 className='font-light'>Protected</h6>
                                </Button>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target="protected-defence"
                                >
                                    Payloads which were marked or their defence rule was deployed.
                                </UncontrolledTooltip>
                        </div>
                    </Card>
                </Col>
            </Row>
            <BulletModal
                isOpen={this.state.isModalOpen}
                toggleFunc={this.toggleModal}
                actions={this.props.actions}
                title={this.state.modalTitle}
                filterFunc={this.state.modalFilter}
            />
            </>
        );
    }
}

export default StatusPie;