import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

class CreateOrganizationButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: ''
        }

        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleClick() {
        if(this.state.name) {
            this.props.createFunc(this.state.name);
            this.toggle();
            this.setState({
                name: ''
            });
        }
        else {
            this.setState({nameFB: 'Name is required'});
        }
    }

    render() {
        return(
            <div>
                <Button outline color='secondary' size="sm" onClick={this.toggle}>
                    <i className='mr-1 mdi mdi-account-plus'/>
                    Create organization
                </Button>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className='modal-dialog-centered'
                  data-theme='dark'
                >
                <ModalHeader toggle={this.toggle}>Create organization</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <InputGroup>
                                <Input placeholder="Name" required onChange={(e) => this.setState({name : e.target.value})} invalid={!!this.state.nameFB}/>
                                <FormFeedback>
                                    {this.state.nameFB}
                                </FormFeedback>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button color="info" onClick={this.handleClick}>
                    Create
                </Button>{' '}
                <Button color="secondary" onClick={this.toggle}>
                    Cancel
                </Button>
                </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default CreateOrganizationButton;