import React from "react";
import {
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';

export default props => (
    <InputGroup className={props.className}>
        <InputGroupAddon addonType="prepend">
            <InputGroupText><i className="fas fa-search"/></InputGroupText>
        </InputGroupAddon>
        <Input className="search-input" type="text" placeholder="Search" onChange={props.onChange} disabled={props.isLoading ? true : false}/>
    </InputGroup>
);