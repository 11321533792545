import React  from 'react';
import { Route, Redirect } from 'react-router-dom';
import user from '../services/user';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, ...rest }) => {
  rest.properties = rest.properties || {};
  recheckLogin();

  const render = props => {
    if(!user.isLoggedIn()) {
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>;
    }

    if(!user.isLicensed()) {
      return <Redirect to={{ pathname: '/no-license', state: { from: props.location } }}/>;
    }

    return <Component {...props} {...rest.properties }/>;
  };

  return <Route {...rest} render={render}/>;
};

let shouldRecheck = true;
function recheckLogin(){
  const RECHECK_INTERVAL = 1000 * 60 * 20;
  if (user.isLoggedIn() && shouldRecheck) {
    shouldRecheck = false;
    user.recheckLogin().catch(()=>{
      user.logout();
      window.location.reload();
    });
    setTimeout(()=>{shouldRecheck = true}, RECHECK_INTERVAL);
  }
}
PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object
};

export default PrivateRoute;