import React, { useRef, useEffect } from 'react';
import WebViewer from '@pdftron/webviewer';

const PdfEditor = ({ getData, exitEditMode, onSave }) => {
  const viewerDiv = useRef(null);

  useEffect(() => {
    getData().then(arrayBuffer => {
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      WebViewer(
        {
          path: '/pdfjsexpress', // Path to your public lib folder
          initialDoc: url, // Load the PDF from the Blob URL
          fullAPI: true, // Enable full API for editing
          licenseKey: 'demo:1725870828008:7e2d805103000000000701133a3f5ab53a9301b812eca3eec54177f824' // Your PDFTron license key
        },
        viewerDiv.current
      ).then(instance => {
        const { documentViewer } = instance.Core;

        instance.UI.enableFeatures(['ContentEdit', 'TextEditing']);
        instance.UI.openElements(['toolbarGroup-Edit']);

        documentViewer.addEventListener('documentLoaded', () => {
          console.log('Document loaded successfully.');
        });
      });
    });
  }, [getData]);

  const saveChanges = async () => {
    const instance = WebViewer.getInstance(viewerDiv.current); // Get the instance of WebViewer
    const { documentViewer, annotationManager } = instance.Core;

    try {
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations(); // Export annotations if any
      const data = await doc.getFileData({ xfdfString }); // Get modified document data
      const modifiedBlob = new Blob([data], { type: 'application/pdf' });

      console.log('Document saved successfully.');
      onSave(modifiedBlob); // Call the onSave function with the modified PDF blob
    } catch (error) {
      console.error('Error saving document:', error);
    }
  };

  return (
    <div>
      <button onClick={exitEditMode}>Exit Edit Mode</button>
      <button onClick={saveChanges}>Save Changes</button> {/* Attach saveChanges directly */}
      <div className="webviewer" ref={viewerDiv} style={{ height: '100vh' }}></div>
    </div>
  );
};

export default PdfEditor;
