import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Fulllayout from './layouts/fulllayout';
import PrivateRouter from './components/PrivateRoute';
import Login from './views/login';
import NoLicense from './views/noLicense';
import ErrorPage from './components/errorPage/errorPage';
import './assets/scss/style.css';

class App extends Component {

  render() {
    return (
      <div className="App">
        <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login}/>
          <Route exact path="/no-license" name="License" component={NoLicense}/>
          <Route exact path="/error-page" name="Error Page" component={ErrorPage}/>
          <PrivateRouter path="/" component = {Fulllayout}/>
        </Switch>
        </HashRouter>
      </div>
    );
  }
}

export default App;