import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Set server URL
import sdk from 'api-sdk';
let baseServicesUrl = window.location.origin.indexOf('localhost') >= 0 ? 
                      'http://localhost:3000' : 
                      window.location.origin;

sdk.setBaseUrl(baseServicesUrl + '/api');

ReactDOM.render(<App />, document.getElementById('root'));

