import React  from 'react';

class PdfDataViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pdfData: ''
        };

        this.getPDF = this.getPDF.bind(this);
    }

    getPDF() {  
        this.props.getData().then(data => {
            const urlData = 'data:application/pdf;base64,' + Buffer.from(data, 'binary').toString('base64');
            this.setState({pdfData: urlData});
        });
    }


    componentDidMount() {
        this.getPDF();
    }

    render() {
        if (!this.state.pdfData) {
            return <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>;
        }

        return (
            <iframe height='100%' width='100%' src={this.state.pdfData} title='pdf file preview'></iframe>
        );
    }
}

export default PdfDataViewer;