import React from 'react';
import {
    Button,
    Card,
    Col,
    Row
} from 'reactstrap';
import { Line } from 'react-chartjs-2';
import sdk from 'api-sdk';

const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function getClosestMidWeekDate(date, firstDayOfWeek = 0) {
    date = new Date(date.getTime()); // cloning date, so function won't overwrite original date
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + (7 - firstDayOfWeek)) % 7);
    return date;
}

function getQuarterWeeks(firstDayOfWeek) {
    const currentDate = new Date();
    currentDate.setHours(0,0,0,0);
    const currQuarter = Math.floor(currentDate.getMonth() / 3) + 1;
    const firstMonth = ((currQuarter - 1) * 3);
    const firstDay = new Date(currentDate.getFullYear(), firstMonth, 1);
    const lastDay = new Date(currentDate.getFullYear(), firstMonth + 3, 1);
    lastDay.setDate(lastDay.getDate - 1);

    const weeks = [];
    let midWeekDate = getClosestMidWeekDate(currentDate, firstDayOfWeek);
    do {
        const startDay = new Date(midWeekDate.getTime());
        startDay.setDate(startDay.getDate() - 3);
        if(startDay < firstDay) {
            startDay.setTime(firstDay.getTime());
        }

        const endDay = new Date(midWeekDate.getTime());
        endDay.setDate(endDay.getDate() + 3);
        if(endDay > lastDay) {
            endDay.setTime(lastDay.getTime());
        }

        weeks.push({
            midDay: midWeekDate.getTime(),
            label: `${startDay.toLocaleDateString()}-${endDay.toLocaleDateString()}`
        });
        midWeekDate.setDate(midWeekDate.getDate() - 7);
    } while (midWeekDate >= firstDay);

    weeks.reverse();
    return weeks;
}

function actions2Graph(actions, firstDayOfWeek = 0) {
    const now = new Date();

    const yearLabels = monthNames.slice(0, now.getMonth() + 1);
    const weekLabels = dayNames.slice(0, now.getDay() + 1)

    const weeks = getQuarterWeeks(firstDayOfWeek);
    const quarterLabels = weeks.map(w => w.label);

    const graphData = {
        year: {
            total: 0,
            data: {
                title: 'Yearly',
                labels: yearLabels,
                data: new Array(yearLabels.length).fill(0),
            }
        },
        quarter: {
            total: 0,
            data: {
                title: 'Quarterly',
                labels: quarterLabels,
                data: new Array(quarterLabels.length).fill(0),
            },
        },
        week: {
            total: 0,
            data: {
                title: 'Weekly',
                labels: weekLabels,
                data: new Array(weekLabels.length).fill(0),
            },
        },
    }

    actions.forEach(action => {
        const actionDate = new Date(action.createdAt);

        const isCurrWeek = getClosestMidWeekDate(actionDate, firstDayOfWeek).getTime() === getClosestMidWeekDate(now, firstDayOfWeek).getTime();
        const isCurrQuarter = Math.floor(actionDate.getMonth() / 3) === Math.floor(now.getMonth() / 3);
        const isCurrYear = actionDate.getFullYear() === now.getFullYear();

        if (isCurrWeek && isCurrQuarter && isCurrYear) {
            graphData.week.total++;
            graphData.week.data.data[actionDate.getDay()]++;
        }

        if (isCurrQuarter && isCurrYear) {
            graphData.quarter.total++;

            const midDay = getClosestMidWeekDate(actionDate, firstDayOfWeek).getTime();
            const index = weeks.findIndex(week => week.midDay === midDay);
            graphData.quarter.data.data[index]++;
            
        }

        if (isCurrYear) {
            graphData.year.total++;
            graphData.year.data.data[actionDate.getMonth()]++;
        }
    });

    return graphData;
}

class PayloadTimeGraph extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        Promise.all([
            sdk.actions.getFirstDownloadAction(),
            sdk.organizations.getById(sdk.getOrganization(), {include: 'organizationConfiguration'})
        ]).then(res => {
            const orgConfig = res[1].organizationConfiguration;
            const firstDayOfWeek = orgConfig? orgConfig.firstDayOfTheWeek : 0;
            
            const graphData = actions2Graph(res[0], firstDayOfWeek);

            this.setState({
                graphData,
                currentGraphData: {
                    title: graphData.year.data.title,
                    labels: [...graphData.year.data.labels],
                    data: [...graphData.year.data.data] 
                }
            });
        });
    }

    render() {
        if (!this.state.currentGraphData) {
            return (
                <Card body className='pb-4'>
                    <Row>
                        <Col>
                            <h2 className='mb-4 text-center'>
                                Yearly Downloads
                            </h2>
                            <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
                        </Col>
                    </Row>
                </Card>
            );
        }

        return (
            <Card body className='pb-4'>
                <Row>
                    <Col>
                        <h2 className='mb-4 text-center'>
                            {this.state.currentGraphData.title} Downloads
                        </h2>
                        <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 350 }}>
                            <Line
                                data={{
                                    labels: this.state.currentGraphData.labels,
                                    datasets: [{
                                        backgroundColor: ['transparent'],
                                        borderColor: 'rgb(79, 195, 247)',
                                        borderWidth: 4,
                                        data: this.state.currentGraphData.data,
                                    }]
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    legend: { display: false },
                                    scales: {
                                        yAxes: [{display: false, ticks: { suggestedMin: 0 }}],
                                        xAxes: [{ gridLines: { display: false }, ticks: { fontFamily: "Poppins", fontColor: '#bbb' } }]
                                    }
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <div className='graph-btn-container mt-3'>
                        <Button size='lg' className='btn-outline-secondary' onClick={() => this.setState({ currentGraphData : this.state.graphData.week.data})}>
                            <h1>{this.state.graphData.week.total}</h1>
                            <h5>Weekly Downloads</h5>
                        </Button>
                        <Button size='lg' className='btn-outline-secondary' onClick={() => this.setState({ currentGraphData : this.state.graphData.quarter.data})}>
                            <h1>{this.state.graphData.quarter.total}</h1>
                            <h5>Quarterly Downloads</h5>
                        </Button>
                        <Button size='lg' className='font-light btn-outline-secondary' onClick={() => this.setState({ currentGraphData : this.state.graphData.year.data})}>
                            <h1>{this.state.graphData.year.total}</h1>
                            <h5>Yearly Downloads</h5>
                        </Button>
                </div>
            </Card>
        );
    }
}

export default PayloadTimeGraph;