import React from 'react';
import { Col, Row } from 'reactstrap';
import PayloadTimeGraph from './payloadTimeGraph';
import StatusPie from './statusPie';
import AttackVectorsStatus from './attackVectorsStatus';
import NotificationPanel from './notificationPanel';
import sdk from 'api-sdk';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        sdk.actions.getLatestActionsBySilverBullet().then(res => this.setState({ actions : (res) }));
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg='12' xl='6'>
                        <StatusPie actions={this.state.actions}/>
                    </Col>
                    <Col lg='12' xl='6'>
                        <PayloadTimeGraph/>
                    </Col>
                </Row>
                <Row>
                    <Col xl='8'>
                        <AttackVectorsStatus actions={this.state.actions}/>
                    </Col>
                    <Col xl='4'>
                        <NotificationPanel/>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default Dashboard;