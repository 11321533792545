import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './notificationPanel.css';

import userService from '../../../services/user';
import sdk from 'api-sdk';

const timestamp2String = time => {
  const date = new Date(Date.parse(time));
  return date.toLocaleString('en-il');
}

class NotificationPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: null,
    };

    this.getNotifications = this.getNotifications.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
  }

  getNotifications() {
    const filter = { order: 'createdAt DESC' };
    const userData = userService.getUserData();
    if(!userData) {
      return;
    }
    sdk.users.getNotifications(userData.id, filter).then(notifications => {
      this.setState({ notifications });
    });
  };

  deleteNotification(id) {
    sdk.users.deleteNotification(userService.getUserData().id, id);
    const index = this.state.notifications.findIndex(notification => notification.id === id);
    this.setState(state => {
      const newNotifications = state.notifications.splice(index,1);
      return {newNotifications};
    });
  }

  componentDidMount() {
    this.getNotifications();
  }

  render() {
    if (!this.state.notifications) {
      return (
        <Card>
          <CardBody>
            <CardTitle>Notifications</CardTitle>
            <h2 className='text-center'><i className='fas fa-spin fa-spinner'/></h2>
          </CardBody>
        </Card>
      );
    }
    return (
      <Card className='notification-panel pb-2'>
        <CardBody className='mailbox'>
          <CardTitle>Notifications</CardTitle>
          <PerfectScrollbar>
          <div className='message-center notifications'>
            {!this.state.notifications? <h1><i className='fas fa-spin fa-spinner'/></h1>: null}
            {this.state.notifications.length === 0? <h3 className='mt-3 text-center text-muted'>No notifications</h3>: null}
            {this.state.notifications.map((notification) => 
              <span className="message-item" key={notification.id}>
                <span className='btn btn-circle btn-info'>
                  <i className='mdi mdi-bell'/>
                </span>
                <div className="mail-contnet">
                  <h5 className="message-title">
                    {notification.title}
                    <Button
                      className='btn-link float-right pt-0 pr-0'
                      onClick={() => this.deleteNotification(notification.id)}
                    >
                      <i className='fas fa-times'/>
                    </Button>
                  </h5>
                  <span className="time">{timestamp2String(notification.createdAt)}</span>
                  <span className="mail-desc">
                    {notification.message}
                  </span>
                  {/* <span className="time">{notification.time}</span> */}
                </div>
              </span>
            )}
          </div>
          </PerfectScrollbar>
        </CardBody>
      </Card>
    );
  }
}

export default NotificationPanel;
