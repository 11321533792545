import React, { useState, useEffect } from 'react';
import { Button, Table } from 'reactstrap';
import RunHistoryModal from './runHistoryModal'; // Ensure correct import path
import sdk from 'api-sdk'; // Ensure correct import path
import NotePopup from './NotePopup'; // Ensure correct import path
import './noteText.css'; // Ensure correct import path
import userService from '../../../../services/user'; // Ensure correct import path


const RunHistoryList = ({ bullet }) => {
    const [runHistories, setRunHistories] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [notePopupOpen, setNotePopupOpen] = useState(false);
    const [selectedNote, setSelectedNote] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedHistory, setSelectedHistory] = useState(null);

    const userRoles = userService.getRoles();
    const isRoot = userRoles.includes('root');

    useEffect(() => {
        if (bullet) {
            fetchRunHistories();
        }
    }, [bullet]);

    const fetchRunHistories = () => {
        const filter = {
            where: {
                bulletId: bullet.id
            }
        };


        sdk.runHistory.getFiltered(filter).then((data) => {
            const sortedData = data.sort((a, b) => new Date(b.runDate) - new Date(a.runDate));
            setRunHistories(sortedData);
        }).catch(error => {
            console.error("Error fetching run histories:", error);
        });
    };

    const showFullNotePopup = (note, date) => {
        setSelectedNote(note);
        setSelectedDate(date);
        setNotePopupOpen(true);
    };

    const handleEditRunHistory = (history) => {
        setSelectedHistory(history);
        setEditModalOpen(true);
    };

    const handleDeleteRunHistory = (historyId) => {
        if (window.confirm("Are you sure you want to delete this run history?")) {
            sdk.runHistory.delete(historyId).then(() => {
                fetchRunHistories();
            }).catch(error => {
                console.error("Error deleting run history:", error);
            });
        }
    };

    const onSaveRunHistory = (runHistoryData, id) => {
        const saveOrUpdate = id ? sdk.runHistory.update(id, runHistoryData) : sdk.runHistory.create(runHistoryData);

        saveOrUpdate.then(() => {
            setSelectedHistory(null);
            setModalOpen(false);
            setEditModalOpen(false);
            fetchRunHistories();
        }).catch(error => {
            console.error(`Error ${id ? 'updating' : 'creating'} run history:`, error);
        });
    };

    const getColorByStatus = (status) => {
        switch (status) {
            case 'Pass':
                return 'green';
            case 'Fail':
                return 'red';
            case 'No defense':
                return 'orange';
            case 'Detected':
                return '#478CCF';
            case 'No POC':
                return '#694F8E';
            case 'No Working':
                return 'brown';
            case 'Declined':
                return 'darksalmon';
            default:
                return 'inherit';
        }
    };

    return (
        <div className="table-responsive">
            <Button onClick={() => setModalOpen(true)}>Create New Run History</Button>
            <Table >
                <thead>
                    <tr>
                        <th>Bullet</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Run Number</th>
                        <th>Run Date</th>
                        <th>Defense Rules</th>
                        <th>Notes</th>
                        <th>Company Name</th>

                        {isRoot && <th>Actions</th>}
                        {isRoot && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {runHistories.map((history) => (
                        <tr key={history.id}>
                            <td>{history.bulletName}</td>
                            <td>{history.categoryName}</td>
                            <td style={{ color: getColorByStatus(history.status) }}>{history.status}</td>
                            <td>{history.runNumber}</td>
                            <td>{new Date(history.runDate).toLocaleDateString()}</td>
                            <td>{history.defenceRules}</td>
                            <td className={history.notes ? "note-hover" : ""} onClick={() => showFullNotePopup(history.notes, new Date(history.runDate).toLocaleDateString())}>
                                {history.notes.length > 50 ? `${history.notes.substring(0, 50)}...` : history.notes}
                            </td>
                            <td>{history.companyName}</td>
                            {isRoot && (
                                <td>
                                    <Button size="sm" color="info" onClick={() => handleEditRunHistory(history)}>Edit</Button>{' '}
                                </td>
                            )}
                            {isRoot && (
                                <td>
                                    <Button size="sm" color="danger" onClick={() => handleDeleteRunHistory(history.id)}>Delete</Button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
            <RunHistoryModal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} bullet={bullet} onSaveRunHistory={onSaveRunHistory} />
            {selectedHistory && <RunHistoryModal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)} bullet={bullet} runHistory={selectedHistory} onSaveRunHistory={onSaveRunHistory} />}
            <NotePopup isOpen={notePopupOpen} toggle={() => setNotePopupOpen(!notePopupOpen)} note={selectedNote} runDate={selectedDate} />
        </div>
    );
};

export default RunHistoryList;
