import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
} from 'reactstrap';
import sdk from 'api-sdk';

import LicenseCard from './licenseCard';
import CreateOrganizationButton from './createOrganizationButton';
import SetAdminButton from './setAdminButton';
import './selectedRow.css';
import SetConfigButton from "./setConfigButton";
import FreezeButton from "../../components/buttons/FreezeButton";
import './org.css'

class OrganizationManagement extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            orgs: [],
        };

        this.createOrg = this.createOrg.bind(this);
        this.setAdmin = this.setAdmin.bind(this);
    }

    componentDidMount() {
        sdk.organizations.get()
        .then(orgs => this.setState({ orgs }));
    }

    createOrg(name) {
        return sdk.organizations.create(name)
        .then(sdk.organizations.get)
        .then(orgs => this.setState({ orgs }));
    }

    setAdmin(orgId, email) {
        return sdk.organizations.setAdmin(orgId, null, email)
        .then(sdk.organizations.get)
        .then(orgs => this.setState({ orgs }));
    }

    handleFreeze(org, index) {
        return sdk.organizations.freeze(org).then(res => {
            const orgs = this.state.orgs;
            orgs[index] = res ;
            this.setState({orgs : orgs});
        })
        
    }

    render() {
        return (
        <Row>
            <Col md='6'>
                <Card>
                    <CardTitle className="bg-light border-bottom p-3 mb-0">
                            Organization management
                            <span className="float-right"><CreateOrganizationButton createFunc={this.createOrg.bind(this)}/></span>
                    </CardTitle>
                    <CardBody className='p-0'>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Admin User Id</th>
                                    <th>Set Admin</th>
                                    <th>Set Configuration</th>
                                    <th>Freeze Organization</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.orgs.map((org, index) =>
                                    <tr
                                        key={org.id}
                                        className={`${this.state.selectedOrgId === org.id ? 'selected-row' : ''} clickable-cell`}
                                        onClick={e => {
                                            e.stopPropagation();
                                            this.setState(state => {
                                                if(state.selectedOrgId !== org.id) {
                                                    state.selectedOrgId = org.id;
                                                } else {
                                                    state.selectedOrgId = null;
                                                }
                                                return state;
                                            });
                                        }}
                                    >
                                        <td>{org.id}</td>
                                        <td>{org.name}</td>
                                        <td>{org.orgAdminUserId}</td>
                                        <td>
                                            <SetAdminButton setAdminFunc={(email) => this.setAdmin(org.id, email)}/>
                                        </td>
                                        <td>
                                            <SetConfigButton orgId={org.id}/>
                                        </td>
                                        <td>
                                            {org.name !== "default" ? <FreezeButton user={org} item='organization' freezeFunc={() => this.handleFreeze(org, index)}/> : null}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
            {this.state.selectedOrgId?
                    <LicenseCard org={this.state.orgs.find(org => org.id === this.state.selectedOrgId)}/>
            : null}
        </Row>
        );
    }
}

export default OrganizationManagement;