import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Label, Input, Form, Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import sdk from 'api-sdk'; // Ensure correct import path

const ReportForge = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [runNumber, setRunNumber] = useState('');
    const [bullets, setBullets] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCompanies(); // Fetch companies when the component mounts
    }, []);

    const fetchCompanies = async () => {
        try {
            const companiesData = await sdk.companies.getAll();
            console.log(companiesData);

            setCompanies(companiesData);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const fetchSilverBullets = async () => {
        if (!selectedCompany || !runNumber) {
            alert('Please select a company and enter a run number');
            return;
        }

        setLoading(true);
        try {
            const bulletsData = await sdk.companies.getBulletsByCompanyAndRunNumber(selectedCompany, runNumber);
            setBullets(bulletsData.bullets);
            
        } catch (error) {
            console.error('Error fetching bullets:', error);
        }
        setLoading(false);
    };


    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);
    };

    const handleRunNumberChange = (e) => {
        setRunNumber(e.target.value);
    };




    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card className="shadow-sm border-0">
                        <CardBody>
                            <CardTitle tag="h4" className="text-center mb-4">
                                Report Forge
                            </CardTitle>
                            <Form>
                                <FormGroup>
                                    <Label for="companySelect">Select Company</Label>
                                    <Input
                                        type="select"
                                        id="companySelect"
                                        value={selectedCompany}
                                        onChange={handleCompanyChange}
                                    >
                                        <option value="">Choose a company</option>
                                        {companies.map((company) => (
                                            <option key={company.companyID} value={company.companyName}>
                                                {company.companyName}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="runNumber">Run Number</Label>
                                    <Input
                                        type="text"
                                        id="runNumber"
                                        placeholder="Enter run number"
                                        value={runNumber}
                                        onChange={handleRunNumberChange}
                                    />
                                </FormGroup>
                                <Button color="primary" block onClick={fetchSilverBullets} disabled={loading}>
                                    {loading ? 'Loading...' : 'Fetch Silver Bullets'}
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    {bullets && bullets.length > 0 ? (
                        <Card className="shadow-sm border-0">
                            <CardBody>
                                <CardTitle tag="h5" className="text-center">
                                    Silver Bullets for Company: {selectedCompany} | Run Number: {runNumber}
                                </CardTitle>
                                {bullets.map((bullet, index) => (
                                    <CardText key={index} className="text-muted">
                                        {bullet.name} - {bullet.description}
                                    </CardText>
                                ))}
                            </CardBody>
                        </Card>
                    ) : (
                        <Card className="shadow-sm border-0">
                            <CardBody>
                                <CardText className="text-center">
                                    No bullets found for the selected company and run number.
                                </CardText>
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default ReportForge;
