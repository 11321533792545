import React, { useState } from 'react';
import {
    Button,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Form,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

import sdk from 'api-sdk';

export default props => {
    const [file, setFile] = useState(null);
    const [isModalOpen, setModal] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(undefined);

    const toggleModal = () => {
        if(uploading) return;

        setModal(!isModalOpen);
    };

    return (
        <Form>
            <FormGroup>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>{props.name} file</InputGroupText>
                    </InputGroupAddon>
                    <div className="custom-file">
                        <Input
                            type="file"
                            className="custom-file-input"
                            id={`${props.type}FileInput`}
                            disabled={uploading}
                            onChange={e => {
                                setFile(e.target.files[0]);
                                setError(undefined);
                            }}
                        />
                        <label
                            className="custom-file-label"
                            htmlFor="fileInput"
                        >
                            {file? file.name : "Choose file"}
                        </label>
                    </div>
                    <InputGroupAddon addonType="append">
                        <Button
                            disabled={uploading || !file}
                            onClick={() => toggleModal()}
                        >
                            Upload
                        </Button>
                    </InputGroupAddon>
                    <InputGroupAddon addonType="append">
                        <Button
                            className="btn btn-outline-danger"
                            disabled={uploading || !file}
                            onClick={() => {
                                setFile(null);
                                setError(undefined);
                            }}
                        >
                            <i className="fas fa-redo"/>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
                <Modal
                    isOpen={isModalOpen}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                    data-theme='dark'
                    >
                    <ModalHeader toggle={() => toggleModal()}>Upload {props.name} file</ModalHeader>
                    <ModalBody>
                        Are you sure you want to replace the previous document?
                        <h5 className='text-danger font-light text-center mt-2'>
                            {error? 'File upload failed' : undefined}
                        </h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="info" 
                            disabled={uploading}
                            onClick={() => {
                                setUploading(true);
                                setError(undefined);
                                sdk.silverBullets.uploadFile(file, props.bulletId, props.type)
                                .then(() => {
                                    toggleModal();
                                    setFile(null);
                                    setUploading(false);
                                }).catch(() => {
                                    setError(true);
                                    setUploading(false);
                                });
                            }}
                        >
                            {!uploading? 'Yes, upload' : <i className='fas fa-spin fa-spinner'/>}
                        </Button>{' '}
                        <Button
                            color="danger"
                            disabled={uploading}
                            onClick={() => toggleModal()}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </FormGroup>
        </Form>
    );
};