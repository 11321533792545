import users from './users';
import { setBaseUrl, setAuthToken, getOrganization, setOrganization, clearAuthData } from './client';
import silverBullets from './silver-bullets';
import organizations from './organizations';
import licenses from './licenses';
import actions from './actions';
import aptTags from './apt-tags';
import systemConfiguration from './system-configuration';
import edrTags from './edr-tags';
import softwareTags from './software-tags';
import payloads from './payloads';
import defRules from './defRules';
import techniques from './techniques';
import bulletsEdrs from './bullets-edrs';
import bulletPlatforms from './bullet-platforms';
import payloadDownloads from './payloadDownloads';
import runHistory from './run-history';
import companies from './companies';
export default {
  users: users,
  setBaseUrl: setBaseUrl,
  setAuthToken: setAuthToken,
  getOrganization: getOrganization,
  setOrganization: setOrganization,
  clearAuthData: clearAuthData,
  silverBullets: silverBullets,
  organizations: organizations,
  licenses: licenses,
  actions: actions,
  aptTags: aptTags,
  systemConfiguration: systemConfiguration,
  edrTags: edrTags,
  softwareTags: softwareTags,
  payloads: payloads,
  defRules: defRules,
  techniques: techniques,
  bulletsEdrs: bulletsEdrs,
  bulletPlatforms: bulletPlatforms,
  payloadDownloads: payloadDownloads,
  runHistory: runHistory,
  companies: companies
};