import React from 'react';
import {
	Row,
    Col
} from 'reactstrap';
import LoginVerification from './loginVerification';
import user from '../../services/user';

class SecretCreation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            secret: null
        };
    }

    componentDidMount() {
        user.createSecret().then(secret => {
            this.setState({ secret });
        });
    }

    render() {
        return (
            <LoginVerification
                updateFunc={this.props.updateFunc}
                verifyFunc={user.verifySecret}
            >
                <Row className='mt-3 pl-2 pt-3 pr-2'>
                    <Col md='5' className='pl-5 pt-1 pb-3 pr-1'>
                        {this.state.secret?
                            <img src={this.state.secret.qrImage} alt='QR'/>
                        : <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>}
                    </Col>
                    <Col md='7'>
                        <ol>
                            <li><h4>Download Google Authenticator to your phone.</h4></li>
                            <li><h4>Scan the QR code.</h4></li>
                            <li><h4>Use the app generated code for user secret verification.</h4></li>
                        </ol>
                    </Col>
                </Row>
            </LoginVerification>
        );
    }
}

export default SecretCreation;
