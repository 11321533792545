import { request } from './client';
var model = 'system-configuration';
var systemConfiguration = {
  get: function get() {
    return request('get', "/".concat(model, "/1"));
  },
  update: function update(config) {
    return request('put', "/".concat(model, "/1"), config);
  }
};
export default systemConfiguration;