import React from "react";
import {
    Jumbotron,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

export default props => (
    <Jumbotron className='pt-3 pb-3 mb-0'>
        {props.tags.length === 0? <h3 className='text-muted mr-2'>{props.title}</h3> : null}
        <div className='tags-row'>
            {props.tags.map((tag,index) => {
                const color = props.tagColor || 'danger';
                const dropDownClassName = tag.action === 'delete'? `btn-outline-${color}` : `btn-${color}`;
                return (
                    <UncontrolledDropdown className="mr-2 mb-3" key={`${tag[props.nameProp]}-tag-${index}`}>
                        <DropdownToggle className={dropDownClassName} block>
                            {tag.action === 'delete'?
                                <><i className='fas fa-times'/>{" "}</>
                            : null}
                            {tag[props.nameProp]}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => props.onRemove(index)}>
                                {tag.action === 'delete'? 'Cancel removal' : <>
                                    <i className="fas fa-times"/>
                                    {" "}
                                    Remove
                                </>}
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            })}
        </div>
    </Jumbotron>
);