import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    CustomInput,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    FormFeedback,
    UncontrolledAlert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import sdk from 'api-sdk';
import vectors from '../../assets/data/attackVectors.json';
import DefRuleUpload from './defRuleUpload';
import PayloadUpload from './payloadUpload';
import TagContainer from './tagContainer';
import EDRs from '../../assets/data/edrs.json'
import Platforms from '../../assets/data/platforms.json'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const attackVectors = vectors.map(vector => vector.hide? vector.title + ' - to be removed' : vector.title);

class BulletCreation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            techniqueSearch: "",
            creating: true,
            uploadError: undefined,
            needForInternet: false,
            requireLocalAdmin: false,
            techniques: [],
            aptTags: [],
            softwareTags: [],
            edrTags: [],
            newAptTags: [],
            // chosenEdrs: null,
            chosenPlatforms: null,
            bulletsTechniques: [],
            currentTag: '',
            currentTech: '',
            id: undefined,
            idError: undefined,
            name: undefined,
            nameError: undefined,
            attackVector: undefined,
            attackVectorError: undefined,
            status: undefined,
            statusError: undefined,
            creator: undefined,
            creatorError: undefined,
            description: undefined,
            descriptionError: undefined,
            mitreTag: undefined,
            mitreUrl: undefined,
            descriptionFile: undefined,
            descriptionFileError: undefined,
            intelFile: undefined,
            intelFileError: undefined,
            instructionsFile: undefined,
            instructionsFileError: undefined,
            chartFile: undefined,
            chartFileError: undefined,
            payloads: [{softwareTags: []}],
            defRules: [{}],
        };

        this.addAptTag = this.addAptTag.bind(this);
        this.removeAptTag = this.removeAptTag.bind(this);
        this.addTech = this.addTech.bind(this);
        this.removeTech = this.removeTech.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.onDefRuleChange = this.onDefRuleChange.bind(this);
        this.onPayloadChange = this.onPayloadChange.bind(this);
        this.addPayload = this.addPayload.bind(this);
        this.removePayload = this.removePayload.bind(this);
        this.addDefRule = this.addDefRule.bind(this);
        this.removeDefRule = this.removeDefRule.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    addAptTag() {
        let newTag = this.state.aptTags.find(tag => tag.aptName === this.state.currentTag);
        if(!newTag) {
            newTag = {
               isNew: true,
               aptName: this.state.currentTag
            };
        }

        this.setState(state => {
            state.newAptTags.push(newTag);
            const newArr = [...state.newAptTags];
            return {
                newAptTags: newArr,
                currentTag: '',
            };
        });
    }

    removeAptTag(index) {
        this.setState(state => {
            state.newAptTags.splice(index,1);
            const arr = [...state.newAptTags];
            return {
                newAptTags: arr,
            };
        });
    }

    addTech() {
        let newTech = this.state.techniques.find(tech => tech.id === parseInt(this.state.currentTech));
        if (!newTech || this.state.bulletsTechniques.find(tech => tech.id === newTech.id)) return;

        this.setState(state => {
            const newArr = [...state.bulletsTechniques];
            newArr.push(newTech);
            return {
                bulletsTechniques: newArr,
                currentTag: '',
            };
        });
    }

    removeTech(index) {
        this.setState(state => {
            state.bulletsTechniques.splice(index,1);
            const arr = [...state.bulletsTechniques];
            return {
                bulletsTechniques: arr,
            };
        });
    }

    onPayloadChange(index, action, value) {
        this.setState(state => {
            const payload = state.payloads[index];

            if (!payload) return state;

            payload.errors = null;

            if (action === 'addTag') {
                let newTag = this.state.softwareTags.find(tag => tag.softwareName === value);
                if(newTag) {
                    newTag = { ...newTag };
                } else {
                    newTag = {
                        isNew: true,
                        softwareName: value
                    };
                }

                payload.softwareTags.push(newTag);
            } else if (action === 'removeTag') {
                payload.softwareTags.splice(value, 1);
            } else {
                payload[action] = value;
            }

            const newArray = [...state.payloads];

            return { payloads: newArray };
        });
    }

    addPayload() {
        this.setState(state => {
            const newArray = [...state.payloads];

            newArray.push({softwareTags: []});

            return {payloads: newArray};
        });
    }

    removePayload(index) {
        this.setState(state => {
            const newArray = [...state.payloads];
            newArray.splice(index, 1);

            return {payloads: newArray};
        })
    }

    onDefRuleChange(index, action, value) {
        this.setState(state => {
            const defRule = state.defRules[index];

            if(!defRule) return state;

            defRule.errors = null;

            if (action === 'edrName') {
                defRule.edrTag = this.state.edrTags.find(tag => tag.edrName === value);

                if (!defRule.edrTag) {
                    defRule.edrTag = {
                        isNew: true,
                        edrName: value
                    };
                }
            } else {
                defRule[action] = value;
            }

            const newArray = [...state.defRules];
            return { defRules: newArray };
        });

    }

    addDefRule() {
        this.setState(state => {
            const newArray = [...state.defRules];

            newArray.push({});

            return {defRules: newArray};
        });
    }

    removeDefRule(index) {
        this.setState(state => {
            const newArray = [...state.defRules];
            newArray.splice(index, 1);

            return {defRules: newArray};
        })
    }


    validateForm() {
        const errors = {};

        if(!this.state.id) {
            errors.idError = 'Id required';
        }

        if(!this.state.name) {
            errors.nameError = 'Name required';
        }

        if(!this.state.attackVector) {
            errors.attackVectorError = 'Attack vector required';
        }

        if(!this.state.status) {
            errors.statusError = 'Status required';
        }

        if(!this.state.creator) {
            errors.creatorError = 'Creator required';
        }

        if(!this.state.description) {
            errors.descriptionError = 'Description required';
        }

        if(!this.state.descriptionFile) {
            errors.descriptionFileError = 'Description file required';
        }

        if(!this.state.intelFile) {
            errors.intelFileError = 'Intelligence file required';
        }

        if(!this.state.instructionsFile) {
            errors.instructionsFileError = 'Instructions file required';
        }

        let payloadErrorExist = false;

        errors.payloads = this.state.payloads.map(payload => {
            const payloadErrors = {};

            if(!payload.name) {
                payloadErrors.name = 'Name required';
            }
    
            if(!payload.fileFormat) {
                payloadErrors.fileFormat = 'File format required';
            }
    
            if(!payload.file) {
                payloadErrors.file = 'File required';
            }

            if (Object.keys(payloadErrors).length > 0) {
                payloadErrorExist = true;
                payload.errors = payloadErrors;
            }

            return payload;
        });

        let defRuleErrorExist = false;

        errors.defRules = this.state.defRules.map(defRule => {
            const defRulesErrors = {};

            if(!defRule.name) {
                defRulesErrors.name = 'Name required';
            }
    
            if(!(defRule.edrTag && defRule.edrTag.edrName)) {
                defRulesErrors.edrTag = 'EDR tag required';
            }
    
            if(!defRule.file) {
                defRulesErrors.file = 'File required';
            }

            if (Object.keys(defRulesErrors).length > 0) {
                defRuleErrorExist = true;
                defRule.errors = defRulesErrors;
            }

            return defRule;
        });


        return {
            isValid: Object.keys(errors).length === 2 && !payloadErrorExist && !defRuleErrorExist,
            errors
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        const {isValid, errors} = this.validateForm();

        if (!isValid) {
            this.setState(errors);
            return;
        }
        
        // if (this.state.chosenEdrs != null) {
        //     const updatedChosenEdrs = {
        //         ...this.state.chosenEdrs,
        //         silverBulletId: this.state.id
        //     };
        //     sdk.bulletsEdrs.create(updatedChosenEdrs);
        //     this.setState({ chosenEdrs: updatedChosenEdrs });
        // }
        
        if (this.state.chosenPlatforms != null) {
            const updatedChosenPlatforms = {
                ...this.state.chosenPlatforms,
                silverBulletId: this.state.id
            };
            sdk.bulletPlatforms.create(updatedChosenPlatforms);
            this.setState({ chosenPlatforms: updatedChosenPlatforms });
        }
        

        const newBullet = {
            id: this.state.id,
            name: this.state.name,
            attackVector: this.state.attackVector,
            description: this.state.description,
            requireLocalAdmin: this.state.requireLocalAdmin,
            needForInternet: this.state.needForInternet,
            mitreTag: this.state.mitreTag,
            mitreUrl: this.state.mitreUrl,
            status: this.state.status,
            creator: this.state.creator,
        };

        this.setState({ creating: true });
        sdk.silverBullets.create(newBullet).then(bullet => {
            const promises = [
                sdk.silverBullets.uploadFile(this.state.descriptionFile, bullet.id, 'description'),
                sdk.silverBullets.uploadFile(this.state.intelFile, bullet.id, 'intel'),
                sdk.silverBullets.uploadFile(this.state.instructionsFile, bullet.id, 'instructions'),
            ];

            if(this.state.chartFile) {
                promises.push(sdk.silverBullets.uploadFile(this.state.chartFile, bullet.id, 'chart'));
            }

            this.state.bulletsTechniques.forEach(tech => {
                promises.push(sdk.silverBullets.relateTechnique(bullet.id, tech.id));
            });

            this.state.newAptTags.forEach(tag => {
                if(!tag.isNew) {
                    promises.push(sdk.silverBullets.relateAptTag(bullet.id, tag.id));
                } else {
                    promises.push(sdk.silverBullets.relateNewAptTag(bullet.id, tag.aptName));
                }
            });

            this.state.payloads.forEach(payload => {
                payload.fileName = payload.file.name;
                const payloadPromise = sdk.silverBullets.postPayload(bullet.id, payload)
                .then(p => {
                    const uploadPromise = sdk.payloads.uploadFile(payload.file, p.id);
                    const tagPromises = payload.softwareTags.map(tag => {
                        if(!tag.isNew) {
                            return sdk.payloads.relateSoftwareTag(p.id, tag.id);
                        } else {
                            return sdk.payloads.relateNewSoftwareTag(p.id, tag.softwareName);
                        }
                    });
                    return Promise.all([ uploadPromise, ...tagPromises ]);
                });
                promises.push(payloadPromise);
            });

            this.state.defRules.forEach(defRule => {
                let tagPromise;
                if (defRule.edrTag.isNew) {
                    tagPromise = sdk.edrTags.create({edrName: defRule.edrTag.edrName});
                } else {
                    tagPromise = Promise.resolve(defRule.edrTag);
                }

                const defRulePromise = tagPromise.then(edrTag => {
                    defRule.edrTagId = edrTag.id;
                    defRule.fileName = defRule.file.name;
                    return sdk.silverBullets.postDefRule(bullet.id, defRule);
                })
                .then(rule => sdk.defRules.uploadFile(defRule.file, rule.id));
                promises.push(defRulePromise);
            });
            return Promise.all(promises);
        }).then(() => {
            toast.success("Silver Bullet Create Successfully");
            setTimeout(() => {
                this.props.history.push('/silver-bullet-management');
            }, 2000); // 3000 milliseconds = 3 seconds
        }).catch(e => {
            this.setState({
                creating: false,
                uploadError: e.toString()
            });
        });
    }

    componentDidMount() {
        Promise.all([
            sdk.aptTags.get(),
            sdk.softwareTags.get(),
            sdk.edrTags.get(),
            sdk.techniques.get(),
            sdk.silverBullets.get()
        ]).then(tags => {
            //const edrs = tags[2];
            let defRules = [{}];
            //create the default rules
            // if (edrs.length > 0) {
            //     defRules = tags[2].map(edr => ({edrTag: edr}));
            // }

            if (tags[4].length !== 0){
                this.setState({
                    creating: false,
                    aptTags: tags[0],
                    softwareTags: tags[1],
                    edrTags: tags[2],
                    defRules,
                    techniques: tags[3],
                    id : (tags[4][tags[4].length - 1].id)+1,
                });
            }
            else {
                this.setState({
                    creating: false,
                    aptTags: tags[0],
                    softwareTags: tags[1],
                    edrTags: tags[2],
                    defRules,
                    techniques: tags[3],
                    id : 1,
                });
            }
        });
    }

    

    render() {

        return (
            <div>
                <ToastContainer />
                <Form onSubmit={this.handleSubmit}>
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            Create a Silver Bullet
                            <span className="float-right">
                                <Link to={`/silver-bullet-management`} onClick={e => e.stopPropagation()}>
                                    <Button className='btn-outline-danger' size='sm'>
                                        <i className='fas fa-times'/>
                                    </Button>
                                </Link>
                            </span>
                        </CardTitle>
                        <CardBody>
                            <Row>
                                <Col md='6'>
                                    <Row>
                                        <Col md='3'>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Id</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="number"
                                                        min="1"
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.idError}
                                                        onChange={e => this.setState({id: e.target.value, idError: undefined})}
                                                        placeholder={this.state.id}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.idError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md='5'>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Name</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.nameError}
                                                        onChange={e => this.setState({name: e.target.value, nameError: undefined})}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.nameError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md='4'>
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        type="select"
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.statusError}
                                                        onChange={e => this.setState({status: e.target.value, statusError: undefined})}
                                                        defaultValue="Select Status"
                                                    >
                                                        <option key='attackVector-placeholder' default disabled>Select Status</option>
                                                        <option>online</option>
                                                        <option>offline</option>
                                                    </Input>
                                                    <FormFeedback>
                                                        {this.state.statusError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='4'>
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        id = "tech"
                                                        type="select"
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.attackVectorError}
                                                        onChange={e => this.setState({attackVector: e.target.value, attackVectorError: undefined})}
                                                        defaultValue="Select Attack Vector"
                                                    >
                                                        <option key='attackVector-placeholder' default disabled>Select Attack Vector</option>
                                                        {attackVectors.map(vector => <option key={vector+'-option'}>{vector}</option>)}
                                                    </Input>
                                                    <FormFeedback>
                                                        {this.state.attackVectorError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <CustomInput
                                                className="mt-2"
                                                type="checkbox"
                                                id="internetCheckbox"
                                                label="Requires internet connection"
                                                onChange={() => this.setState(state => ({needForInternet: !state.needForInternet}))}
                                            />
                                        </Col>
                                        <Col md="4">
                                            <CustomInput
                                                className="mt-2"
                                                type="checkbox"
                                                id="localAdminCheckbox"
                                                label="Requires local admin"
                                                onChange={() => this.setState(state => ({requireLocalAdmin: !state.requireLocalAdmin}))}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="8">
                                        <CardTitle className="bg-light border-bottom p-3 mt-3">
                                            Select the relevant Platforms
                                        </CardTitle>
                                        <FormGroup>
                                            {Platforms.map(platform => 
                                                <div className="form-check form-check-inline" key={platform.name}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={platform.name}
                                                        label={platform.name}
                                                        onChange={e => {
                                                            const chosenPlatforms = {};
                                                            if (this.state.chosenPlatforms) {
                                                                Object.assign(chosenPlatforms, this.state.chosenPlatforms);
                                                            }

                                                            chosenPlatforms[platform.propName] = e.target.checked;
                                                            this.setState({ chosenPlatforms });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </FormGroup>
                                        </Col>

                                        <Col md="4">
                                            <CardTitle className="bg-light border-bottom p-3 mt-3">
                                                Creator
                                            </CardTitle>
                                            <FormGroup>
                                                <Input 
                                                    type="select"
                                                    className="form-control"
                                                    value={this.state.creator}
                                                    onChange={e => this.setState({ creator: e.target.value, creatorError: undefined})}
                                                    invalid={!!this.state.creatorError}
                                                >
                                                    <option value="">Select a Creator</option>
                                                    <option value="Hai Vaknin">Hai Vaknin</option>
                                                    <option value="Barak Sofir">Barak Sofir</option>
                                                    <option value="Uriel Kosayev">Uriel Kosayev</option>
                                                    <option value="Tamir Yehuda">Tamir Yehuda</option>
                                                    <option value="Naor Evgi">Naor Evgi</option>
                                                    <option value="Tzach Benita">Tzach Benita</option>
                                                    <option value="Nathaniel Licht">Nathaniel Licht</option>
                                                    <option value="Danel Yehuda">Danel Yehuda</option>
                                                </Input>
                                                <FormFeedback>
                                                    {this.state.creatorError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className='mb-0'>
                                                <InputGroup>
                                                    <Input
                                                        className="mt-3"
                                                        type="textarea"
                                                        rows="6"
                                                        placeholder="Write a description..."
                                                        disabled={this.state.creating}
                                                        invalid={!!this.state.descriptionError}
                                                        onChange={e => this.setState({description: e.target.value, descriptionError: undefined})}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.descriptionError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md='6'>
                                    <Row>
                                            <Col>
                                                <FormGroup>
                                                    <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Techniques</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input type="text" placeholder="Search Technique" onChange={e => this.setState({techniqueSearch: e.target.value.toLowerCase()}) && this.changeTech}/>
                                                    <Input
                                                        type="select"
                                                        // disabled={this.state.creating}
                                                        value={this.state.currentTech || undefined}
                                                        onChange={e => this.setState({currentTech: e.target.value})}
                                                        defaultValue="Select Technique"
                                                    >
                                                        <option key='technique-placeholder' default>Select Technique</option>
                                                        { 
                                                            this.state.techniques.filter(t => t.attackVector === this.state.attackVector && (t.name.toLowerCase().includes(this.state.techniqueSearch) || t.mitreId.toLowerCase().includes(this.state.techniqueSearch)))
                                                            .map(t => <option key={`technique-${t.id}-option`} value={t.id}>{t.mitreId} - {t.name}</option>)
                                                        }
                                                    </Input>
                                                    <InputGroupAddon addonType="append">
                                                        <Button onClick={this.addTech}>Add</Button>
                                                    </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TagContainer
                                                    title='Techniques'
                                                    tagColor='info'
                                                    tags={this.state.bulletsTechniques}
                                                    nameProp='name'
                                                    onRemove={index => this.removeTech(index)}
                                                />
                                            </Col>
                                        </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className='mt-3'>
                                                <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>APT tags</InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    list="aptTags"
                                                    placeholder="APT name"
                                                    value={this.state.currentTag}
                                                    disabled={this.state.creating}
                                                    onChange={e => this.setState({currentTag: e.target.value})}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button onClick={this.addAptTag}>Add</Button>
                                                </InputGroupAddon>
                                                <datalist id="aptTags">
                                                    {this.state.aptTags.map(tag => (
                                                        <option key={tag.aptName + '-option'}>{tag.aptName}</option>
                                                    ))}
                                                </datalist>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TagContainer
                                                title='APT tags'
                                                tags={this.state.newAptTags}
                                                nameProp='aptName'
                                                onRemove={index => this.removeAptTag(index)}
                                            />
                                        </Col>
                                    </Row>
                                    {/* here */}
                                    
                                    {/* <Row>
                                        <Col>
                                            <CardTitle className="bg-light border-bottom p-3 mt-3">
                                                Select the relevant Defense Systems
                                            </CardTitle>
                                            <FormGroup>
                                                {EDRs.map(edr => 
                                                    <div className="form-check form-check-inline" key={edr.name}>
                                                        <CustomInput
                                                            type="checkbox"
                                                            id={edr.name}
                                                            label={edr.name}
                                                            onChange={e => {
                                                                const chosenEdrs = {};
                                                                if (this.state.chosenEdrs) {
                                                                    Object.assign(chosenEdrs, this.state.chosenEdrs);
                                                                }

                                                                chosenEdrs[edr.propName] = e.target.checked ? true : null;
                                                                this.setState({ chosenEdrs });
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </FormGroup>  
                                        </Col>
                                    </Row> */}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col md='6'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Description HTML file</InputGroupText>
                                    </InputGroupAddon>
                                    <div className="custom-file">
                                    <Input
                                        type="file"
                                        className="custom-file-input"
                                        id="descriptionFileInput"
                                        disabled={this.state.uploading}
                                        onChange={(e) => this.setState({descriptionFile: e.target.files[0], descriptionFileError: undefined})}
                                    />
                                    <label
                                        className="custom-file-label"
                                        htmlFor="fileInput"
                                    >
                                        {this.state.descriptionFile? this.state.descriptionFile.name : "Choose file"}
                                    </label>
                                    </div>
                                </InputGroup>
                                <h6 className='text-danger font-light'>
                                    {this.state.descriptionFileError}
                                </h6>
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Intelligence HTML file</InputGroupText>
                                    </InputGroupAddon>
                                    <div className="custom-file">
                                    <Input
                                        type="file"
                                        className="custom-file-input"
                                        id="intelFileInput"
                                        disabled={this.state.uploading}
                                        onChange={(e) => this.setState({intelFile: e.target.files[0], intelFileError: undefined})}                                            
                                    />
                                    <label
                                        className="custom-file-label"
                                        htmlFor="fileInput"
                                    >
                                        {this.state.intelFile? this.state.intelFile.name : "Choose file"}
                                    </label>
                                    </div>
                                </InputGroup>
                                <h6 className='text-danger font-light'>
                                    {this.state.intelFileError}
                                </h6>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md='6'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Instructions HTML file</InputGroupText>
                                    </InputGroupAddon>
                                    <div className="custom-file">
                                    <Input
                                        type="file"
                                        className="custom-file-input"
                                        id="instructionsFileInput"
                                        disabled={this.state.uploading}
                                        onChange={(e) => this.setState({instructionsFile: e.target.files[0], instructionsFileError: undefined})}                                            
                                    />
                                    <label
                                        className="custom-file-label"
                                        htmlFor="fileInput"
                                    >
                                        {this.state.instructionsFile? this.state.instructionsFile.name : "Choose file"}
                                    </label>
                                    </div>
                                </InputGroup>
                                <h6 className='text-danger font-light'>
                                    {this.state.instructionsFileError}
                                </h6>
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>Chart png file</InputGroupText>
                                    </InputGroupAddon>
                                    <div className="custom-file">
                                    <Input
                                        type="file"
                                        className="custom-file-input"
                                        id="chartFileInput"
                                        disabled={this.state.uploading}
                                        onChange={(e) => this.setState({chartFile: e.target.files[0]})}                                            
                                    />
                                    <label
                                        className="custom-file-label"
                                        htmlFor="fileInput"
                                    >
                                        {this.state.chartFile? this.state.chartFile.name : "Choose file"}
                                    </label>
                                    </div>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.state.payloads.map((payload, i) =>
                        <PayloadUpload
                            key={`payload-${i}`}
                            index={i}
                            payload={payload}
                            disabled={this.state.creating}
                            onChange={this.onPayloadChange}
                            onRemove={this.removePayload}
                            softwareTags={this.state.softwareTags}
                        />
                    )}
                    {this.state.defRules.map((defRule, i) =>
                        <DefRuleUpload
                            key={`defRule-${i}`}
                            index={i}
                            defRule={defRule}
                            disabled={this.state.creating}
                            onChange={this.onDefRuleChange}
                            onRemove={this.removeDefRule}
                            edrTags={this.state.edrTags}
                            edrs={EDRs}
                        />
                    )}
                    <Row className="mt-2">
                        <Col xl={{ size: 2, offset: 4 }} lg={{ size: 4, offset: 2 }}  md='6' >
                            <FormGroup>
                                <Button color="danger" size="lg" block disabled={this.state.creating} onClick={this.addPayload}>
                                    Add payload variant
                                </Button>
                            </FormGroup>
                        </Col>
                        <Col xl='2' lg='4' md='6'>
                            <FormGroup>
                                <Button color="info" size="lg" block disabled={this.state.creating} onClick={this.addDefRule}>
                                    Add defence rule
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xl={{ size: 2, offset: 5 }} lg={{ size: 4, offset: 4 }} md='12'>
                            {this.state.uploadError?
                                <UncontrolledAlert className="mt-2 mb-0" color="danger">
                                    {this.state.uploadError}
                                </UncontrolledAlert>
                            : null}
                            <FormGroup>
                                <Button type="submit" color="secondary" size="lg" block disabled={this.state.creating}>
                                    {!this.state.creating? "Submit" : <i className="fas fa-spin fa-spinner"/>}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default BulletCreation;