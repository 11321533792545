import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Table,
} from 'reactstrap';
import sdk from 'api-sdk';
import user from '../../services/user';

import FreezeButton from "../../components/buttons/FreezeButton";
import DeleteButton from '../../components/buttons/deleteButton';
import SetPasswordButton from './setPasswordButton';
import ResetSecretButton from './resetSecretButton';
import CreateUserButton from './createUserButton';
import SetRoleButton from './setRoleButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class UserManagement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
        };
    }

    componentDidMount() {
        const userRoles = user.getRoles();
        const isRoot = userRoles.some(s => s === 'root' || s === 'wh-admin');
    
        let orgPromise;
        if (isRoot) {
            orgPromise = sdk.organizations.get();
        } else {
            orgPromise = sdk.organizations.getById(sdk.getOrganization()).then(org => [org]);
        }
    
        const userPromise = sdk.users.getUsers();
    
        Promise.all([orgPromise, userPromise])
            .then(([orgs, users]) => {
                // Fetch roles for each user
                const usersWithRolesPromises = users.map(user =>
                    sdk.users.getUserRoles(user.id).then(roles => ({
                        ...user,
                        roles: roles.join(", ") // Assuming roles is an array of strings
                    }))
                );
    
                return Promise.all([orgs, Promise.all(usersWithRolesPromises)]);
            })
            .then(([orgs, usersWithRoles]) => {
                this.setState({
                    orgs: orgs,
                    users: usersWithRoles
                });
            })
            .catch(error => {
                console.error("Error in fetching data:", error);
                // Handle errors here, such as setting an error state or showing a notification
            });
    }
    

    handleDelete(id, index) {
        return sdk.users.delete(id).then(res => {
            const users = this.state.users;
            users.splice(index, 1);
            this.setState({ users: users });
    
            // Display a success message
            toast.success(`User successfully deleted ❌.`);
        });
    }

    setRole(user, role) {
        return sdk.users.setRole(user, role)
            .then(() => {
                toast.success(`Role set successfully for ${user.email}`);
                return this.fetchUsersAndRoles();
            })
            .then(usersWithRoles => this.setState({ users: usersWithRoles }));
    }
    
    deleteRole(user, role) {
        return sdk.users.deleteRole(user, role)
            .then(() => {
                toast.success(`Role deleted successfully for ${user.email}`);
                return this.fetchUsersAndRoles();
            })
            .then(usersWithRoles => this.setState({ users: usersWithRoles }));
    }
    
    fetchUsersAndRoles() {
        return sdk.users.getUsers().then(users => {
            const usersWithRolesPromises = users.map(user =>
                sdk.users.getUserRoles(user.id).then(roles => ({
                    ...user,
                    roles: roles.join(", ")
                }))
            );
            return Promise.all(usersWithRolesPromises);
        });
    }
    

    handleFreeze(user, index) {
        return sdk.users.freeze(user).then(res => {
            const users = this.state.users;
            users[index] = res;
            this.setState({ users: users });
            // Check if the user is frozen or unfrozen and display the appropriate message
            const freezeStatus = res.freeze ? 'frozen' : 'unfrozen';
            toast.info(`User ${user.email} has been successfully ${freezeStatus}❄️.`);
            return this.fetchUsersAndRoles();
        }).then(usersWithRoles => this.setState({ users: usersWithRoles }));
    }

    createUser(email, password, organizationId) {
        const newUser = {
            email,
            emailVerified: false,
            password,
            organizationId,
        };
        return sdk.users.add(newUser).then(res => {
            const users = this.state.users;
            users.push({email,organizationId})
            this.setState({users});
        });
    }

    render() {
        const userRoles = user.getRoles();
        const isRoot = userRoles.some( s => s === 'root' || s === 'wh-admin');
        const isSupport = userRoles.some( s => s === 'support');
        const orgMap = {};
        if(this.state.orgs) {
            this.state.orgs.forEach(org => {
                orgMap[org.id] = org.name;
            });
        }
        return (
        <div>
        <Card>
            <CardTitle className="bg-light border-bottom p-3 mb-0">
                    User management
                    <span className="float-right"><CreateUserButton  isRoot={isRoot} organizations={this.state.orgs} createFunc={this.createUser.bind(this)}/></span>
            </CardTitle>
            <CardBody className='p-0'>
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Organization</th>
                            {user.isRoot() || isSupport ?
                                <>
                                    <th>Set password</th>
                                    <th>Reset secret</th>
                                </>
                            : null}
                            <th>Roles</th>
                            {user.isRoot() ?  <th>Set role</th>  : null}
                            {user.isRoot() ?  <th>Delete role</th>  : null}
                            <th>Freeze user</th>
                            <th>Delete user</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users.map((u, index) =>
                            <tr key={u.email}>
                                <td>{u.email}</td>
                                <td>{orgMap[u.organizationId]}</td>
                                {user.isRoot() || isSupport?
                                    <>
                                        <td>
                                            <SetPasswordButton user={u}/>
                                        </td>
                                        <td>
                                            <ResetSecretButton user={u}/>
                                        </td>
                                    </>
                                : null}
                                <td>{u.roles}</td>
                                {u.email !== "root@whitehat.com" && user.isRoot() ? <td> <SetRoleButton delete={false} setRoleFunc={(role) => this.setRole(u,role)}/> </td>: null}
                                {u.email !== "root@whitehat.com" && user.isRoot() ? <td> <SetRoleButton delete={true} setRoleFunc={(role) => this.deleteRole(u,role)}/> </td>: null}

                                <td>
                                    {u.email !== "root@whitehat.com" ? <FreezeButton user={u} item='user' freezeFunc={() => this.handleFreeze(u, index)}/> : null}  
                                </td>
                                <td>
                                    {u.email !== "root@whitehat.com" ? <DeleteButton item='user' deleteFunc={() => this.handleDelete(u.id, index)}/> : null}
                                </td>
                            </tr>
                        )}
                    </tbody>
                    
                </Table>
            </CardBody>
        </Card>
        </div>
        );
    }
}

export default UserManagement;