import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Nav,
  Collapse,
  Button
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import user from '../../services/user';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.expandLogo = this.expandLogo.bind(this);
    this.activeRoute.bind(this);
    this.state = {
      dropdownOpen: false
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  /*--------------------------------------------------------------------------------*/
  /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
  /*--------------------------------------------------------------------------------*/
  expandLogo() {
    document.getElementById('logobg').classList.toggle('expand-logo');
  }
  /*--------------------------------------------------------------------------------*/
  /*Verifies if routeName is the one active (in browser input)                      */
  /*--------------------------------------------------------------------------------*/
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
      ? 'selected'
      : '';
  }

  render() {
    const userRoles = user.getRoles();
    const isAdmin = userRoles.some(s => s === 'org-admin');
    const isSupport = userRoles.some(s => s === 'support');
    const isUploader = userRoles.some(s => s === 'uploader');
    const isRoot = user.isRoot();
    return (
      <aside
        className="left-sidebar"
        id="sidebarbg"
        data-sidebarbg={this.props.data.settings[0].sidebarbg}
        onMouseEnter={this.expandLogo}
        onMouseLeave={this.expandLogo}
      >
        <div className="scroll-sidebar">
          <PerfectScrollbar className="sidebar-nav">
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar Menus will go here                                                     */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav id="sidebarnav">
              {this.props.routes.map((prop, key) => {
                if (prop.redirect) {
                  return null;
                } else if (prop.navlabel && !prop.hide && ((!prop.adminOnly && !prop.rootOnly) || (prop.adminOnly && isAdmin) || (prop.rootOnly && isRoot))) {
                  return (
                    <li className="nav-small-cap" key={key}>
                      <i className={prop.icon} />
                      <span className="hide-menu">{prop.name}</span>
                    </li>
                  );
                } else if (prop.collapse && !prop.hide && ((!prop.adminOnly && !prop.rootOnly) || (prop.adminOnly && isAdmin) || (prop.rootOnly && isRoot))) {
                  let firstdd = {};
                  firstdd[prop['state']] = !this.state[prop.state];
                  return (
                    /*--------------------------------------------------------------------------------*/
                    /* Menus wiil be goes here                                                        */
                    /*--------------------------------------------------------------------------------*/
                    <li
                      className={this.activeRoute(prop.path) + ' sidebar-item'}
                      key={key}
                    >
                      <Button
                        data-toggle="collapse"
                        className="btn-link sidebar-link has-arrow"
                        aria-expanded={this.state[prop.state]}
                        onClick={() => this.setState(firstdd)}
                      >
                        <i className={prop.icon} />
                        <span className="hide-menu">{prop.name}</span>
                      </Button>
                      {/*--------------------------------------------------------------------------------*/}
                      {/* Sub-Menus wiil be goes here                                                    */}
                      {/*--------------------------------------------------------------------------------*/}
                      <Collapse isOpen={this.state[prop.state]}>
                        <ul className="first-level">
                          {prop.child.map((prop, key) => {
                            if (prop.redirect && !prop.adminOnly && !prop.hide) return null;
                            if (prop.collapse && !prop.adminOnly && !prop.hide) {
                              let seconddd = {};
                              seconddd[prop['state']] = !this.state[prop.state];
                              return (
                                <li
                                  className={
                                    this.activeRoute(prop.path) +
                                    ' sidebar-item'
                                  }
                                  key={key}
                                >
                                  <Button
                                    data-toggle="collapse"
                                    className="btn-link sidebar-link has-arrow"
                                    aria-expanded={this.state[prop.state]}
                                    onClick={() => this.setState(seconddd)}
                                  >
                                    <i className={prop.icon} />
                                    <span className="hide-menu">
                                      {prop.name}
                                    </span>
                                  </Button>
                                  {/*--------------------------------------------------------------------------------*/}
                                  {/* Sub-Menus wiil be goes here                                                    */}
                                  {/*--------------------------------------------------------------------------------*/}
                                  <Collapse isOpen={this.state[prop.state]}>
                                    <ul className="second-level">
                                      {prop.subchild.map((prop, key) => {
                                        if (prop.redirect && !prop.adminOnly && !prop.hide) return null;
                                        return (
                                          <li
                                            className={
                                              this.activeRoute(prop.path) +
                                              ' sidebar-item'
                                            }
                                            key={key}
                                          >
                                            <NavLink
                                              to={prop.path}
                                              activeClassName="active"
                                              className="sidebar-link"
                                            >
                                              <i className={prop.icon} />
                                              <span className="hide-menu">
                                                {prop.name}
                                              </span>
                                            </NavLink>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </Collapse>
                                </li>
                              );
                            }
                            return (
                              /*--------------------------------------------------------------------------------*/
                              /* Adding Sidebar Item                                                            */
                              /*--------------------------------------------------------------------------------*/
                              <li
                                className={
                                  this.activeRoute(prop.path) +
                                  (prop.pro ? ' active active-pro' : '') +
                                  ' sidebar-item'
                                }
                                key={key}
                              >
                                <NavLink
                                  to={prop.path}
                                  className="sidebar-link"
                                  activeClassName="active"
                                >
                                  <i className={prop.icon} />
                                  <span className="hide-menu">{prop.name}</span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  );
                }
                else if ((!prop.adminOnly && !prop.rootOnly && !prop.hide) || (prop.adminOnly && isAdmin) || (prop.rootOnly && isRoot) || (prop.supportOnly && isSupport) || (prop.uploaderOnly && isUploader)) {
                  return (
                    /*--------------------------------------------------------------------------------*/
                    /* Adding Sidebar Item                                                            */
                    /*--------------------------------------------------------------------------------*/
                    <li
                      className={
                        this.activeRoute(prop.path) +
                        (prop.pro ? ' active active-pro' : '') +
                        ' sidebar-item'
                      }
                      key={key}
                    >
                      <NavLink
                        to={prop.path}
                        className="sidebar-link"
                        activeClassName="active"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <i className={prop.icon} />
                        <span className="hide-menu" style={{ borderLeft: `5px solid ${prop.hexColor ? prop.hexColor : 'defaultBorderColor'}`, paddingLeft: '10px' }}>
                          {prop.name}
                        </span>
                      </NavLink>

                    </li>
                  );
                }
                return null;
              })}
            </Nav>
          </PerfectScrollbar>
        </div>
      </aside>
    );
  }
}
export default Sidebar;
