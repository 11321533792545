import React from 'react';
import { Redirect } from 'react-router-dom';
import user from '../../services/user';
import InitialLogin from './initialLogin';
import SecretCreation from './secretCreation';
import TokenVerification from './tokenVerification';
import img2 from '../../assets/images/background/login.jpg';
import './login-box.css';



const sidebarBackground = {
    backgroundImage: "url(" + img2 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
};

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.determineLoginState = this.determineLoginState.bind(this);
    }

    determineLoginState() {
        new Promise((resolve, reject) => {
            if (user.isLoggedIn()) {
                resolve('loggedIn');
            } else if (user.hasToken()) {
                user.hasSecret().then(hasSecret => {
                    resolve(hasSecret? 'verifyToken' : 'createSecret');
                }).catch(e => user.logout());
            } else {
                resolve('initialLogin');
            }
        }).then(loginState => {
            this.setState({ loginState: loginState });
        });
    }

    componentDidMount() {
        this.determineLoginState();
    }

    render() {
        let loginComponent;
        switch (this.state.loginState) {
            case 'loggedIn':
                const { from } = this.props.location.state || { from: { pathname: '/' } };
                loginComponent = <Redirect to={from}/>;
                break;
            case 'initialLogin':
                loginComponent = <InitialLogin updateFunc={this.determineLoginState}/>;
                break;
            case 'verifyToken':
                loginComponent = <TokenVerification updateFunc={this.determineLoginState}/>;
                break;
            case 'createSecret':
                loginComponent = <SecretCreation updateFunc={this.determineLoginState}/>;
            break;
            default:
                break;
        }
        return <div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
            {loginComponent}
        </div>
    }
}

export default Login;