import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    CustomInput,
    Form,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    FormFeedback,
    UncontrolledAlert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import sdk from 'api-sdk';
import vectors from '../../assets/data/attackVectors.json';

class TechniqueEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            creating: !!props.match.params.techniqueId,
            id: undefined,
            mitreId: undefined,
            name: undefined,
            isSubTechnique: false,
            parentMitreId: undefined,
            nameError: undefined,
            attackVectors: {},
            attackVectorsError: undefined,
            description: undefined,
            descriptionError: undefined,
        };

        this.validateForm = this.validateForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateForm() {
        const errors = {};

        if(!this.state.name) {
            errors.nameError = 'Name required';
        }

        if (this.state.isSubTechnique && !this.state.parentMitreId) {
            errors.parentError = 'Parent Mitre Id is required'
        }

        if(!this.state.isSubTechnique && Object.values(this.state.attackVectors).indexOf(true) === -1 && !this.props.match.params.techniqueId) {
            errors.attackVectorsError = 'At least one Attack vector is required';
        }

        return {
            isValid: Object.keys(errors).length === 0,
            errors
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        const {isValid, errors} = this.validateForm();
        
        if(!isValid) {
            this.setState(errors);
            return;
        }

        this.setState({ creating: true });

        const techniqueId = this.props.match.params.techniqueId;
        let prm;

        if (!techniqueId) {
            if (!this.state.isSubTechnique) {
                const newTechniques = Object.entries(this.state.attackVectors).filter(entry => entry[1]).map(([vector, value]) => ({
                    name: this.state.name,
                    mitreId: this.state.mitreId,
                    attackVector: vector,
                    description: this.state.description,
                }));
    
                prm = Promise.all(newTechniques.map(t => sdk.techniques.create(t)));
            } else {
                prm = sdk.techniques.get({where: {'mitreId': this.state.parentMitreId}}).then(parents => {
                    if (parents.length > 0) {
                        const newTechniques = parents.map(parent => ({
                            name: this.state.name,
                            mitreId: this.state.mitreId,
                            attackVector: parent.attackVector,
                            description: this.state.description,
                            isSubTechnique: true,
                            subTechniqueOfId: parent.id
                        }));

                        return Promise.all(newTechniques.map(t => sdk.techniques.create(t)));
                    } else {
                        throw new Error('Parent technique was not found');
                    }
                });
            }
        } else {
            const newTechnique = {
                id: this.state.id,
                name: this.state.name,
                mitreId: this.state.mitreId,
                attackVector: this.state.attackVector,
                description: this.state.description,
            };

            prm = sdk.techniques.update(techniqueId, newTechnique);
        }

        prm.then(() => {
            this.props.history.push('/technique-management');
        }).catch(e => {
            this.setState({
                creating: false,
                uploadError: e.toString()
            });
        });
    }

    componentDidMount() {
        const techniqueId = this.props.match.params.techniqueId;
        if (techniqueId) {
            sdk.techniques.getById(techniqueId, {include: 'subTechniqueOf'}).then(t => {
                this.setState({
                    creating: false,
                    name: t.name,
                    mitreId: t.mitreId,
                    attackVector: t.attackVector,
                    description: t.description,
                    isSubTechnique: t.isSubTechnique,
                    parentTech: t.subTechniqueOf
                });
            });
        }
    }

    render() {
        const techniqueId = this.props.match.params.techniqueId;
        if (techniqueId && !this.state.name) {
            return (
                <Row>
                    <Col>
                        <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
                    </Col>
                </Row>
            );
        }

        return (
            <Form onSubmit={this.handleSubmit}>
                <Card>
                    <CardTitle className="bg-light border-bottom p-3 mb-0">
                        {techniqueId? 'Edit' : 'Create'} a Technique
                        <span className="float-right">
                            <Link to={`/technique-management`} onClick={e => e.stopPropagation()}>
                                <Button className='btn-outline-danger' size='sm'>
                                    <i className='fas fa-times'/>
                                </Button>
                            </Link>
                        </span>
                    </CardTitle>
                    <CardBody>
                        <Row>
                            <Col md='6'>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Name</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="text"
                                            value={this.state.name}
                                            disabled={this.state.creating}
                                            invalid={!!this.state.nameError}
                                            onChange={e => this.setState({name: e.target.value, nameError: undefined})}
                                        />
                                        <FormFeedback>
                                            {this.state.nameError}
                                        </FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md='6'>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Mitre Id</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="text"
                                            disabled={this.state.creating}
                                            value={this.state.mitreId}
                                            onChange={e => this.setState({mitreId: e.target.value})}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className='mb-0'>
                                    <InputGroup>
                                        <Input
                                            type="textarea"
                                            value={this.state.description}
                                            rows="4"
                                            placeholder="Write a description..."
                                            disabled={this.state.creating}
                                            invalid={!!this.state.descriptionError}
                                            onChange={e => this.setState({description: e.target.value, descriptionError: undefined})}
                                        />
                                        <FormFeedback>
                                            {this.state.descriptionError}
                                        </FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CustomInput
                                    className='mt-4 ml-2'
                                    type="checkbox"
                                    id='isSubTech'
                                    label='Sub Technique'
                                    disabled={this.state.creating || techniqueId}
                                    checked={this.state.isSubTechnique}
                                    onChange={e => {
                                        this.setState(state => ({ isSubTechnique: !state.isSubTechnique }));
                                    }}
                                />
                                {techniqueId && this.state.isSubTechnique && this.state.parentTech?
                                    <div>
                                        <h5 className='mt-4 ml-2'>Parent Technique Id: {this.state.parentTech.id}</h5>
                                        <h5 className='mt-4 ml-2'>Parent Technique Mitre ID: {this.state.parentTech.mitreId}</h5>
                                        <h5 className='mt-4 ml-2'>Parent Technique Name: {this.state.parentTech.name}</h5>
                                    </div>
                                : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={this.state.isSubTechnique? '4' : '12'} md='12'>
                                {techniqueId || !this.state.isSubTechnique?
                                    <h5 className='mt-4 ml-2'>Attack Vector{!techniqueId? 's': `: ${this.state.attackVector}`}</h5>
                                : null}
                                {!techniqueId && !this.state.isSubTechnique?
                                    <FormGroup>
                                        {vectors.filter(v => !v.hide).map(v => 
                                            <div key={`checkbox-${v.propName}`} className="ml-3 mb-2 form-check form-check-inline">
                                                <CustomInput
                                                    type="checkbox"
                                                    id={v.propName}
                                                    label={v.title}
                                                    invalid={!!this.state.attackVectorsError}
                                                    onChange={e => {
                                                        this.setState(state => {
                                                            const newVectors = { ...state.attackVectors };

                                                            newVectors[v.title] = e.target.checked;

                                                            return { attackVectors: newVectors, attackVectorsError: null };
                                                        });
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <FormText className='ml-3'>At least one Attack vector is required</FormText>
                                    </FormGroup>
                                : null}
                                {!techniqueId && this.state.isSubTechnique?
                                    <FormGroup className='mt-4'>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Mitre Id of Parent Technique</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                type="text"
                                                value={techniqueId? this.state.parentTech.mitreId : this.state.parentMitreId}
                                                disabled={this.state.creating || techniqueId}
                                                invalid={!!this.state.parentError}
                                                onChange={e => this.setState({parentMitreId: e.target.value, parentError: undefined})}
                                            />
                                            <FormFeedback>
                                                {this.state.parentError}
                                            </FormFeedback>
                                        </InputGroup>
                                    </FormGroup>
                                : null}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row className="mt-2">
                    <Col xl={{ size: 2, offset: 5 }} lg={{ size: 4, offset: 4 }} md='12'>
                        {this.state.uploadError?
                            <UncontrolledAlert className="mt-2 mb-0" color="danger">
                                {this.state.uploadError}
                            </UncontrolledAlert>
                        : null}
                        <FormGroup>
                            <Button type="submit" color="secondary" size="lg" block disabled={this.state.creating}>
                                {!this.state.creating? "Submit" : <i className="fas fa-spin fa-spinner"/>}
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default TechniqueEditor;