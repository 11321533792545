import React from 'react';
import { Button, Modal } from 'react-bootstrap';

class PermissionPopup extends React.Component {
    handleClose = () => {
      if (this.props.onClose) {
        this.props.onClose(); // call the onClose prop function when handleClose is called
      }
    };
  
    render() {
      return (
        <div>
          {this.props.isOpen && ( // Conditionally render the PermissionPopup component
            <Modal show={this.props.isOpen} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Permission Error</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>You don't have permission for this action.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      );
    }
  }
  
  export default PermissionPopup;
  