import React  from 'react';
import sdk from 'api-sdk';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';
import userService from '../../services/user';

class SelectOrganization extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            org: null,
            organizations: []
        }

        this.changeOrganization = this.changeOrganization.bind(this);
    }

    changeOrganization(orgId) {
        sdk.setOrganization(orgId);
        this.setState(
            {org: orgId},
            () => this.props.history.replace('/')
        );
    }

    componentDidMount() {
        const userRoles = userService.getRoles();
        const isAdmin = userRoles.some( s => s === 'root' || s === 'wh-admin');
        if(!isAdmin) return;
        
        sdk.organizations.get()
        .then(organizations => this.setState({
            organizations,
            org: sdk.getOrganization()
        }));
    }

    render() {
        const userRoles = userService.getRoles();
        const isAdmin = userRoles.some( s => s === 'root' || s === 'wh-admin');
        if(!isAdmin) {
            return null;
        }
        const currOrg = this.state.organizations.find(org => org.id === this.state.org)
        return (
            <UncontrolledDropdown className='mt-3'>
                <DropdownToggle className='btn-outline-secondary' disabled={!this.state.org || this.state.organizations.length === 0} caret>
                    Organization: {this.state.org? currOrg.name : <i className='fas fa-spin fa-spinner'/>}
                </DropdownToggle>
                <DropdownMenu>
                    {this.state.organizations.map( org => (
                        <DropdownItem
                            onClick={() => this.changeOrganization(org.id)}
                            active={this.state.org === org.id}
                            key={org.id}
                        >
                            {org.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}

export default SelectOrganization;