import React from "react";
import { Route, Switch } from 'react-router-dom';
import BulletMenu from './bulletMenu';


class SilverBullets extends React.Component {

    render() {
        return(
            <Switch>
                <Route path={`${this.props.match.path}/:attackVector`} exact component={BulletMenu} />
                <Route path={`${this.props.match.path}/:attackVector/technique/:techniqueId`} exact component={BulletMenu} />
            </Switch>
        );
    }
}

export default SilverBullets;