import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    InputGroup,
} from 'reactstrap';
import sdk from 'api-sdk';
import user from '../../services/user'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            newPassword: '',
            oldPassword: '',
            isValid: false,
            feedBack: '',
            isLoggedIn: !(user.getUserData().hasLoggedIn),
            user: user.getUserData(),
            token: localStorage.getItem("WhiteHatSuiteUser")
        };

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTriggerChange = this.handleTriggerChange.bind(this);
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
          this.handleSubmit(event)
          event.preventDefault();
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        sdk.users.changePassword(this.state.oldPassword, this.state.newPassword).then(res => {
            sdk.users.updateLoginState(this.state.user);
            this.setState({
                isValid: true,
                feedBack: 'Password has been changed'
            });
            toast.success("Password successfully changed!", {
                position: toast.POSITION.TOP_CENTER
            });
        }).catch(err => {
            this.setState({
                isValid: false,
                feedBack: err.response.data.error.message
            });
        });
    }
    

    handleTriggerChange(){
        this.setState({ isLoggedIn: false });
    }

    render() {
        return (
            <div>
                <ToastContainer />
                <Card>
                    <CardTitle className="bg-light border-bottom p-3 mb-0">
                        Change Password
                    </CardTitle>
                    <CardBody>
                        <Form onSubmit={this.handleSubmit}>
                            <Col md={{ size: 10, offset: 1 }}>
                                <FormGroup>
                                    <InputGroup>
                                        <Input
                                            placeholder="Current Password"
                                            type="password"
                                            onChange={(e) => this.setState({oldPassword : e.target.value})}
                                            valid={(!!this.state.feedBack && this.state.isValid)}
                                            invalid={(!!this.state.feedBack  && !this.state.isValid)}
                                            required
                                        />
                                        <FormFeedback>
                                            {this.state.feedBack}
                                        </FormFeedback>
                                    </InputGroup>
                                <FormGroup>
                                </FormGroup>
                                    <InputGroup>
                                        <Input
                                            placeholder="New Password"
                                            type="password"
                                            onChange={(e) => this.setState({newPassword : e.target.value})}
                                            valid={(!!this.state.feedBack && this.state.isValid)}
                                            invalid={(!!this.state.feedBack  && !this.state.isValid)}
                                            required
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Button type="submit" disabled={!this.state.newPassword || !this.state.oldPassword}>
                                        Set
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Form>
                    </CardBody>
                    {/* <Popup trigger={this.state.isLoggedIn} handleTriggerChange={this.handleTriggerChange}/> */}
                </Card>
            </div>
        );
    }
}

export default ChangePassword;