import { request } from './client';
var model = 'Organizations';
var organizations = {
  get: function get() {
    return request('get', "/".concat(model));
  },
  getById: function getById(id, filter) {
    return request('get', "/".concat(model, "/").concat(id), null, {
      filter: filter
    });
  },
  getName: function getName() {
    return request('get', "/".concat(model, "/getName"));
  },
  create: function create(name) {
    return request('post', "/".concat(model), {
      name: name
    });
  },
  updateConfiguration: function updateConfiguration(id, config) {
    return request('put', "/".concat(model, "/").concat(id, "/organizationConfiguration"), config);
  },
  setAdmin: function setAdmin(orgId) {
    var userId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var email = arguments.length > 2 ? arguments[2] : undefined;
    return request('post', "/".concat(model, "/").concat(orgId, "/setAdmin"), null, {
      userId: userId,
      email: email
    });
  },
  freeze: function freeze(organization) {
    return request('put', "/".concat(model, "/freeze"), organization);
  }
};
export default organizations;