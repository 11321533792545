import { request, requestDownload } from './client';
var model = 'eye-of-the-enemy/Payloads';
var payloads = {
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  getById: function getById(id, filter) {
    return request('get', "/".concat(model, "/").concat(id), null, {
      filter: filter
    });
  },
  create: function create(payload) {
    return request('post', "/".concat(model), payload);
  },
  update: function update(id, bullet) {
    return request('patch', "/".concat(model, "/").concat(id), bullet);
  },
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  },
  download: function download(id) {
    return requestDownload("/".concat(model, "/").concat(id, "/download"));
  },
  uploadFile: function uploadFile(file, id) {
    // eslint-disable-next-line no-undef
    var formData = new FormData();
    formData.append('file', file);
    var config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return request('post', "/".concat(model, "/").concat(id, "/upload"), formData, config);
  },
  relateSoftwareTag: function relateSoftwareTag(id, tagId) {
    return request('put', "/".concat(model, "/").concat(id, "/softwareTags/rel/").concat(tagId));
  },
  relateNewSoftwareTag: function relateNewSoftwareTag(id, softwareName) {
    return request('post', "/".concat(model, "/").concat(id, "/softwareTags"), {
      softwareName: softwareName
    });
  },
  deleteSoftwareTagRelation: function deleteSoftwareTagRelation(id, tagId) {
    return request('delete', "/".concat(model, "/").concat(id, "/softwareTags/rel/").concat(tagId));
  }
};
export default payloads;