import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import sdk from 'api-sdk';

const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];

class SetConfigButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            config: {}
        };

        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleChange(propName, value) {
        this.setState(state => {
            const newConfig = {...state.config};
            newConfig[propName] = value;
            return { config: newConfig };
        });
    }

    handleUpdate() {
        this.setState({ updating: true });
        sdk.organizations.updateConfiguration(this.props.orgId, this.state.config)
        .then(() => this.setState({
            config: {},
            updating: false,
            modal: false
        }));
    }

    render() {
        return(
            <div>
                <Button color='secondary' size='sm' onClick={this.toggle}>
                    Set Configuration
                </Button>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className='modal-dialog-centered'
                  data-theme='dark'
                >
                <ModalHeader toggle={this.toggle}>Set Configuration</ModalHeader>
                <ModalBody>
                    <Form onSubmit={e => e.preventDefault()}>
                        <FormGroup>
                            First day of the week
                            <InputGroup>  
                                <Input
                                    type="select"
                                    value={this.state.config.firstDayOfTheWeek}
                                    onChange={e => this.handleChange('firstDayOfTheWeek', e.target.value)}
                                >
                                    {weekdays.map((day, index) => (
                                        <option key={day} value={index}>{day}</option>
                                    ))}
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button color="info" onClick={this.handleUpdate} disabled={this.state.updating}>
                    Set new configuration
                </Button>{' '}
                <Button color="secondary" onClick={this.toggle}>
                    Cancel
                </Button>
                </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default SetConfigButton;