import React from 'react';
import {
    Badge,
    Button,
    Jumbotron,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from 'reactstrap';
import sdk from 'api-sdk';
import user from '../../../services/user';
import SendModal from './SendModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './payloadModel.css'

class PayloadViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payloadContent: '',
        };
    }

    componentDidMount() {
        this.props.getData().then(arrayBuffer => {
            const decoder = new TextDecoder('utf-8');
            const text = decoder.decode(arrayBuffer);
            this.setState({ payloadContent: text });
            // Set the content in the parent component for the copy functionality
            this.props.setContentForCopy(text);
        });
    }

    render() {
        if (!this.state.payloadContent) {
            return <h1 className='text-center'><i className='fas fa-spin fa-spinner' /></h1>;
        }

        return (
            <pre className="payload-content"> {/* Apply the custom CSS class here */}
                {this.state.payloadContent}
            </pre>
        );
    }

}



class PayloadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payloads: null,
            selectedPayload: null,
            selectedPreviewPayload: null,
            user: null,
            roles: [],
            contentToCopy: '',
        };

        this.getPayloads = this.getPayloads.bind(this);
        this.toggleSendModal = this.toggleSendModal.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.setContentForCopy = this.setContentForCopy.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    getPayloads(id) {
        sdk.silverBullets.getPayloads(id, { include: 'softwareTags' })
            .then(payloads => this.setState({ payloads }));
    }

    componentDidMount() {
        if (this.props.bullet) {
            this.getPayloads(this.props.bullet.id);
            const currentUser = user.getUserData();
            const roles = user.getRoles();
            this.setState({ user: currentUser, roles: roles });
        }
    }
    

    componentDidUpdate(prevProps) {
        if (this.props.bullet && prevProps.bullet && this.props.bullet.id !== prevProps.bullet.id) {
            this.getPayloads(this.props.bullet.id);
        } else if (this.props.bullet && !prevProps.bullet) {
            this.getPayloads(this.props.bullet.id);
        }
    }
    
    

    toggleSendModal(payload) {
        this.setState(prevState => ({
            showSendModal: !prevState.showSendModal,
            selectedPayload: payload || null,
        }));
    }

    handleClose = () => {
        // Reset the selected preview payload
        this.setState({ selectedPreviewPayload: null }, () => {
            // After state reset, call the toggle to close the modal
            this.props.toggle();
        });
    }

    handleSend(email, encoding) {
        const { selectedPayload } = this.state;
        const { bullet } = this.props;

        if (selectedPayload) {
            let sendPayloadPromise;

            switch (encoding) {
                case 'Base64':
                    sendPayloadPromise = sdk.silverBullets.sendBase64PayloadEmail(bullet.id, selectedPayload.id, email, encoding);
                    break;
                // case 'certutil':
                //     // Assuming there's a method in sdk for sending with certutil encoding
                //     sendPayloadPromise = sdk.silverBullets.sendCertutilPayloadEmail(bullet.id, selectedPayload.id, email, encoding);
                //     break;
                case 'Bytes':
                    // Assuming there's a method in sdk for sending with Bytes encoding
                    sendPayloadPromise = sdk.silverBullets.sendBytesPayloadEmail(bullet.id, selectedPayload.id, email, encoding);
                    break;
                default:
                    sendPayloadPromise = Promise.reject(new Error('Unsupported encoding type'));
            }

            sendPayloadPromise.then(() => {
                this.props.updateFunc(bullet.id);
                this.setState({ message: 'File sent', sendDisabled: false, showSendModal: false });
                toast.success("Mail sent successfully 📧"); // Display success toast
            }).catch(err => {
                console.error('Error sending payload:', err);
                this.setState({ message: 'Something went wrong', sendDisabled: false, showSendModal: false });
                toast.error("Failed to send mail");
            });
        }
    }

    setContentForCopy(content) {
        this.setState({ contentToCopy: content });
    }

    async copyToClipboard() {
        try {
            await navigator.clipboard.writeText(this.state.contentToCopy);
            toast.success("Content copied to clipboard");
        } catch (err) {
            console.error('Failed to copy:', err);
            toast.error("Failed to copy content");
        }
    }

    render() {
        const { payloads, selectedPayload, roles, showSendModal, selectedPreviewPayload } = this.state;
        const canSend = roles && (roles.includes('root') || roles.includes('uploader'));
        const { bullet, isOpen, toggle,fromDashBoard } = this.props;
    
        return (
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                className={selectedPreviewPayload ? 'modal-dialog-centered modal-wide' : 'modal-dialog-centered'}
                data-theme='dark'
            >
                <ModalHeader toggle={this.handleClose}>
                    {!selectedPayload ? 'Choose Payload' : 'Payload Preview'}
                </ModalHeader>
                {!bullet || !payloads ?
                    <h1 className='text-center'><i className='fas fa-spin fa-spinner' /></h1>
                    : (!selectedPreviewPayload ?
                        <ModalBody className='modal-menu'>
                            {payloads.map(payload => (
                                <Jumbotron className='menu-entry' key={`payloads-${payload.id}`}>
                                    <div className='info-container'>
                                        <div className='title-row title-test'>
                                            <h3 className='mr-2'>{payload.name}</h3>
                                            <Badge className='badge-tag' color="secondary" pill>
                                                Format: {payload.fileFormat}
                                            </Badge>
                                        </div>
                                        {payload.softwareTags.length > 0 && (
                                            <div className='tags-row'>
                                                {payload.softwareTags.map(tag => (
                                                    <Badge className='pl-2 pr-2 ml-1 mr-1 mt-1' color="danger" key={`sw-tag-${tag.id}`}>
                                                        {tag.softwareName}
                                                    </Badge>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div className='button-container'>
                                        <Button type="button" onClick={() => this.setState({ selectedPreviewPayload: payload })}>
                                            Preview
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                sdk.silverBullets.downloadPayload(bullet.id, payload.id, payload.fileName)
                                                    .then(() => {
                                                        // Call the setPayloadDownload function with the necessary parameters
                                                        sdk.users.setPayloadDownload(this.state.user.id, this.state.user.email, payload.id, payload.name, payload.fileName)
                                                            .then(response => {
                                                                console.log('Payload download logged:', response);
                                                                this.props.updateFunc(bullet.id);
                                                            })
                                                            .catch(error => {
                                                                console.error('Error logging payload download:', error);
                                                            });
                                                    })
                                                    .catch(error => {
                                                        console.error('Download error:', error);
                                                    });
                                            }}
                                        >
                                            Download
                                        </Button>
                                        {canSend && (
                                            <Button onClick={(e) => {
                                                this.toggleSendModal(payload);
                                            }}>
                                                Send
                                            </Button>
                                        )}
                                        {fromDashBoard && (
                                            <Button onClick={(e) => {
                                                this.toggleSendModal(payload);
                                            }}>
                                                Send
                                            </Button>
                                        )}
                                    </div>
                                </Jumbotron>
                            ))}
                        </ModalBody>
                        :
                        <ModalBody>
                            <PayloadViewer
                                getData={() => sdk.silverBullets.getPayloadFile(bullet.id, selectedPreviewPayload.id).then(data => {
                                    this.props.updateFunc(bullet.id);
                                    return data;
                                })}
                                setContentForCopy={this.setContentForCopy}
                            />
                        </ModalBody>
                    )}
                <ModalFooter>
                    {selectedPreviewPayload &&
                        <>
                            <Button color="secondary" onClick={() => this.setState({ selectedPreviewPayload: null })}>
                                Back to List
                            </Button>
                            <Button color="primary" onClick={this.copyToClipboard}>
                                Copy to Clipboard
                            </Button>
                        </>
                    }
                </ModalFooter>
                <SendModal isOpen={showSendModal} toggle={this.toggleSendModal} onSend={this.handleSend} />
            </Modal>
        );
    }
    
}

export default PayloadModal;
